import React from "react";
import NavBar from "../nav/nav";
import { Helmet } from "react-helmet";
import virgoblog from "../img/blog/blogvirgo.png";



import "./virgo.css";


export default function virgopage() {
    return (
        <>
            <Helmet>

                <title>
                    All About Virgo Zodiac Sign By Dr. Surabhi Bhatnagar
                </title>
                <meta
                    name="description"
                    content="People belonging to this are in the first sign and therefore the most ~raw~ with their needs, demands and emotions. They can be needy."
                />
                <meta name="keywords" content="zodiac signs symbol, astrology, astrologer, blessings  astrologer, vastu shastra, numerology services, Taurus Zodiac" />

                <link rel="canonical" href="https://blessingsastrology.com/virgo" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:site_name" content="Blessings Astrology" />
                <meta property="og:title" content=" All About Virgo Zodiac Sign By Dr. Surabhi Bhatnagar" />
                <meta property="og:description" content="People belonging to this are in the first sign and therefore the most ~raw~ with their needs, demands and emotions. They can be needy." />
                <meta
                    property="og:url"
                    content=" https://blessingsastrology.com/virgo"
                />
                <meta
                    property="og:image"
                    content="https://blessingsastrology.com/static/media/taurusblog.fb3c41cad98026489577.png"
                />
                <meta
                    name="twitter:title"
                    content=" All About Virgo Zodiac Sign By Dr. Surabhi Bhatnagar"
                />
                <meta
                    name="twitter:description"
                    content="People belonging to this are in the first sign and therefore the most ~raw~ with their needs, demands and emotions. They can be needy."
                />
                <meta
                    name="twitter:image"
                    content="https://blessingsastrology.com/static/media/taurusblog.fb3c41cad98026489577.png"
                />
            </Helmet>

            <section className="inner-section virgo-page">
                <NavBar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Horoscope Prediction</li>

                            </ul>
                        </div>
                    </div>
                </div>

            </section>
            <section className="blogsection">
                <div className="container">
                    <div className="row">
                        <div className="section-head  mb-0 col-12 mt-lg-5 mt-5">
                            <h1 className="pb-2 "><span className="px-2">Virgo</span> Zodiac</h1>
                        </div>
                        <div className="col-12">

                            {/* <img src={Taurusblog} className="w-100 ariesimage" alt="Astrology Services"></img> */}
                            <div className="card-body ">
                                <h4 className="card-title mt-3"></h4>

                                <p className="card-text text-dark">Virgi sign falls  between 23rd August and 22nd September. Its representation is a virgin girl with a crop in her hand. 

                                </p>
                                <p className="text-dark">They are hard workers and effortlessly perfect.They are detail loving people. They are highly organized and bear extremely high standards. They make sure that things go smoothly and perfectly. They can be very critical. Being a Mercurian they love to gather information and talk. 



                                </p>
                                <p className="text-dark">They are bright, sharp and shrewd.



                                </p>
                                {/* <p className="text-dark">Taurus can be gentle as well as  they can be fierce, they don’t like to be coerced, they have their own  strong opinions which are difficult to change  and they love their home comforts. If they can manage to do all activities whether work, game, grocery , they will do it from their comfort zone ( home) only. 

                                </p> */}
                                <img src={virgoblog} className="  ariesimage img-fluid w-100" alt="Astrology Services"></img>

                                <ul className="bulletpoints mt-4">
                                    <li>
                                        <strong>High standards:- </strong>Their style, taste and quality maintain very high standards. They don't compromise with this. They can notice even the minutest detail and can judge you over it.





                                    </li>
                                    <li><strong>No bragging stuff  :- </strong>They will never boast, brag or show off. They never show their achievements and hence go unnoticed which can lead to dip in confidence. They can even criticize themselves.



                                    </li>
                                    <li><strong>Diligent:- </strong>They are very hardworking. They are silently working diligently and becoming perfectionists. They are ready to do other people’s job just to make it perfect in all senses.


                                    </li>
                                    <li><strong>Helping:- </strong>They love helping people and taking them out of any problem but silently needs gratitude which they seldom receive.



                                    </li>
                                    {/* <li><strong>Protectors :- </strong>  They possess their people. They cannot stand someone else worrying or bullying them. They protect their people , family , friends and team from any danger.</li> */}
                                </ul>
                                <p className=" text-dark">They are hungry for knowledge and hence keep learning. They search a lot hence anything out of the line and they will start labeling and grouping. They are highly intellectual people. They are well organized and  meticulous. They have schedules and plans ready for almost everything in life right from the day to travel.They always lend their hand to help others. They are young, perfect and love to keep themselves perfect in appearance too. They love to dress well and be in touch with the fashion trends going on. 


                                </p>
                                <p className="text-dark"><strong>Traits:</strong> </p>
                                {/* <p className="text-dark">Taurus are naturally sexy people, as  their ruler  planet is  Venus, the planet of love, luxury, passion  and pleasure.  Taureans have a strong inner self-confidence and belief which they don’t brag about , and people get attracted to this quality of them as  they know that taureans  can be trusted. Taureans always go for exuberant and luxurious which is pleasing to others. . Taureans also know how to have a gala time, so they are always to be around.
</p> */}
                                {/* <p className="text-dark"><strong>Aries are attractive because :- </strong></p> */}
                                <ul>
                                    <li>
                                    They are naturally perfectionists.




                                    </li>
                                    <li>They are highly meticulous who do not leave any stone unturned to make the plan or project be perfect and flawless.


                                    </li>
                                    <li>Ready to help always and ready to do others job too.



                                    </li>
                                    <li>They are calm and serene.





                                    </li>
                                    <li>They never announce about their achievements.



                                    </li>
                                    <li>They notice all details in seconds and can go mad about it.
                                    </li>
                                    <li>
                                    They can be funny and sharp.
                                    </li>
                                    {/* <li>They always look good.</li>
                                    <li>They are warm and loving.</li>
                                    <li>They can be big flirts too.</li>
                                    <li>They can't stand unfair treatment and hence can be very protective of their own people.</li> */}
                                </ul>
                                <p className="text-dark"><strong>Career Choices :-</strong> </p>
                                <p className="text-dark">They do extremely well in the service and care industries, as servers, running shops, working the front desk,  as therapists,  they also excel at anything that requires mass amounts of research or stats,  think fact-checker and editor, technician, translator, or detective.



                                </p>
                                <p className="text-dark">They  notice all  micro-attentions and extras you do .They keep on doing favors to others and do  odd jobs for them. They  offer advice and support, and try to make life smoother for others and they will never mention it. They will always present well-organized and well-researched  projects. You will always see them in a Co-ordinated, well put-together outfits. They offer help and like others to accept it or they get upset. They hate misplanning or mess of any kind.





                                </p>
                                <p className="text-dark"><strong>
                                Virgo man:
                                </strong></p>
                                <ul>
                                    <li>
                                    He is highly resourcefu.


                                    </li>
                                    <li>He is mutable and flexible.


                                    </li>
                                    <li>He is logical and stubborn and see things in tangibility.
                                    </li>
                                    <li>He is sensitive but clever and ambitious too.


                                    </li>
                                    <li>He is private and non boasting.

                                    </li>
                                    <li>He has a constant churning mind.


                                    </li>
                                    <li>He possess very effective communication and put things through in a very effective manner.


                                    </li>
                                    <li>He delves into every single detail.

                                    </li>
                                    <li>If he is shown gratitude , he is overjoyed.
                                    </li>
                                    {/* <li>
                                    He is always well dressed and well groomed.
                                    </li> */}
                                    {/* <li>Over analyzing any problem and coming up with own conclusions with sticking on it only.

                                    </li>
                                    <li>hey can be boring sometimes.

                                    </li>
                                    <li>They love their home and comfort zone.

                                    </li>
                                    <li>They love food and are good cook. 
                                    </li> */}
                                </ul>
                                <p className="text-dark"><strong>Virgo Woman
                                    :
                                </strong></p>
                                <ul>
                                    <li>Critical perfectionist she is who search others and show love.


                                    </li>
                                    <li>She helps in all possible ways and helps others to correct it.
                                    </li>
                                    <li>She is an excellent communicator.

                                    </li>
                                    <li>She can change herself according to the situation hence she can be seen in many avatars.

                                    </li>
                                    <li>She can go over her call of duty towards others.


                                    </li>
                                    <li>She is a dreamer and a visionary too.

                                    </li>
                                    <li>She listens and encourages other to pursue their aims.


                                    </li>
                                    <li>They are gentle and nurturing.</li>
                                    <li>She is slow as she comprehends everything before executing.</li>
                                    {/* <li>They are moved by pure intentions as they are highly intuitive and can differentiate fake and genuine.

                                    </li>
                                    <li>They are unmatchable when it comes to love and care.
                                    </li>
                                    <li>She's a bit of a homebody and loves spending cozy, one-on-one time with her partner.
                                    </li>
                                    <li>She has a small circle of close friends rather than a wide group of casual acquaintances.</li>
                                    <li>A Taurus woman won’t be quick to change her opinions or uproot her life for someone.
</li> */}
                                    {/* <li>They are very impatient and cannot wait for things to happen.
                                    </li>
                                    <li>They are moody and impulsive and hence can take wrong decisions and drive things away.
                                    </li>
                                    <li>They get aggressive easily and are emotionally weak.
                                    </li> */}
                                </ul>

                            </div>


                        </div>
                    </div>
                </div>
            </section>


        </>

    );
}