import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PostApiCall from "../../API/POSTAPI";

function ConsultForm({ setShow }) {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  // state for loader
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });

  const [modalShow, SetModalShow] = useState(false);
  // const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    Notiflix.Notify.init({
      width: "280px",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      useFontAwesome: false,
      fontSize: "15px",
      timeout: 4000
    });
  }, []);

  return (
    <>
      {/* *** Consult button **** */}

      <form class="row g-3 needs-validation mt-2">
        <div className="col-12">
          {/* <h5 className="text-center">Fill your Free Consultation Form Now!</h5> */}
          <h5 className="text-center">Start your personalized astrological journey by filling out the form below</h5>
        </div>
        <div class="col-12">
          <label class="form-label">Name <span className="text-danger">*</span></label>
          <input type="text" class="form-control"
            value={Enquiry.name}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, name: e.target.value })
            } />
        </div>
        <div class="col-12">
          <label class="form-label">Email Id</label>
          <input type="email" class="form-control"
            value={Enquiry.email}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, email: e.target.value })
            }
          />
        </div>
        <div class="col-12">
          <label class="form-label">Mobile Number <span className="text-danger">*</span></label>
          <input type="text" class="form-control"
            value={Enquiry.mobile}
            onChange={(e) => {
              if (e.target.value.length <= 10)
                setEnquiry({
                  ...Enquiry,
                  mobile: e.target.value.replace(/\D/g, ""),
                });
            }}
          />
        </div>
        <div class="col-12">
          <label class="form-label">Ask Us Anything</label>
          <textarea class="form-control" rows="3"
            value={Enquiry.message}
            onChange={(e) =>
              setEnquiry({ ...Enquiry, message: e.target.value })
            }
          ></textarea>
        </div>
      </form>
      <div className="text-end mt-3">
        <a style={{cursor:'pointer'}}
          onClick={() => {
            if (Enquiry.name != "") {
              if (
                Enquiry.email == "" ||
                Enquiry.EmailRegex.test(Enquiry.email)
              ) {
                if (Enquiry.mobile != "") {
                  if (Enquiry.mobile.length == 10) {
                      {
                        setLoader(true);
                        PostApiCall.postRequest(
                          {
                            // mail: htmlMail,
                            // clientid: clientid,
                            name: Enquiry.name,
                            mobile: Enquiry.mobile,
                            email: Enquiry.email,
                            message: Enquiry.message
                          },
                          "BlessingConsultForm"
                        ).then((results2) =>
                          results2.json().then((obj2) => {
                            if (
                              results2.status == 200 ||
                              results2.status == 201
                            ) {
                              setLoader(false);
                              setShow(false);
                              Notiflix.Notify.success(
                                "Thankyou, we will get back to you shortly."
                              );
                              setTimeout(() => {
                                // window.location.reload();
                              }, 3000);
                            }
                          })
                        );
                      }
                    }
                  else {
                    Notiflix.Notify.failure(
                      "Please enter valid mobile number"
                    );
                  }
                } else {
                  Notiflix.Notify.failure("Please enter mobile number");
                }
              } else {
                Notiflix.Notify.failure("Please enter valid email");
              }
            } else {
              Notiflix.Notify.failure("Please enter name");
            }
          }}
          className="button button-2"
        >
          {Loader == true ? (
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border"
                role="status"
                style={{ width: "1.4rem", height: "1.4rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            "Ask Now"
          )}
        </a>
      </div>
    </>
  )
}
export default ConsultForm;