import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { photos } from "./photos";

export default function imagegallery() {
    
    return (
        <>
            <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
                <Masonry gutter="5px">
                {photos.map((photos, i) => (
                        <img
                            key={i}
                            src={photos.src}
                            style={{width: "100%", display: "block"}}
                            alt=""
                        />
                    ))}
                </Masonry>
            </ResponsiveMasonry>
        </>
    );
}