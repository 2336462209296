import React from "react";
import NavBar from "../nav/nav";
import { Helmet } from "react-helmet";
import Taurusblog from "../img/blog/taurusblog.png";



import "./taurus.css";


export default function Tauruspage() {
    return (
        <>
            <Helmet>

                <title>
                    All About Taurus Zodiac Sign By Dr. Surabhi Bhatnagar
                </title>
                <meta
                    name="description"
                    content="People belonging to this are in the first sign and therefore the most ~raw~ with their needs, demands and emotions. They can be needy."
                />
                <meta name="keywords" content="zodiac signs symbol, astrology, astrologer, blessings  astrologer, vastu shastra, numerology services, Taurus Zodiac" />

                <link rel="canonical" href="https://blessingsastrology.com/taurus" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:site_name" content="Blessings Astrology" />
                <meta property="og:title" content=" All About Taurus Zodiac Sign By Dr. Surabhi Bhatnagar" />
                <meta property="og:description" content="People belonging to this are in the first sign and therefore the most ~raw~ with their needs, demands and emotions. They can be needy." />
                <meta
                    property="og:url"
                    content=" https://blessingsastrology.com/taurus"
                />
                <meta
                    property="og:image"
                    content="https://blessingsastrology.com/static/media/taurusblog.fb3c41cad98026489577.png"
                />
                <meta
                    name="twitter:title"
                    content=" All About Taurus Zodiac Sign By Dr. Surabhi Bhatnagar"
                />
                <meta
                    name="twitter:description"
                    content="People belonging to this are in the first sign and therefore the most ~raw~ with their needs, demands and emotions. They can be needy."
                />
                <meta
                    name="twitter:image"
                    content="https://blessingsastrology.com/static/media/taurusblog.fb3c41cad98026489577.png"
                />
            </Helmet>

            <section className="inner-section taurus-page">
                <NavBar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Horoscope Prediction</li>

                            </ul>
                        </div>
                    </div>
                </div>

            </section>
            <section className="blogsection">
                <div className="container">
                    <div className="row">
                        <div className="section-head  mb-0 col-12 mt-lg-5 mt-5">
                            <h1 className="pb-2 "><span className="px-2">Taurus</span> Zodiac</h1>
                        </div>
                        <div className="col-12">

                            {/* <img src={Taurusblog} className="w-100 ariesimage" alt="Astrology Services"></img> */}
                            <div className="card-body ">
                                <h4 className="card-title mt-3"></h4>

                                <p className="card-text text-dark">Taurus is the second sign of the zodiac, considered as governing the period from about April 21 to about May 20. Its representation is a Bull.
                                </p>
                                <p className="text-dark">They ponder deeply on all matters - love, life, money, work, the weather. They dissect situations , understand how things work, how much ever time it takes. This takes a while (hence them seeming lazy) and when it’s done means that their conclusions are thorough and rock-solid (hence why they seem stubborn, because they know their own mind). 

                                </p>
                                <p className="text-dark">They appear to be  as laidback, even lazy, and extremely stubborn.  they might look  like this sometimes, but actually these behaviours are driven by a more intellectual and analytical nature than they’re credited with.

                                </p>
                                <p className="text-dark">Taurus can be gentle as well as  they can be fierce, they don’t like to be coerced, they have their own  strong opinions which are difficult to change  and they love their home comforts. If they can manage to do all activities whether work, game, grocery , they will do it from their comfort zone ( home) only. 

                                </p>
                                <img src={Taurusblog} className="  ariesimage img-fluid w-100" alt="Astrology Services"></img>

                                <ul className="bulletpoints mt-4">
                                    <li>
                                        <strong>Persistence :- </strong>   If they decide to do anything, nothing in this world can deter hem to achieve that.There is no task or challenge that will beat them, they have endless reserves of tenacity, patience and resilience. Like the tide on the shore, they’ll just keep coming back at it.



                                    </li>
                                    <li><strong>Awesome taste :- </strong> They  like the good life, prioritizes their comfort and pleasure over everything  else, and loves luxury. Their appearance, their homes, their materialistic things around  will look, feel, smell and taste aesthetic! They just want “the best” of anything.

                                    </li>
                                    <li><strong>Passionately stubborn :- </strong>  Taurus believes in living  life at their own  pace generally slower than other people. They  deep ponder and consider their every move and other’s too and  analyze each and every step.  This deep internal  desire and deliberation makes them stubborn. Their analysis have given them conclusions which  are totally clear in their mind  and they are committed to where they stand.

                                    </li>
                                    <li><strong>Sensuously materialistic :- </strong> They  are extremely seeking  physical pleasures - they inherently desire  the ultimate  pleasures of the material world  viz: food, drink, chilling, pampering, shopping, sex. They don't know where to put a stop on these cravings as these things are their click start , but this greed can lead them into troubled situations too. 

                                    </li>
                                    <li><strong>Into their own zone :- </strong>  Taurus are always thinking or they are always doing their own work not bothered to listen to anyone else even their partners or children until and unless they are offered food/drink/sex/money.They call it chilling in their own private zone. They  love doing nothing and being in their comfort zone. They won't make fuss until they are pricked. 
 

                                    </li>
                                </ul>
                                <p className=" text-dark">All Taurus obsess over, respect and manage their money. They enjoy investing and saving.
They are natural born food lovers and bloggers.They live to eat and cook.They can sit and chill at one place with a blanket for hours together if it is comfortable.They love attracting admiration and attention. They are expert flirts.They take shopping seriously. They can go to their favorite boutique or restaurant after all the busy and run down the whole day. 

                                </p>
                                <p className="text-dark"><strong>Traits:</strong> </p>
                                <p className="text-dark">Taurus are naturally sexy people, as  their ruler  planet is  Venus, the planet of love, luxury, passion  and pleasure.  Taureans have a strong inner self-confidence and belief which they don’t brag about , and people get attracted to this quality of them as  they know that taureans  can be trusted. Taureans always go for exuberant and luxurious which is pleasing to others. . Taureans also know how to have a gala time, so they are always to be around.
</p>
                                {/* <p className="text-dark"><strong>Aries are attractive because :- </strong></p> */}
                                <ul>
                                    <li>
                                    They love pleasure and indulgence and indulge others too.


                                    </li>
                                    <li>They are very happy to be zoned out, chilling with themselves, happy in their own skin.

                                    </li>
                                    <li>They are no nonsense and no tension people around.


                                    </li>
                                    <li>They have captivating eyes which draws people towards them.



                                    </li>
                                    <li>They will listen to you only either if they want to or you offer them what they learn for.



</li>
                                </ul>
                                <p className="text-dark"><strong>Career Choices :-</strong> </p>
                                <p className="text-dark">Taurus are ruled by  Venus hence they are skilled  in finance, beautification, the arts, aesthetics, and hospitality / entertainment. Bull as their sign they are  built for endurance and tasks requiring focus, concentration, determination and perseverance. 

                                </p>
                                <p className="text-dark"><strong>Taurus Man:
                                </strong></p>
                                <ul>
                                    <li>
                                    They may be stubborn but always there for you.

                                    </li>
                                    <li>They are loyal and dependable.

                                    </li>
                                    <li>They might be slow but perfectionist.
.
                                    </li>
                                    <li>They are level headed and have a taste of fine things in life.
</li>
                                    <li>They are natural romantics and flirts.
                                    </li>
                                    <li>They want all things materialistic.

                                    </li>
                                    <li>They have dovey eyes which can make you drool over them.

                                    </li>
                                    <li>They never care to win an argument but will never leave, infact will come on it again and again.

                                    </li>
                                    <li>Over analyzing any problem and coming up with own conclusions with sticking on it only.

                                    </li>
                                    <li>hey can be boring sometimes.

                                    </li>
                                    <li>They love their home and comfort zone.

                                    </li>
                                    <li>They love food and are good cook. 
                                    </li>
                                </ul>
                                <p className="text-dark"><strong>Taurus Women:
                                </strong></p>
                                <ul>
                                    <li>Perfect face with goddess features.

                                    </li>
                                    <li>  Inclined to be sweet. They have kind nature hence people try to take advantage. 
                                    </li>
                                    <li>They open up only with their closest of friends.
                                    </li>
                                    <li>They like to focus on building a relationship.

                                    </li>
                                    <li>They are doers, if they want to get something, they will go out and get it.

                                    </li>
                                    <li>They might be slow but making a subtle and stable moves for their plans.
                                    </li>
                                    <li>They prefer enjoy life and their comfort zone stress free.
                                    </li>
                                    <li>They exhibit the patience and strength of a bull.
                                    </li>
                                    <li>They are unmatchable when it comes to love and care.
                                    </li>
                                    <li>She's a bit of a homebody and loves spending cozy, one-on-one time with her partner.
                                    </li>
                                    <li>She has a small circle of close friends rather than a wide group of casual acquaintances.</li>
                                    <li>A Taurus woman won’t be quick to change her opinions or uproot her life for someone.
</li>
                                    {/* <li>They are very impatient and cannot wait for things to happen.
                                    </li>
                                    <li>They are moody and impulsive and hence can take wrong decisions and drive things away.
                                    </li>
                                    <li>They get aggressive easily and are emotionally weak.
                                    </li> */}
                                </ul>

                            </div>


                        </div>
                    </div>
                </div>
            </section>


        </>

    );
}




