import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./whychoose.css";
import wcImg from "../../img/wcu/bg.png";
import w1 from "../../img/wcu/astro.png";
import w2 from "../../img/wcu/hq.png";
import w3 from "../../img/wcu/trust.png";
import w4 from "../../img/wcu/exp.png";
import w5 from "../../img/wcu/pra.png";
import w6 from "../../img/wcu/hol.png";
function WhyChoose() {
  return (
    <>
      {/* <Container className="mt-5 pt-3 why-choose-section">
    <Row>
        <Col xs={12} className="section-head">
          <h4><span>Why Choose</span> Us?</h4>
          <p>Choose Blessings Astrology for Your Spiritual Journey: Unmatched Expertise, Compassionate Guidance, and Personalized Support!</p>
        </Col>
        <Col xs={12} sm={6} lg={4}>
          <div className="item"> <span className="icon feature_box_col_one"></span>
            <h6>Comprehensive Astrological Services</h6>
            <p> Blessings Astrology offers a wide range of astrology-related services to help you make better decisions in your life.</p>
          </div>
        </Col>
        <Col xs={12} sm={6} lg={4}>
          <div className="item"> <span className="icon feature_box_col_two"></span>
            <h6>High-Quality Astrological Forecasts and Advice</h6>
            <p>The platform has a reputation for providing high-quality astrological forecasts and advice, which can help you make informed decisions about your life.</p>
          </div>
        </Col>
        <Col xs={12} sm={6} lg={4}>
          <div className="item"> <span className="icon feature_box_col_three"></span>
            <h6>Expertise and Experience</h6>
            <p> Dr. Surabhi Bhatnagar's expertise and experience in the field of astrology makes clients comfortable and solutions are given in a practical manner.</p>
          </div>
        </Col>
        <Col xs={12} sm={6} lg={4}>
          <div className="item"> <span className="icon feature_box_col_four"></span>
            <h6>Trustworthy and Confidential</h6>
            <p>Blessings Astrology is committed to maintaining the highest standards of confidentiality and trust, ensuring that your personal information is kept safe and secure.</p>
          </div>
        </Col>
        <Col xs={12} sm={6} lg={4}>
          <div className="item"> <span className="icon feature_box_col_five"></span>
            <h6>Practical Solutions</h6>
            <p>Blessings Astrology provides practical solutions that are tailored to your individual needs and challenges, taking into account the demands of modern life.</p>
          </div>
        </Col>
        <Col xs={12} sm={6} lg={4}>
          <div className="item"> <span className="icon feature_box_col_six"></span>
            <h6>Holistic Approach</h6>
            <p> Blessings Astrology takes a holistic approach to astrology, considering all aspects of your life to help you achieve balance, harmony, and success in all areas of your life.</p>
          </div>
        </Col>
      </Row>
    </Container> */}
      <section className="card-section pb-4 pt-5 mt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 t-card-img">
              <img src={wcImg} className="img-fluid w-100 " alt="Achivements of Dr. Surabhi Bhatnagar"></img>
              <div className="section-head section-head-2 text-start">
                <h4 className="text-start pb-2"><span>Why Choose</span> Us?</h4>
                <p className="text-start">Choose Blessings Astrology for Your Spiritual Journey: Unmatched Expertise, Compassionate Guidance, and Personalized Support!</p>
              </div>

            </div>
            <div className="col-lg-8 main-card-section">
              <div className="row h-100">
                <div className="col-md-4 p-0">
                  <div className="three-section-card three-section-card-1">
                    <div className="ts-icon">
                      <img src={w1} className="img-fluid w-50" alt="" />
                      <h3>
                        Comprehensive Astrological Services
                      </h3>
                    </div>
                    {/* <div className="ts-content">

                      <p>
                        Looking to make a difference in the lives of visually impaired individuals in Delhi? Consider volunteering with NAB Delhi. We welcome volunteers of all backgrounds and provide training and support to help you make a positive impact in the community. Whether you want to help with programs, events, fundraising, or other initiatives, we have opportunities for everyone.
                      </p>
                      <a href="/be-a-volunteer" alt="NAB Delhi Be a Volunteer" className="default-btn" aria-label="NAB Delhi Be a Volunteer Page">Join Now</a>
                    </div> */}
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="three-section-card three-section-card-2">
                    <div className="ts-icon">
                      <img src={w2} className="img-fluid w-50" alt="" />
                      <h3>
                        High-Quality Astrological Forecasts and Advice
                      </h3>
                    </div>
                    {/* <div className="ts-content">

                      <p>
                        Your donation can make a significant difference in the lives of these individuals by providing them with access to education, healthcare, and job opportunities. With your support, NAB Delhi can continue to provide essential services and programs to those in need. Every contribution, no matter how small, can help create a brighter future for the visually impaired community in Delhi.
                      </p>
                      <a href="https://nabdelhi.mojo.page/national-association-for-blind---delhi" target="_blank" alt="NAB Delhi Donate Now" className="default-btn" aria-label="NAB Delhi Donate Now Page">Donate Now</a>
                    </div> */}
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="three-section-card three-section-card-3">
                    <div className="ts-icon">
                      <img src={w3} className="img-fluid w-50" alt="" />

                      <h3>
                        Trustworthy and Confidential
                      </h3>
                    </div>
                    {/* <div className="ts-content">

                      <p>
                        Joining hands with NAB Delhi can make a difference in the lives of visually impaired individuals. As a CSR partner, your organization can help provide vital resources and services to those in need. By supporting us, your organization can contribute to the empowerment and inclusion of the visually impaired community. Join us today to create a positive impact and fulfill your CSR objectives.
                      </p>
                      <a href="/be-a-csr" alt="NAB Delhi Be a CSR" aria-label="NAB Delhi Be a CSR Page" className="default-btn">Be a CSR Partner</a>
                    </div> */}
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="ts-icon three-section-card">
                    <img src={w4} className="img-fluid w-50" alt="" />
                    <h3>
                      Expertise and Experience
                    </h3>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="ts-icon three-section-card three-section-card-4 three-section-card-5">
                    <img src={w5} className="img-fluid w-50" alt="" />
                    <h3>
                      Practical Solutions
                    </h3>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="ts-icon three-section-card three-section-card-4">
                    <img src={w6} className="img-fluid w-50" alt="" />
                    <h3>
                      Holistic Approach
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default WhyChoose;