import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import abt from "../img/surabhi.png";
import NavBar from "../nav/nav";
import { Autoplay, Pagination } from "swiper";

import "./horoscopepage.css";

import w1 from "../img/wcu/astro.png";
import w2 from "../img/wcu/hq.png";
import w3 from "../img/wcu/trust.png";
import w4 from "../img/wcu/exp.png";
import w5 from "../img/wcu/pra.png";
import w6 from "../img/wcu/hol.png";
import { Helmet } from "react-helmet";
import aries from "../img/horoscope/aries.png";
import taurus from "../img/horoscope/taurus.png";
import gemini from "../img/horoscope/gemini.png";
import cancer from "../img/horoscope/cancer.png";
import leo from "../img/horoscope/leo.png";
import virgo from "../img/horoscope/virgo.png";
import libra from "../img/horoscope/libra.png";
import scorpio from "../img/horoscope/Scorpio.png";
import Sagittarius from "../img/horoscope/Saggitarius.png";
import capricorn from "../img/horoscope/Capricorn.png";
import aquarius from "../img/horoscope/Aquarius.png";
import pisces from "../img/horoscope/Pisces.png";
import s1 from "../img/service/1.png";
import s2 from "../img/service/2.png";
import s3 from "../img/service/3.png";
import s4 from "../img/service/4.png";
import s5 from "../img/service/pa.png";
import s6 from "../img/service/nu.png";
import s7 from "../img/service/re.png";

export default function horoscopepage() {
  return (
    <>
      <Helmet>
        <title>Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar</title>
        <meta
          name="description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general."
        />
        <meta
          name="keywords"
          content="Horoscope Prediction, Aries horoscope, Taurus horoscope, taurus weekly horoscope, Gemini horoscope, Cancer horoscope, weekly Leo horoscope, Virgo horoscope, virgo weekly horoscope, weekly Libra horoscope"
        />

        <link
          rel="canonical"
          href="https://blessingsastrology.com/horoscope-prediction"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta
          property="og:title"
          content="Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar"
        />
        <meta
          property="og:description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general."
        />
        <meta
          property="og:url"
          content="https://blessingsastrology.com/horoscope-prediction"
        />
        <meta
          property="og:image"
          content="https://blessingsastrology.com/static/media/horoscopebg.dc48382d7221fd269388.png"
        />
        <meta
          name="twitter:title"
          content="Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar"
        />
        <meta
          name="twitter:description"
          content="Predict your weekly Horoscope By Dr. Surabhi Bhatnagar - Best Astrologer in India, Check your Moon and Ascendant Signs. Aries Horoscope Prediction."
        />
        <meta
          name="twitter:image"
          content="https://blessingsastrology.com/static/media/horoscopebg.dc48382d7221fd269388.png"
        />
      </Helmet>
      <section className="inner-section horoscope-page">
        <NavBar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Horoscope Prediction</li>
              </ul>
            </div>
          </div>
        </div>

        <section className="horoscope-section pt-4 py-lg-5">
          <div className="container">
            <div className="row">
              <div className="section-head text-center mb-0 col-12">
                <h1 className="pb-2">
                  <span>Weekly</span> Horoscope Prediction
                </h1>
                <h5>16th Sep - 22th Sep</h5>
                <p>Check your Moon and Ascendant Signs</p>
              </div>
              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={aries} alt="Astrology Services"></img>
                  </div>
                  <h3>Aries</h3>
                  <p className=" ">
This week, due to Jupiter in the house of money and assets , you will be benefited through commission or dividend from past investments. You will also be planning and investing in profitable schemes. You will be setting your goals higher than before. As a result you will have to work hard to accomplish it. Don't be disappointed if results are not upto your expectations. You will also get rid of all ups and downs in the family.                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Red
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 9
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={taurus} alt="Astrology Services"></img>
                  </div>
                  <h3>Taurus</h3>
                  <p className=" ">
This week, due to Rahu being placed in the house of gains and profits, is going to be very auspicious financially. Favourable planetary positions will provide a great opportunity to increase your wealth hence do not let your efforts fall a bit. You will be given an extended helping hand from your friends at the time of your need. Instead of getting angry with subordinates, work with them using a proper strategy. Favourable results will follow.                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Blue
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 4
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={gemini} alt="Astrology Services"></img>
                  </div>
                  <h3>Gemini</h3>
                  <p className=" ">
This week, due to Rahu being present in the house of karma, you will get the financial benefits from secret sources and you will decide to invest it further. It is likely that you will benefit from this investment as indicated by favourable conjunctions of planets. You will be able to gain new achievements in your professional life due to the influence of many auspicious planets. You will be able to get many opportunities which will help you rise in your career.                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Green
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 5
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={cancer} alt="Astrology Services"></img>
                  </div>
                  <h3>Cancer</h3>
                  <p className=" ">
This week, due to Ketu being present in the house of efforts, your career will progress, you will be burdened with responsibility but these might give you mental stress. It is advised that you should stay calm and try to stay away from all kinds of stress. You will be beaming with energy and use your extra energy in a positive manner. You should stay away from all kinds of intoxication. One of the family members will be felicitated for good work.                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Red
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 9
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={leo} alt="Astrology Services"></img>
                  </div>
                  <h3>Leo</h3>
                  <p className=" ">
                This week, due to Jupiter being present in the house of karma, you will be gaining money but you will be tempted to spend it. You will need to make your efforts towards accumulating your wealth and taking care of all the future risks. Due to Saturn being placed in the seventh house, your ability to accomplish work will increase and you might make a big decision to expand your profession and you will have fruits of hard work.     </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Orange
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 1
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={virgo} alt="Astrology Services"></img>
                  </div>
                  <h3>Virgo</h3>
                  <p className=" ">
                  This week, familial responsibilities take priority, especially the younger members. Their company and activities should be monitored. You have to be careful with your friends and colleagues as their behaviour might offend you and you lose your mental peace. It is advised that you save your money and restrict yourself from spending unnecessarily. Ketu being posited in the first house indicates that long pending tasks to be finished on time.                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Green
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 5
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={libra} alt="Astrology Services"></img>
                  </div>
                  <h3>Libra</h3>
                  <p className=" ">
                  This week, due to Rahu being present in the sixth house, you will be able to overcome all financial problems and will be able to make decisions for future investments. The decision you make will earn you good profit. People around you will be impressed with your knowledge and will try to follow you. It is advised that you include creative ideas in your work to please your superiors and help yourself getting a promotion.                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Green
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 5
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={scorpio} alt="Astrology Services"></img>
                  </div>
                  <h3>Scorpio</h3>
                  <p className=" ">
                  This week, Jupiter being present in the seventh house asks you to spend your time on those things that make you feel the happiest or make you fulfil your desires. This will help you in staying away from the mental stress. Monetary benefits are sure but since they would be lesser than what you expected hence you might not be happy. Favourable placements on many planets will help you get positive results in your career and earn well.                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Orange
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 1
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={Sagittarius} alt="Astrology Services"></img>
                  </div>
                  <h3>Sagittarius</h3>
                  <p className=" ">
                  This week,due to Ketu being present in the house of Karma you will be getting positive results and support from seniors in your career. Many past problems and hurdles will vanish and financial help will be poured in by your well wishers if needed.You are bound to get respected in your social circle. You will be taking care of all your familial responsibilities. Your siblings might require your constant support. It is advised that you restrain yourself from spending unnecessary.                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> White
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 2
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={capricorn} alt="Astrology Services"></img>
                  </div>
                  <h3>Capricorn</h3>
                  <p className=" ">
                  This week, your thirst for knowledge will help you make new friends. Keep your mind focussed. It is important that you concentrate and target on the goals in your career. At some incidents you might feel unsure of how things are progressing but your consistency will help you reach your destination and your professional front will strengthen. It is advised that you start meditation. Participation in physical activities will channelise your energy.                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Pink
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 6
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={aquarius} alt="Astrology Services"></img>
                  </div>
                  <h3>Aquarius</h3>
                  <p className=" ">
                  This week, you will feel highly emotional. Avoid sharing your feelings with others. You will attract luck during this period and more income will be earned through different sources. Maa Lakshmi will be happy with you and will be showering her blessings, hence giving required importance to money and finance. Jupiter being posited  in the fourth house will turn this week highly favourable for you professionally. Take the opportunities seriously and see your career graph shoot up and progress.                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Blue
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 4
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 py-md-2 px-md-3 mb-3">
                <div className="service-card">
                  <div className="icon-wrapper mb-3">
                    <img src={pisces} alt="Astrology Services"></img>
                  </div>
                  <h3>Pisces</h3>
                  <p className=" ">
                  This week, due to Rahu being present in your first house, you will benefit in financial aspects due to salary increments. Your efforts and thoughts will be fully supported by your luck in your career and it will take a leap. Keep trying to achieve your goals. It is advised not to get lured by financial schemes and trap your resources. The time is not right to do any kind of investment. You might plan for a renovation or buying a new vehicle .                  </p>
                  <div className="text-start w-100 mb-4">
                    <span className="me-3">
                      <strong>Lucky Color :</strong> Yellow
                    </span>
                    <span>
                      <strong>Lucky Number :</strong> 3
                    </span>
                  </div>
                  <div className="d-flex align-items-left justify-content-left">
                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                    <a href="/book-an-appointment" className="button button-2">
                      Book An Appointment
                    </a>
                  </div>
                </div>
              </div>

              <Col xs={12} className="section-head t-head mb-0 my-4">
                {/* <h4><span>What Do Our Clients Say</span> About Us?</h4> */}
                <h4>
                  <span>Our</span> Services
                </h4>
              </Col>

              <div className="col-12">
                <Swiper
                  spaceBetween={30}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                  }}
                  autoplay={{
                    delay: 5000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper testimonial-swiper services-swiper pt-5">
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s1} alt="Astrology Services"></img>
                        </div>
                        <h3>Astrology</h3>
                        <p>
                          Astrology, also known as Jyotish, is an ancient and
                          divine knowledge that can see into our past...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/astrology-service"
                            className="button button-2">
                            Read More{" "}
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s2} alt="Vastu Shastra services"></img>
                        </div>
                        <h3>Vastu Shastra</h3>
                        <p>
                          Vastu Shastra is a divine science that we have used
                          since ancient times to plan and build our homes...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/vastu-shastra-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img
                            src={s3}
                            alt="Handwriting and Signature Analysis (Astrology)"></img>
                        </div>
                        <h3>Handwriting & Signature Analysis</h3>
                        <p>"We can judge a person by looking at his...</p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/handwriting-signature-analysis"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s4} alt="Tarot Card Reading"></img>
                        </div>
                        <h3>Tarot</h3>
                        <p>
                          Tarot card reading is an ancient technique that was
                          popular in Europe centuries ago and has now spread all
                          ...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a href="/tarot-service" className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s5} alt="Palmistry Services" />
                        </div>
                        <h3>Palmistry</h3>
                        <p>
                          Palmistry is an age old technique in which an
                          astrologer reads and analyzes the palm of the
                          native....
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/palmistry-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s6} alt="Numerology services" />
                        </div>
                        <h3>Numerology</h3>
                        <p>
                          Numerology is the wing of astrology where we consider
                          numbers and predict the past, present and future.
                          Numerology helps people...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/numerology-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <div className="service-card">
                        <div className="icon-wrapper">
                          <img src={s7} alt="Reiki Healing Services" />
                        </div>
                        <h3>Reiki Healing</h3>
                        <p>
                          It is the japanese technique which makes use of hands
                          and invokes energy and blessings from the universe. It
                          is an age-old...
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <a
                            href="/reikihealing-service"
                            className="button button-2">
                            Read More
                          </a>
                          <a
                            href="/book-an-appointment"
                            className="button button-2">
                            Book Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
