import React from "react";
import "../servicepage.css";
import NavBar from "../../nav/nav";
import s1 from "../../img/service/1.png";
import s2 from "../../img/service/2.png";
import s3 from "../../img/service/3.png";
import s4 from "../../img/service/inner/tar.png";
import { Helmet } from "react-helmet";
export default function tarotpage() {
    return (
        <>
          <Helmet>
        
        <title>
          Best Tarot Card Reader in India | Blessings Astrology
        </title>
        <meta
          name="description"
          content="Tarot card reading is an ancient technique that was popular in Europe centuries ago and has now spread all over the world."
        />
        <meta name="keywords" content="astrology services, best astrologer in gurgaon, astrologer in gurgaon, Jyotis shastra, vastu shastra, vastu shastra consultant" />
        <link rel="canonical" href="https://www.blessingsastrology.com/tarot-service" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta property="og:title" content="Best Tarot Card Reader in India | Blessings Astrology" />
        <meta property="og:description" content="Tarot card reading is an ancient technique that was popular in Europe centuries ago and has now spread all over the world." />
        <meta
          property="og:url"
          content="https://www.blessingsastrology.com/tarot-service"
        />
        <meta
          property="og:image"
          content="https://www.blessingsastrology.com/static/media/tar.2f3ba010944e52ab52b0.png"
        />
        <meta
          name="twitter:title"
          content="Best Tarot Card Reader in India | Blessings Astrology"
        />
        <meta
          name="twitter:description"
          content="Tarot card reading is an ancient technique that was popular in Europe centuries ago and has now spread all over the world.
"
        />
        <meta
          name="twitter:image"
          content="https://www.blessingsastrology.com/static/media/tar.2f3ba010944e52ab52b0.png"
        />
      </Helmet>
            <section className="inner-section service-page">
                <NavBar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/services">Services</a></li>
                                <li>Tarot</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">

                    <div className="row">
                        <div className="section-head t-head mb-0 col-12 mb-4">
                            <h4><span>Tarot</span></h4>
                        </div>
                        <div className="col-lg-4">
                            <img src={s4} className="img-fluid w-100 h-100" alt="Tarot Card Reading" />
                        </div>
                        <div className="col-lg-8 service-inner-card">
                            <p>
                            Tarot card reading is an ancient technique that was popular in Europe centuries ago and has now spread all over the world.It is a tool that helps people by using cards. It employs the Tarot Card Reader's judgement and intuition to provide information about the past, present, and future, as well as guidance to assist the quarant  in obtaining the answer to his or her query. Tarot Card Reader uses his or her intellect, strong intuitive power, and continuous connection with the divine to derive and decode the message given to the quarant by the universe. Dr. Surabhi Bhatnagar uses her expertise in Tarot cards (Ryder weight, Angel cards) to guide, assist, and solve problems, as well as suggest corrective actions.
                            </p>
                            <a href="/book-an-appointment" className="button button-2">Book An Appointment</a>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    );
}