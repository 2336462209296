import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import abt from "../img/surabhi.png";
import NavBar from "../nav/nav";
import { Helmet } from "react-helmet";
import Geminiblog from "../img/blog/Gemini.png";
import bloggemini from "../img/blog/bloggemini.png";
import Sagittarius from "../img/horoscope/Saggitarius.png"



import "./gemini.css";


export default function geminipage() {
    return (
        <>
            <Helmet>

                <title>
                    All About Gemini Zodiac Sign By Dr. Surabhi Bhatnagar
                </title>
                <meta
                    name="description"
                    content="People belonging to this are in the first sign and therefore the most ~raw~ with their needs, demands and emotions. They can be needy."
                />
                <meta name="keywords" content="zodiac signs symbol, astrology, astrologer, blessings  astrologer, vastu shastra, numerology services, Aries Zodiac" />

                <link rel="canonical" href="https://blessingsastrology.com/gemini" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:site_name" content="Blessings Astrology" />
                <meta property="og:title" content=" All About Gemini Zodiac Sign By Dr. Surabhi Bhatnagar" />
                <meta property="og:description" content="People belonging to this are in the first sign and therefore the most ~raw~ with their needs, demands and emotions. They can be needy." />
                <meta
                    property="og:url"
                    content=" https://blessingsastrology.com/gemini"
                />
                <meta
                    property="og:image"
                    content="https://blessingsastrology.com/static/media/blogaries.d16cc71ce28745b3c272.png"
                />
                <meta
                    name="twitter:title"
                    content=" All About Gemini Zodiac Sign By Dr. Surabhi Bhatnagar"
                />
                <meta
                    name="twitter:description"
                    content="People belonging to this are in the first sign and therefore the most ~raw~ with their needs, demands and emotions. They can be needy."
                />
                <meta
                    name="twitter:image"
                    content="https://blessingsastrology.com/static/media/blogaries.d16cc71ce28745b3c272.png"
                />
            </Helmet>

            <section className="inner-section gemini-page">
                <NavBar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Horoscope Prediction</li>

                            </ul>
                        </div>
                    </div>
                </div>

            </section>
            <section className="blogsection">
                <div className="container">
                    <div className="row">
                        <div className="section-head  mb-0 col-12 mt-lg-5 mt-5">
                            <h1 className="pb-2 "><span className="px-2">Gemini</span>Zodiac </h1>
                        </div>
                        <div className="col-12">

                            {/* <img src={ariesblog} className="w-100 ariesimage" alt="Astrology Services"></img> */}
                            <div className="card-body ">
                                <h4 className="card-title mt-3"></h4>

                                <p className="card-text text-dark">Gemini is the third sign of the zodiac, considered as governing the period from about May  21 to about June 20. Its representation is Twins. 
                                </p>
                                <p className="text-dark">They are highly curious, very smart, super curious and extra smart people but they can get distracted that fast too. Blame it on the air element which governs them and keeps them moving.They are very kind but can be ruthless and detached too which explains their twin nature.

                                </p>
                                <p className="text-dark">They are very good in taking notes in the background and keeping secrets and also know how to get away when trapped somehow.

                                </p>
                                {/* <p className="text-dark">They like to  win, attention seeking and being on top.
                                </p> */}
                                <img src={bloggemini} className="  ariesimage img-fluid w-100" alt="Astrology Services"></img>

                                <ul className="bulletpoints mt-4">
                                    <li>
                                        <strong>Versatile and superficial:- </strong> They have an insatiable hunger to know everything in every subject. They are jack of all trades. They know knick and knacks of all topics but still do not want to delve deep into them since they lose interest very soon hence superficiality. 



                                    </li>
                                    <li><strong>Ruthlessly smart:- </strong>They are very analytical and think about things dispassionately. They slide away easily if there is no profitable business. They can disconnect themselves and see situations from the third person’s point of view.
.
                                    </li>
                                    <li><strong>Childish :- </strong>  They are forever young. They are immature sometimes and youthful at all times. They can be perky and silly and into the latest trends for a longer time than others.

                                    </li>
                                    <li><strong>Fast :- </strong> Being a Mercurian they are quick and fast in everything. They think, act and act fast. They react fast and talk fast so that it can become erratic sometimes and things come back to them.

                                    </li>
                                    <li><strong>Curious :- </strong> They should know all about everything and everyone. They can be the source of grapevine communication. Spreading rumors can be their pastime.


                                    </li>
                                </ul>
                                <p className=" text-dark">
Geminis love to read and write. Inheriting the quality of Mercury to communication, they  love talking and interacting with people. They love traveling as Mercury does. They love to explore. Traveling gives them instant energy, life, and changes their mood in an instant. Their curious nature and inquisitiveness makes them travel to know more about other cultures and places. They can party all night and all day if they wish to. If something is to be prioritized and it gives them the same feeling of enjoyment like when in a party then they would prefer doin that thing all day and night. They make lots of friends and they can use them as their contacts. Highly social people they are, they can fulfill the emotional requirements of others. 

                                </p>
                                <p className="text-dark"><strong>Traits:</strong> </p>
                                <p className="text-dark">Geminis are highly social people. They can be ultra flirtatious and delve inside the people’s lives to know it all. They can gossip all day and will always be ready with a new piece of news for all. 
 </p>
                                <p className="text-dark"><strong>They are very charming, youthful, talkative, observant and smart :- </strong></p>
                                <ul>
                                    <li>
                                    They exude infectious positive  energy. They make people stand up and dance even in the lowest moods. 


                                    </li>
                                    <li>Their main life motto is to inquire, explore, inform, communicate, entertain and inspire.
                                    </li>
                                    <li>They have a glow on their faces and charm in their style. They attract second glances due to their youthfulness, never say die attitude and inviting Aura.


                                    </li>
                                </ul>
                                <p className="text-dark"><strong>Career Choices :-</strong> </p>
                                <p className="text-dark">Gemini people are smart, quick-thinking, adaptable, imaginative and innovative, playful and youthful. They love words, communication in any form   and writing so  journalism, PR, scripting, politics, novel writing are good for them. . They also make wonderful teachers. They can be good at travel blogging, commerce and entrepreneurship. 

                                </p>
                                <p className="text-dark">They are blessed with duality. They can be extra loving but disconnected at the same instant. They are highly intellectual people who love creativity. They are very sharp and smart and sometimes shrewd too. They love communication and travel. They can be turned with the slightest mention of adventure and exploration. But if you cling on to them then they can shun you at that very instant.People who do not like to explore or know more are not in their list to meet.
 

                                </p>
                                <p className="text-dark"><strong>Gemini Man:

                                </strong></p>
                                <ul>
                                    <li>
                                    He is flexible and talkative.

                                    </li>
                                    <li>He needs constant stimulation or he will lose interest
                                    </li>
                                    <li> Highly brainy and versatile , he can give you the most memorable moments to share
.
                                    </li>
                                    <li>He is always youthful and refreshing to talk to.
</li>
                                    <li>He has lots of friends, even the oldest ones and he can strike a conversation even with a stranger.
                                    </li>
                                    <li>His mind is always working since he is exploring and observing all the time. 
                                    </li>
                                    <li>He can be playful and mischievous and a prankster.

                                    </li>
                                    <li>He is adaptable hence needs constant motivation to stay in the present.

                                    </li>
                                    {/* <li>They have high energy so they can be argumentative too.
                                    </li>
                                    <li>Hey sometimes can act immature and childish and want others in the surroundings to behave in the same manner as them.
                                    </li>
                                    <li>As a lover, they are very passionate and initiator and can become on the bossier side. They can always experiment with their love life and in the bedroom.
                                    </li> */}
                                </ul>
                                <p className="text-dark"><strong>Gemini Women:
:
                                </strong></p>
                                <ul>
                                    <li>She is highly inquisitive and needs the same in the other person to stay in meetings.
                                    </li>
                                    <li>  She is highly smart, spontaneous and outgoing.

                                    </li>
                                    <li>She hates to be bored so needs something to entertain her .

                                    </li>
                                    <li>She takes time to open up but relationships can last long .

                                    </li>
                                    <li>She can be clever and cunning if feel threatened.


                                    </li>
                                    <li>She is a natural charmer catching all positive attention with her catchy smile.

                                    </li>
                                    <li>They are great in getting things going, initiating, getting the endeavors running using their own energy and being too enthusiastic all along the way.
                                    </li>
                                    <li>You can break the ice with her by seeing the world through her eyes.
                                    </li>
                                    {/* <li>They are always energetic and youthful and hence look younger from their actual age.
                                    </li>
                                    <li>They are natural leaders.
                                    </li>
                                    <li>They are creative and very intelligent.They refuse to accept anything less than the best.</li>
                                    <li>They are fun to be around.</li>
                                    <li>They are very impatient and cannot wait for things to happen.
                                    </li>
                                    <li>They are moody and impulsive and hence can take wrong decisions and drive things away.
                                    </li>
                                    <li>They get aggressive easily and are emotionally weak.
                                    </li> */}
                                </ul>

                            </div>


                        </div>
                    </div>
                </div>
            </section>


        </>

    );
}