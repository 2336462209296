import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./carousel.css";

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import b1 from "../../img/b-1.png";
import b2 from "../../img/b-2.png";
function Carousel() {
    return (
        <>
            <Swiper
                spaceBetween={0}
                effect={"fade"}
                loop={true}
                // autoplay={{
                //     delay: 5000,
                //     pauseOnMouseEnter: true,
                //     disableOnInteraction: false,

                // }}
                navigation={true}
                pagination={false}
                // pagination={{
                //     clickable: true,
                // }}

                modules={[Autoplay, EffectFade, Navigation, Pagination]}
                className="mySwiper"
            >
                <SwiperSlide className="bg-1">
                    <Container className="pt-lg-5 h-100">
                        <Row className="pt-lg-5 mt-2">
                            <Col lg={6} className="d-flex flex-column justify-content-center ">
                                <div className="slide-1">
                                    <h1>
                                        Discover Your Divine Path with Blessings Astrology
                                    </h1>
                                    <p>Welcome to Blessings Astrology, your ultimate guide to unlocking the power of the stars and discovering your spiritual destiny. We are dedicated to helping you unleash your inner potential and align your life with the cosmos through the transformative power of astrology.
                                    </p>
                                    <a href="/about-us" className="button button-2">Read More</a>
                                </div>
                            </Col>
                            <Col lg={6} className="slid-img">
                                <img src={b1} className="img-fluid ms-lg-5" alt="Lord Ganesha" />
                            </Col>
                        </Row>
                    </Container>
                </SwiperSlide>
                {/* <SwiperSlide className="bg-1">
                <Container className="pt-lg-5 h-100">
                    <Row className="pt-lg-5 mt-2">
                        <Col lg={6} className="d-flex flex-column justify-content-center">
                            <div className="">
                                <h1>
                                Heading
                            </h1>
                            <h3>Sub heading</h3>
                            <p>akdf dlfkjddf sdkfjsdf sdkjdfnsdmf kjsddf ddfnkdf sddfndd fkjddf</p>
                            <button className="button button-2  ">Read More</button>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <img src={b2} className="img-fluid ms-lg-5" />
                        </Col>
                    </Row>
                </Container>
            </SwiperSlide> */}
            </Swiper>
        </>
    );
}
export default Carousel;