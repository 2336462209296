import React from "react";
import NavBar from "../nav/nav";
import { Helmet } from "react-helmet";
import Cancerblog from "../img/blog/blogcancer.png";



import "./cancer.css";


export default function Cancerpage() {
    return (
        <>
            <Helmet>

                <title>
                    All About Cancer Zodiac Sign By Dr. Surabhi Bhatnagar
                </title>
                <meta
                    name="description"
                    content="People belonging to this are in the first sign and therefore the most ~raw~ with their needs, demands and emotions. They can be needy."
                />
                <meta name="keywords" content="zodiac signs symbol, astrology, astrologer, blessings  astrologer, vastu shastra, numerology services, Taurus Zodiac" />

                <link rel="canonical" href="https://blessingsastrology.com/cancer" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:site_name" content="Blessings Astrology" />
                <meta property="og:title" content=" All About Cancer Zodiac Sign By Dr. Surabhi Bhatnagar" />
                <meta property="og:description" content="People belonging to this are in the first sign and therefore the most ~raw~ with their needs, demands and emotions. They can be needy." />
                <meta
                    property="og:url"
                    content=" https://blessingsastrology.com/cancer"
                />
                <meta
                    property="og:image"
                    content="https://blessingsastrology.com/static/media/taurusblog.fb3c41cad98026489577.png"
                />
                <meta
                    name="twitter:title"
                    content=" All About Cancer Zodiac Sign By Dr. Surabhi Bhatnagar"
                />
                <meta
                    name="twitter:description"
                    content="People belonging to this are in the first sign and therefore the most ~raw~ with their needs, demands and emotions. They can be needy."
                />
                <meta
                    name="twitter:image"
                    content="https://blessingsastrology.com/static/media/taurusblog.fb3c41cad98026489577.png"
                />
            </Helmet>

            <section className="inner-section cancer-page">
                <NavBar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Horoscope Prediction</li>

                            </ul>
                        </div>
                    </div>
                </div>

            </section>
            <section className="blogsection">
                <div className="container">
                    <div className="row">
                        <div className="section-head  mb-0 col-12 mt-lg-5 mt-5">
                            <h1 className="pb-2 "><span className="px-2">Cancer</span> Zodiac</h1>
                        </div>
                        <div className="col-12">

                            {/* <img src={Taurusblog} className="w-100 ariesimage" alt="Astrology Services"></img> */}
                            <div className="card-body ">
                                <h4 className="card-title mt-3"></h4>

                                <p className="card-text text-dark">Cancer signs fall  between 21st June and 22nd July .
The representation is of a crab.

                                </p>
                                <p className="text-dark">These people are intense, incredibly charismatic and attractive, but also possessive and moody. They are ruled by the Moon and  are passionate, creative, secretive, intuitive, and caring.
 

                                </p>
                                <p className="text-dark">They are mind readers.They can be little dramatic and attention seeking.They command loyalty and undivided attention . 


                                </p>
                                {/* <p className="text-dark">Taurus can be gentle as well as  they can be fierce, they don’t like to be coerced, they have their own  strong opinions which are difficult to change  and they love their home comforts. If they can manage to do all activities whether work, game, grocery , they will do it from their comfort zone ( home) only. 

                                </p> */}
                                <img src={Cancerblog} className="  ariesimage img-fluid w-100" alt="Astrology Services"></img>

                                <ul className="bulletpoints mt-4">
                                    <li>
                                        <strong>Intuitive :- </strong>  They are blessed with God like intuition. They know what other people are thinking and what they have already thought about.They can not be manipulated as they know by their intuition what is your mind saying as they are excellent mind readers.They are dangerous in this sense.




                                    </li>
                                    <li><strong>Blind( loyal)  :- </strong>  They are extremely loyal and clingy. They will turn blind and overlook everything about the person they love. But they demand the same in return. They want their lover to respond in the same manner as they do for them. Every scene of their lover’s life should have them in the center.


                                    </li>
                                    <li><strong>Highly moody :- </strong>  They can control their moods. You can see their different moods within an hour. They can be laughing at one instant and sad at another. 

                                    </li>
                                    <li><strong>Sentimental :- </strong>  They are highly emotional and sentimental for their relations. They never forget the people they got associated with even once. The fire ignited once  never gets cooled but remains stored in one corner of their heart. 
 

                                    </li>
                                    <li><strong>Changing moods and manipulative :- </strong>   They can be over passionate, indulgent,funny and adventurous on one side and can ask for payback on the other. They can present themselves as the wounded and hurt to gain sympathy.
 
 

                                    </li>
                                </ul>
                                {/* <p className=" text-dark">All Taurus obsess over, respect and manage their money. They enjoy investing and saving.
They are natural born food lovers and bloggers.They live to eat and cook.They can sit and chill at one place with a blanket for hours together if it is comfortable.They love attracting admiration and attention. They are expert flirts.They take shopping seriously. They can go to their favorite boutique or restaurant after all the busy and run down the whole day. 

                                </p> */}
                                <p className="text-dark"><strong>Traits:</strong> </p>
                                {/* <p className="text-dark">Taurus are naturally sexy people, as  their ruler  planet is  Venus, the planet of love, luxury, passion  and pleasure.  Taureans have a strong inner self-confidence and belief which they don’t brag about , and people get attracted to this quality of them as  they know that taureans  can be trusted. Taureans always go for exuberant and luxurious which is pleasing to others. . Taureans also know how to have a gala time, so they are always to be around.
</p> */}
                                {/* <p className="text-dark"><strong>Aries are attractive because :- </strong></p> */}
                                <ul>
                                    <li>
                                    They are highly compassionate and caring.



                                    </li>
                                    <li>They are too emotional and helping.


                                    </li>
                                    <li>They can be funny, attractive and clever too.



                                    </li>
                                    <li>.They can see through you and have deep eyes.




                                    </li>
                                    <li>They can be moody and show their different moods in a short span.



                                    </li>
                                    <li>Their smile says it all. The smile is genuine and true. They can light up the room with their smile.




                                    </li>
                                    
                                </ul>
                                <p className="text-dark"><strong>Career Choices :-</strong> </p>
                                <p className="text-dark"> They are intuitive, emotionally intelligent and people-friendly. Cancerians are excellent  where human relationship building and problem solving is there.They love  psychology, bonding, teaching, helping, and healing work.Medicine, teaching, hospitality, creativity, therapy and counseling, social work, interior décor and fashion can satisfy them to a large extent.
 

                                </p>
                                <p className="text-dark">       Cancerians are extremely loyal and hence commands loyalty and same behavior from you.They are a good confidant and can be trusted blindly.They  have  an insatiable appetite for sympathy, pampering, protection and compassion. They want to be looked after too.If you present too much logic with them then they will be turned off.

 

 </p>
                                <p className="text-dark"><strong>Cancerian Man
:
                                </strong></p>
                                <ul>
                                    <li>
                                    They are motivated to make intimate emotional connections.


                                    </li>
                                    <li>They are nurturing, committed and protective of their loved ones.


                                    </li>
                                    <li>They can be sarcastic to hide their emotional side.
.
                                    </li>
                                    <li>They can do anything for their love.


</li>
                                    <li> They can spend endless evenings in their home with love in peaceful surroundings.

                                    </li>
                                    <li>He takes trust and commitment seriously.


                                    </li>
                                    <li>He is a good listener and very receptive.


                                    </li>
                                    <li>He will feel your emotions as his own.

                                    </li>
                                    {/* <li>Over analyzing any problem and coming up with own conclusions with sticking on it only.

                                    </li>
                                    <li>hey can be boring sometimes.

                                    </li>
                                    <li>They love their home and comfort zone.

                                    </li>
                                    <li>They love food and are good cook. 
                                    </li> */}
                                </ul>
                                <p className="text-dark"><strong>Cancer woman
:
                                </strong></p>
                                <ul>
                                    <li>They are extremely affectionate.


                                    </li>
                                    <li>  They are highly emotional and their moods change swiftly.
                                    </li>
                                    <li>They are always on the move and navigating.

                                    </li>
                                    <li>They are nurturers and givers hence need to give  rest to their body too.

                                    </li>
                                    <li>They are warm and welcoming. They give positivity to all around them.


                                    </li>
                                    <li>They are caring, friendly and innocent. 

                                    </li>
                                    <li>They appreciate everything good around them.

                                    </li>
                                    {/* <li>They are moved by pure intentions as they are highly intuitive and can differentiate fake and genuine.

                                    </li>
                                    <li>They are unmatchable when it comes to love and care.
                                    </li>
                                    <li>She's a bit of a homebody and loves spending cozy, one-on-one time with her partner.
                                    </li>
                                    <li>She has a small circle of close friends rather than a wide group of casual acquaintances.</li>
                                    <li>A Taurus woman won’t be quick to change her opinions or uproot her life for someone.
</li> */}
                                    {/* <li>They are very impatient and cannot wait for things to happen.
                                    </li>
                                    <li>They are moody and impulsive and hence can take wrong decisions and drive things away.
                                    </li>
                                    <li>They get aggressive easily and are emotionally weak.
                                    </li> */}
                                </ul>

                            </div>


                        </div>
                    </div>
                </div>
            </section>


        </>

    );
}