import React from "react";
import "../servicepage.css";
import NavBar from "../../nav/nav";
import s1 from "../../img/service/1.png";
import s2 from "../../img/service/2.png";
import s3 from "../../img/service/inner/palm.png";
import s4 from "../../img/service/4.png";
import { Helmet } from "react-helmet";
import { Accordion, Tab, Tabs } from "react-bootstrap";

export default function palmistrypage() {
    return (
        <>
          <Helmet>
        
        <title>
          Best Palmist in India | Best Palm Reader | Blessings Astrology
        </title>
        <meta
          name="description"
          content="Palmistry is an age-old technique in which an astrologer reads and analyzes the palm of the native. It is the art of reading."
        />
        <meta name="keywords" content="astrology services, best astrologer in gurgaon, astrologer in gurgaon, Jyotis shastra, vastu shastra, vastu shastra consultant" />
        <link rel="canonical" href="https://www.blessingsastrology.com/palmistry-service" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta property="og:title" content="Best Palmist in India | Best Palm Reader | Blessings Astrology" />
        <meta property="og:description" content="Palmistry is an age-old technique in which an astrologer reads and analyzes the palm of the native. It is the art of reading." />
        <meta
          property="og:url"
          content="https://www.blessingsastrology.com/palmistry-service"
        />
        <meta
          property="og:image"
          content="https://www.blessingsastrology.com/static/media/astrology.76e4777e1dd32068cb37.png"
        />
        <meta
          name="twitter:title"
          content="Best Palmist in India | Best Palm Reader | Blessings Astrology"
        />
        <meta
          name="twitter:description"
          content="Palmistry is an age-old technique in which an astrologer reads and analyzes the palm of the native. It is the art of reading.
"
        />
        <meta
          name="twitter:image"
          content="https://www.blessingsastrology.com/static/media/astrology.76e4777e1dd32068cb37.png"
        />
      </Helmet>
            <section className="inner-section service-page">
                <NavBar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/services">Services</a></li>
                                <li>Palmistry</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">

                    <div className="row">
                        <div className="section-head t-head mb-0 col-12 mb-4">
                            <h4><span>Palmistry</span></h4>
                        </div>
                        <div className="col-lg-4">
                            <img src={s3} className="img-fluid w-100 h-100" alt="Palmistry Services" />
                        </div>
                        <div className="col-lg-8 service-inner-card">
                            <p>
                            Palmistry is an age old technique in which an astrologer reads and analyzes the palm of the native. It is the art of reading and predicting by seeing the shape,color, texture of palm , placement of fingers and thumb, types of nails and colors, alignment of mounts, lines and their arrangement. It involves chiromancy, chirology. It reads the physical features of the hands to interpret personality characteristics and predict future happenings and also explains past of the native as well as the present scenario.Within palm reading, hands are considered portals that shed invaluable insight. Palmistry is linked with astrology as there are different mounts and fingers which represents various planets. Signs and yogas depicted can predict any kind of good or bad happening and hence can help the native take precautions or take remedial actions.Dr. Surabhi Bhatnagar uses her expertise in Palmistry  to guide, assist, and solve problems, as well as suggest corrective actions.
                            </p>
                            <a href="/book-an-appointment" className="button button-2">Book An Appointment</a>
                        </div>
            </div>
            
            <div className="row">
            <div className="section-head text-center mb-0 col-12">
              <h4 className="pb-2 my-5">
                <span>FAQs</span>
              </h4>
              <h5>Wondering About Astrology? Find Your Answers Here</h5>
            </div>
            <div className="col-12 faq-main">
              <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                {/* <Tab eventKey={1} title="Astrology">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Why do I need astrology?
                      </Accordion.Header>
                      <Accordion.Body>
                        Astrology is the divine guidance where an astrologer
                        answers your questions after reading your birth chart
                        and gives solutions to your problems.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        What is a birth chart?
                      </Accordion.Header>
                      <Accordion.Body>
                        Birth chart is made after studying the position of
                        planets at the time of your birth. It needs the time ,
                        date and place of birth to be constructed.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        What if I don't have my birth details and I want
                        guidance through an astrological chart?
                      </Accordion.Header>
                      <Accordion.Body>
                        If you do not have birth details, then various other
                        methods like palmistry, face reading, tarot will help in
                        giving guidance. If you want astrological guidance
                        without sharing your birth details then Prashna Chart or
                        Horary chart helps in giving your answer.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        What is a Prashna Chart?
                      </Accordion.Header>
                      <Accordion.Body>
                        Prashna Chart is prepared keeping in mind the time of
                        question. It does not require any kind of details. It is
                        made after seeing the current position of the planets.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        Is Prashna chart accurate and how many questions it can
                        answer?
                      </Accordion.Header>
                      <Accordion.Body>
                        The Prashna chart is as accurate as any birth chart. It
                        can answer only one question at a time unlike birth
                        charts which can put light on past , present and future
                        in detail.. Every new question has a new Prashna chart.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        What is a Horary Chart ?
                      </Accordion.Header>
                      <Accordion.Body>
                        It is a chart which is made after asking you to choose
                        between some numbers. It then answers your questions. It
                        is also accurate.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        Which chart is more accurate; Horary or Prashna?
                      </Accordion.Header>
                      <Accordion.Body>
                        It only depends on the enquirer and the astrologer which
                        method to choose to give an answer.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>
                        How many questions can be answered by a birth chart?
                      </Accordion.Header>
                      <Accordion.Body>
                        It represents you , hence it is capable of answering
                        your paste , present and future.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>Do remedies work? </Accordion.Header>
                      <Accordion.Body>
                        Yes, remedies work if done with full dedication and
                        keeping faith. Remedies which are not harming anyone
                        else and done only to help yourself will definitely
                        work.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                      <Accordion.Header>
                        What kind of remedies are given by Blessings Astrology?
                      </Accordion.Header>
                      <Accordion.Body>
                        We give you simple, doable, easy, pocket friendly
                        remedies.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab> */}
                <Tab eventKey={1} title="Palmistry">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        How to get palm reading done?
                      </Accordion.Header>
                      <Accordion.Body>
                        You can send the clear picture of both palms front ,
                        back and sideways.{" "}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        How long does a palm reading last?
                      </Accordion.Header>
                      <Accordion.Body>
                        The lines in the palm keep on changing as time
                        progresses. It is better to reconsult after every 6
                        Months.{" "}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        How accurate is the palm reading?
                      </Accordion.Header>
                      <Accordion.Body>
                        Palm reading gives accurate answers and guides
                        correctly.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        Can I send only one hand for reading?
                      </Accordion.Header>
                      <Accordion.Body>
                        It is better to send pics of both hands for more
                        accurate reading.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab>
                {/* <Tab eventKey={3} title="Tarot reading" disabled>
                  Tab 3 content
                </Tab> */}
              </Tabs>
            </div>
          </div>
                    
                </div>
            </section>
        </>
    );
}