import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import abt from "../img/surabhi.png";
import NavBar from "../nav/nav";
import "./aboutpage.css";

import w1 from "../img/wcu/astro.png";
import w2 from "../img/wcu/hq.png";
import w3 from "../img/wcu/trust.png";
import w4 from "../img/wcu/exp.png";
import w5 from "../img/wcu/pra.png";
import w6 from "../img/wcu/hol.png";
import { Helmet } from "react-helmet";
export default function aboutpage() {
    return (
        <>
          <Helmet>
        
        <title>
          About Blessings Astrology | Divine Solutions For a Happy Life
        </title>
        <meta
          name="description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general."
        />
         <meta name="keywords" content="astrology services, best astrologer in gurgaon, astrologer in gurgaon, Jyotis shastra, astrological services" />

        <link rel="canonical" href="https://www.blessingsastrology.com/about-us" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta property="og:title" content="About Blessings Astrology | Divine Solutions For a Happy Life" />
        <meta property="og:description" content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general." />
        <meta
          property="og:url"
          content="https://www.blessingsastrology.com/about-us"
        />
        <meta
          property="og:image"
          content="https://www.blessingsastrology.com/static/media/surabhi.57e7b24608e0596cd1e3.png"
        />
        <meta
          name="twitter:title"
          content="About Blessings Astrology | Divine Solutions For a Happy Life"
        />
        <meta
          name="twitter:description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general.
"
        />
        <meta
          name="twitter:image"
          content="https://www.blessingsastrology.com/static/media/surabhi.57e7b24608e0596cd1e3.png"
        />
      </Helmet>
            <section className="inner-section about-page">
                <NavBar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>About us</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="about-section">
                    <Container className="py-lg-5">
                        <Row>
                            <Col xs={12} className="image-column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner-column wow fadeInLeft">
                                    <div className="author-desc">
                                        <h2>Dr. Surabhi Bhatnagar</h2>
                                        <span>Spiritual Consultant and Healer</span>
                                    </div>
                                    <figure className="image-1">
                                        {/* <a href="#" className="lightbox-image" data-fancybox="images"> */}
                                        <img title="Dr. Surabhi Bhatnagar" src={abt} alt="Dr. Surabhi Bhatnagar" />
                                        {/* </a> */}
                                    </figure>

                                </div>
                            </Col>
                            <Col xs={12} className="content-column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="section-head section-head-2 text-start mb-3">
                                        <h4 className="text-start"><span>About </span>Blessings</h4>
                                        <h3>Get to Know Blessings Astrology!</h3>
                                        {/* Your Trusted Guide to Unlocking the Power of the Stars and Discovering Your Spiritual Destiny. */}
                                    </div>
                                    <div className="text">
                                        <p>
                                            Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general.
                                            With a large user database and a wide range of services, you can find the perfect psychic to help you navigate your life.
                                        </p>
                                        <p>At Blessings Astrology, we have a strong focus on astrology, unlocking your future, and providing accurate predictions. Our accuracy is based on the enriched and vast experience of Dr. Surabhi Bhatnagar, allowing us to provide more accurate predictions than other similar services.
                                        </p>
                                        <p>
                                            Our mission is to provide comprehensive, accurate, and honest insight into all of life's important moments. We help you make better decisions in your life, such as choosing a new career or looking for love. If you want to learn more about yourself (or someone else), or if you want to know what's coming up in your future, then you must use the services of Blessings Astrology.

                                        </p>
                                        <p>
                                            We are a complete package for those interested in this amazing science, offering everything from horoscopes to detailed moon phase information. The reputation of Blessings Astrology is on a very high pedestal among the people who use our services. People find our site useful and convenient, as it contains not only horoscope forecasts but also other interesting information like moon phases, zodiac signs, dates, compatibility ratings, and more.

                                        </p>
                                    </div>

                                </div>
                            </Col>
                            <Col xs={12}>
                                <p>
                                    Our periodic horoscopes feature detailed descriptions of what will happen in each area of your life based on planetary influences. Each forecast also gives advice on how to capitalize on the good things and avoid the bad things happening. This feature also provides information about how long the planetary influences will last so you know when to expect them to end and what to expect when they do.

                                </p>
                                <p>
                                    We provide a safe and private experience for our clients. We do not allow any third party to track your activity or use your data without your knowledge. Our range of services includes love, relationship, career, and business. So, if you're looking for guidance in any area of your life, come and join us at Blessings Astrology and let us help you find the answers you're looking for.

                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <section className="card-section pb-4">
                    <div className="container">
                        <div className="row">
                            <div className="section-head text-center mb-0 col-12">
                                <h4 className="pb-2"><span>Why Choose</span> Us?</h4>
                                <h5>Choose Blessings Astrology for Your Spiritual Journey</h5>
                                <p>Unmatched Expertise, Compassionate Guidance, and Personalized Support!</p>
                            </div>
                            <div className="col-12 main-card-section">
                                <div className="row">

                                    <div className="col-md-6 p-0">
                                        <div className="three-section-card three-section-card-1">
                                            <div className="ts-icon">
                                                <img src={w1} className="img-fluid w-50" alt="Comprehensive Astrological Services" />
                                                <h3>
                                                    Comprehensive Astrological Services
                                                </h3>
                                            </div>
                                            <div className="ts-content">

                                                <p>
                                                    Blessings Astrology offers a wide range of astrology-related services to help you make better decisions in your life.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 p-0">
                                        <div className="three-section-card three-section-card-2">
                                            <div className="ts-icon">
                                                <img src={w2} className="img-fluid w-50" alt="High-Quality Astrological Forecasts and Advice" />
                                                <h3>
                                                    High-Quality Astrological Forecasts and Advice
                                                </h3>
                                            </div>
                                            <div className="ts-content">

                                                <p>
                                                    The platform has a reputation for providing high-quality astrological forecasts and advice, which can help you make informed decisions about your life.
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 p-0">
                                        <div className="three-section-card three-section-card-3">
                                            <div className="ts-icon">
                                                <img src={w3} className="img-fluid w-50" alt="Trustworthy and Confidential" />

                                                <h3>
                                                    Trustworthy and Confidential
                                                </h3>
                                            </div>
                                            <div className="ts-content">

                                                <p>
                                                    Blessings Astrology is committed to maintaining the highest standards of confidentiality and trust, ensuring that your personal information is kept safe and secure.

                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 p-0">
                                        <div className="ts-icon three-section-card three-section-card-7">
                                            <div className="ts-icon">
                                                <img src={w4} className="img-fluid w-50" alt="Expertise and Experience" />
                                                <h3>
                                                    Expertise and Experience
                                                </h3>
                                            </div>
                                            <p>
                                                Dr. Surabhi Bhatnagar's expertise and experience in the field of astrology makes clients comfortable and solutions are given in a practical manner.

                                            </p>
                                        </div>

                                    </div>
                                    <div className="col-md-6 p-0">
                                        <div className="ts-icon three-section-card three-section-card-4 three-section-card-5">
                                            <div className="ts-icon">
                                                <img src={w5} className="img-fluid w-50" alt="Practical Solutions" />
                                                <h3>
                                                    Practical Solutions
                                                </h3>
                                            </div>
                                            <p>
                                                Blessings Astrology provides practical solutions that are tailored to your individual needs and challenges, taking into account the demands of modern life.

                                            </p>
                                        </div>

                                    </div>
                                    <div className="col-md-6 p-0">
                                        <div className="ts-icon three-section-card three-section-card-4 three-section-card-6 border-end-0">
                                            <div className="ts-icon">
                                                <img src={w6} className="img-fluid w-50" alt="Holistic Approach" />
                                                <h3>
                                                    Holistic Approach
                                                </h3>
                                            </div>
                                            <p>
                                                Blessings Astrology takes a holistic approach to astrology, considering all aspects of your life to help you achieve balance, harmony, and success in all areas of your life.

                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}