import React from "react";
import NavBar from "../nav/nav";
import PhotoGallery from "./photogallery";
import ImageGallery from "./imagegallery";
import { Helmet } from "react-helmet";
export default function gallerypage() {

    return (
        <>
       <Helmet>
        
        <title>
        Blessings Astrology'S Gallery | Divine Solutions For a Happy Life
        </title>
        <meta
          name="description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general."
        />
        <meta name="keywords" content="astrology services, best astrologer in gurgaon, astrologer in gurgaon, Jyotis shastra, " />
        <link rel="canonical" href="https://www.blessingsastrology.com/gallery" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta property="og:title" content="Blessings Astrology'S Gallery | Divine Solutions For a Happy Life" />
        <meta property="og:description" content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general." />
        <meta
          property="og:url"
          content="https://www.blessingsastrology.com/gallery"
        />
        <meta
          property="og:image"
          content="https://www.blessingsastrology.com/static/media/astrology.76e4777e1dd32068cb37.png"
        />
        <meta
          name="twitter:title"
          content="Blessings Astrology'S Gallery | Divine Solutions For a Happy Life"
        />
        <meta
          name="twitter:description"
          content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general.
"
        />
        <meta
          name="twitter:image"
          content="https://www.blessingsastrology.com/static/media/astrology.76e4777e1dd32068cb37.png"
        />
      </Helmet>
            <section className="inner-section gallery-page">
                <NavBar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Gallery</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5">
                    <div className="row">
                        <div className="section-head col-12 t-head mb-4">
                            <h4><span>Photo</span> Gallery</h4>
                        </div>
                        <div className="col-12"> 
                            {/* <PhotoGallery /> */}
                            <ImageGallery />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}