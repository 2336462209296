
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaFacebookSquare, FaWhatsappSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import logo from "../img/blessings-astrology-logo.png";
import { NavLink } from "react-router-dom";
import "./nav.css";
import { IoMdMail } from 'react-icons/io';



function NavBar() {
    return (
        <>
            <div className="nav-container">
                <Container fluid="xxl" className='border-bottom py-3 px-xl-5 px-xxl-0'>
                    <Row className='text-center text-md-start'>
                        <Col md={6} lg={6} xxl={6}>
                            <Navbar.Brand href="/"><img src={logo} className="img-fluid"></img></Navbar.Brand>
                        </Col>
                        <Col md={3} lg={6} className="d-none d-lg-flex align-items-center pb-3 justify-content-end top-sm-icons">
                            <a href="mailto:surabhibhatnagar@blessingsastrology.com"><IoMdMail /></a>
                            <a href="https://wa.me/919821155251"><FaWhatsappSquare /></a>
                            <a href="https://m.facebook.com/blessings.astrologysolutions?_rdr" target={"_blank"}><FaFacebookSquare /></a>
                            <a href="https://www.instagram.com/blessings.astrologysolutions/?r=nametag" target={"_blank"}><FaInstagramSquare /></a>
                            <a href="https://youtube.com/channel/UCWOu9AwcqwDxXUa9ZRi7cEQ" target={"_blank"}><FaYoutubeSquare /></a>
                            <a className="pe-lg-0" href="https://www.linkedin.com/in/dr-surabhi-bhatnagar-7a470612b/" target={"_blank"}><FaLinkedin /></a>
                        </Col>
                    </Row>
                </Container>
                <Navbar expand="lg" sticky="top">


                    <Container fluid="xxl" className="px-xl-5 px-xxl-0">
                        {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} className="order-2 order-md-1" />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand`}
                            aria-labelledby={`offcanvasNavbarLabel-expand`}
                            placement="start"
                        >
                            <Offcanvas.Header className="ms-auto" closeButton>
                                {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                  Offcanvas
                </Offcanvas.Title> */}
                            </Offcanvas.Header>
                            <Offcanvas.Body className="me-md-auto">
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Nav.Link
                                        href="/" className="ps-lg-0 nav-link">Home</Nav.Link>
                                    <Nav.Link href="about-us">About Us</Nav.Link>
                                    <Nav.Link href="/services">Our Services</Nav.Link>
                                    <Nav.Link href="/horoscope-prediction">Horoscope </Nav.Link>
                                    <Nav.Link href="/our-products">Our Products</Nav.Link>
                                    <Nav.Link href="/blogs">Blogs</Nav.Link>
                                    <Nav.Link href="/gallery">Gallery</Nav.Link>
                                    <Nav.Link href="/media">Media</Nav.Link>
                                    <Nav.Link href="/our-books">Our Books</Nav.Link>


                                    <Nav.Link href="/book-an-appointment">Book An Appointment</Nav.Link>

                                </Nav>
                            </Offcanvas.Body>

                        </Navbar.Offcanvas>
                        <Nav className="d-md-flex d-lg-none order-1 order-md-2 d-flex flex-row nav-sm-icon">
                            <Nav.Link href="https://m.facebook.com/blessings.astrologysolutions?_rdr" target={"_blank"}><FaFacebookSquare /></Nav.Link>
                            <Nav.Link href="https://www.instagram.com/blessings.astrologysolutions/?r=nametag" target={"_blank"}><FaInstagramSquare /></Nav.Link>
                            <Nav.Link href="https://youtube.com/channel/UCWOu9AwcqwDxXUa9ZRi7cEQ" target={"_blank"}><FaYoutubeSquare /></Nav.Link>
                            <Nav.Link className="pe-lg-0" href="https://www.linkedin.com/in/dr-surabhi-bhatnagar-7a470612b/" target={"_blank"}><FaLinkedin /></Nav.Link>
                            <Nav.Link href="https://wa.me/919821155251"><FaWhatsappSquare /></Nav.Link>
                            <Nav.Link href="mailto:surabhibhatnagar@blessingsastrology.com"><IoMdMail /></Nav.Link>

                        </Nav>
                    </Container>
                </Navbar>
            </div>
        </>
    );
}

export default NavBar;