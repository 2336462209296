import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import abt from "../img/surabhi.png";
import NavBar from "../nav/nav";
import { Helmet } from "react-helmet";
import Geminiblog from "../img/blog/Gemini.png";
import bloggemini from "../img/blog/bloggemini.png";
import Sagittarius from "../img/horoscope/Saggitarius.png"
import newbookcover from "../img/newbookcover.jpeg"
import newbook from "../img/new-book.jpeg"
import abt from "../img/surabhi.png";
import author from "../img/author.jpeg";
// import abt from "../../img/surabhi.png";
// import { FacebookEmbed } from 'react-social-media-embed';





import "./books.css";
import { FacebookEmbed } from "react-social-media-embed";


export default function BooksPage() {
    return (
        <>
            <Helmet>

                <title>
                    A Book on Vastu: BY DR SURABHI BHATNAGAR                </title>
                <meta
                    name="description"
                    content="Dive into the world of Vastu with OurBooks' enlightening read. Uncover the science behind balancing your environment and unlock your true potential."
                />
                {/* <meta name="keywords" content="zodiac signs symbol, astrology, astrologer, blessings  astrologer, vastu shastra, numerology services, Aries Zodiac" /> */}

                <link rel="canonical" href="https://www.blessingsastrology.com/our-books" />                <meta property="og:locale" content="en_US" />
                <meta property="og:site_name" content="A Book on Vastu: BY DR SURABHI BHATNAGAR" />
                <meta property="og:title" content=" All About Gemini Zodiac Sign By Dr. Surabhi Bhatnagar" />
                <meta property="og:description" content="Dive into the world of Vastu with OurBooks' enlightening read. Uncover the science behind balancing your environment and unlock your true potential." />
                <meta
                    property="og:url"
                    content="https://www.blessingsastrology.com/our-books"
                />
                <meta
                    property="og:image"
                    content="https://www.blessingsastrology.com/static/media/newbookcover.155cbe3ad418366f8a6e.jpeg"
                />
                <meta
                    name="twitter:title"
                    content="A Book on Vastu: BY DR SURABHI BHATNAGAR"
                />
                <meta
                    name="twitter:description"
                    content="Dive into the world of Vastu with OurBooks' enlightening read. Uncover the science behind balancing your environment and unlock your true potential."
                />
                <meta
                    name="twitter:image"
                    content="https://blessingsastrology.com/static/media/app.1ef3a7123b825bd66135.png"
                />
            </Helmet>

            <section className="inner-section book-page">
                <NavBar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Horoscope Prediction</li>

                            </ul>
                        </div>
                    </div>
                </div>

            </section>


            <section className="blogsection py-3">
                <div className="container">
                    <div className="row">
                        <div className="section-head  mb-0 col-12 mt-lg-5 mt-5">
                            <h1 className="pb-2 "><span className="px-2">About The </span>Book </h1>
                        </div>
                        <div className="col-12">
                            <p>This book on Vastu aims to equip people with knowledge of balancing their environment and understanding the flaws that might be present in their immediate environment and blocking progress gaining them the desired outcomes of their actions.</p>
                            <p>There are many writings available in the market by various other writers but this book” title” has an ambition to strengthen its readers and help them make their decisions towards being happy, prosperous, and wealthy.</p>
                            <p>This book explains Vastu as a science emphasizing its similarity and synchronization with the rules and laws of nature which is very important in today’s scientific and fact-driven world.
                                I hope This book will be successful in attaining its goal of making all people empowered and self-sufficient in balancing their surroundings and reaching their destination. This book aspires to touch your heart and get a place in your lives as a consultant in hand. </p>
                        </div>
                        {/* <div className="section-head  mb-0 col-12 mt-lg-5 mt-5">
                            <h1 className="pb-2 "><span className="px-2">Our</span>Books </h1>
                        </div> */}
                        <div className="col-lg-6 col-12">
                            <div className="img-box">
                                <img src={newbookcover} className="w-100 h-100" alt="newbookcover" />
                            </div>

                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="img-box mt-3 mt-lg-0">
                                <img src={newbook} className="w-100 h-100" alt="newbook" />
                            </div>
                        </div>
                        <div className="d-none d-lg-block text-center my-4">
                            <a href="https://amzn.in/d/fnIHnV8" class="button button-2 " target="_blank">Buy Now</a>

                        </div>
                        <div className="d-block d-lg-none my-4 text-center">
                            <a href="https://amzn.in/d/fnIHnV8" class="button button-2 ">Buy Now</a>


                        </div>

                        <div className="col-lg-6 col-12">
                            <iframe width="100%" height="540" src="https://www.youtube.com/embed/fWOiE87Ov7g" title="Sacred Spaces बुक लांच के दौरान क्या कहा बुक आथर डॉ सुरभि भटनागर" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                        </div>

                        <div className="col-lg-6 col-12 mt-3 mt-lg-0">
                            <FacebookEmbed url="https://www.facebook.com/Timekhaber/videos/3493879637591525/?mibextid=oFDknk&rdid=bsnJ6CvdPravpFyJ" width="100%" />

                        </div>
                        <div className="col-lg-6 col-12">
                            {/* <div className="img-box mt-3 mt-lg-0">
                                <img src={newbook} className="w-100 h-100" alt="newbook" />
                            </div> */}
                        </div>

                        <div className=" col-lg-12 py-lg-4">
                            <h3 className="card-title  mb-lg-3"></h3>
                            {/* <p>Author Dr. Surabhi Bhatnagar with her motto to raise awareness among all towards doing good for oneself and bring a positive change in lives while keeping our foundation of traditional values intact following our age-old and tested laws of nature along with the newest scientific skills and knowledge, has provided her knowledge, researched factors and experiences in this book to make your reading enriched and empower you with the tools to help yourself towards the path of success.</p> */}
                        </div>

                        <div className="col-lg-4 mt-lg-0 mt-4">
                            <img src={author} className="img-fluid book-author-img" alt="newbookcover" />

                        </div>
                        <div className="col-lg-8">
                            <div className="section-head section-head-2 text-start mb-3 mt-lg-0 mt-3">
                                <h4 className="text-start"><span>About The</span> Author</h4>
                                <h3>Dr. Surabhi Bhatnagar</h3>
                                {/* Your Trusted Guide to Unlocking the Power of the Stars and Discovering Your Spiritual Destiny. */}
                            </div>
                            <p>Author Dr. Surabhi Bhatnagar with her motto to raise awareness among all towards doing good for oneself and bring a positive change in lives while keeping our foundation of traditional values intact following our age-old and tested laws of nature along with the newest scientific skills and knowledge, has provided her knowledge, researched factors and experiences in this book to make your reading enriched and empower you with the tools to help yourself towards the path of success.</p>
                            <p>This book provides basic knowledge about principles, working, process, and application of vastu. Various actual case studies have been discussed which help you know the need for Vastu Science in our lives. Some observations, general tips, and common questions asked are also discussed to give a fair and honest idea to the readers to help them decide and take action towards the achievement of their goals.</p>
                            <div className="  mt-4">
                                <a href="/about-us" class="button button-2 ">Read More</a>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="blogsection">
                <div className="container">
                    <div className="row">
                        <div className="section-head  mb-0 col-12 mt-lg-5 mt-5">
                            <h1 className="pb-2 "><span className="px-2">Our</span>Books </h1>
                        </div>
                        <div className="col-md-5 col-12">

                            <img src={noimage} className="w-100 ariesimage" alt="Astrology Services"></img>
                            <figure className="image-1">
                                    <a href="#" className="lightbox-image" data-fancybox="images">
                                    <img title="Dr. Surabhi Bhatnagar" src={abt} alt="Dr. Surabhi Bhatnagar" />
                                    </a>
                                </figure>


                        </div>
                        <div className="col-md-7 col-12">
                            <h3 className="card-title mt-3 ">Author</h3>
                            <h5 className="card-title mt-2 mb-2">Dr. Surabhi Bhatnagar</h5>
                            <p>Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general. With a large user database and a wide range of services, you can find the perfect psychic to help you navigate your life.</p>
                            <p>At Blessings Astrology, we have a strong focus on astrology, unlocking your future, and providing accurate predictions. Our accuracy is based on the enriched and vast experience of Dr. Surabhi Bhatnagar, allowing us to provide more accurate predictions than other similar services.</p>
                            <p>Our mission is to provide comprehensive, accurate, and honest insight into all of life's important moments. We help you make better decisions in your life, such as choosing a new career or looking for love. </p>
                            <p>We are a complete package for those interested in this amazing science, offering everything from horoscopes to detailed moon phase information. The reputation of Blessings Astrology is on a very high pedestal among the people who use our services. People find our site useful and convenient, as it contains not only horoscope forecasts but also other interesting information like moon phases, zodiac signs, dates, compatibility ratings, and more.</p>

                            <a href="https://wa.me/+919821155251" class="button button-2">Click Here To Buy A Copy</a>

                        </div>
                    </div>
                </div>
            </section> */}


        </>

    );
}