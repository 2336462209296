import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./about.css";
import abt from "../../img/surabhi.png";
import NavBar from "../../nav/nav";
function About() {
    return (
        <>

            <section className="about-section mb-lg-5">
                <Container>
                    <Row>
                        <Col xs={12} className="image-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-column wow fadeInLeft">
                                <div className="author-desc">
                                    <h2>Dr. Surabhi Bhatnagar</h2>
                                    <span>Spiritual Consultant and Healer</span>


                                </div>
                                <figure className="image-1">
                                    {/* <a href="#" className="lightbox-image" data-fancybox="images"> */}
                                    <img title="Dr. Surabhi Bhatnagar" src={abt} alt="Dr. Surabhi Bhatnagar" />
                                    {/* </a> */}
                                </figure>

                            </div>
                        </Col>
                        <Col xs={12} className="content-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="section-head section-head-2 text-start mb-3">
                                    <h4 className="text-start"><span>About </span>Blessings</h4>
                                    <h3>Your Personal Guide to Cosmic Blessings and Divine Destiny</h3>
                                    {/* Your Trusted Guide to Unlocking the Power of the Stars and Discovering Your Spiritual Destiny. */}
                                </div>
                                <div className="text">
                                    Blessings Astrology is headed by Dr. Surabhi Bhatnagar, an eminent and international astrologer. Blessings Astrology was conceptualised in 2020 to help people in need. It aims to assist people in their problems and bring them out of despair by giving simple, doable, and easy solutions. It also aims to prevent people from falling into the wrong hands and wasting their money and time. We understand the value of your time and the tough and busy schedules you go through. We provide various services for you so that you can get the best from the best. Divine solutions provided not only help you get solutions to existing problems but also guide you to live your life as per planetary requirements so that you have a divine and blessed hand on you always.

                                </div>
                                <div className="btn-box">
                                    <a href="/about-us" className="button button-2">Read More</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );
}
export default About;