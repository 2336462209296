import g1 from "../img/gallery/1.jpg";
import g2 from "../img/gallery/2.jpg";
import g3 from "../img/gallery/3.jpg";
import g4 from "../img/gallery/4.jpg";
import g5 from "../img/gallery/5.jpg";
import g6 from "../img/gallery/6.jpg";
import g7 from "../img/gallery/7.jpg";
import g8 from "../img/gallery/8.jpg";
import g9 from "../img/gallery/9.jpg";
import g10 from "../img/gallery/10.jpg";
import g11 from "../img/gallery/11.jpg";
import g12 from "../img/gallery/12.jpg";
import g13 from "../img/gallery/13.jpg";
import g14 from "../img/gallery/14.jpg";
import g15 from "../img/gallery/15.jpg";
import g16 from "../img/gallery/16.jpg";
import g17 from "../img/gallery/17.jpg";
import g18 from "../img/gallery/18.jpg";
import g19 from "../img/gallery/19.jpg";
import g20 from "../img/gallery/20.jpg";
import g21 from "../img/gallery/21.jpg";
import g22 from "../img/gallery/22.jpg";
import g23 from "../img/gallery/23.jpg";
import g24 from "../img/gallery/24.jpg";
import g25 from "../img/gallery/25.jpg";
import g26 from "../img/gallery/26.jpg";
import g27 from "../img/gallery/27.jpg";
import g28 from "../img/gallery/28.jpg";
import g29 from "../img/gallery/29.jpg";
import g30 from "../img/gallery/30.jpg";
import g31 from "../img/gallery/31.jpg";
import g32 from "../img/gallery/32.jpg";
import g33 from "../img/gallery/33.jpg";
import g34 from "../img/gallery/34.jpg";
import g35 from "../img/gallery/35.jpg";
import g36 from "../img/gallery/36.jpg";
import g37 from "../img/gallery/37.jpg";
import g38 from "../img/gallery/38.jpeg";
import g39 from "../img/gallery/39.jpeg";
import g40 from "../img/gallery/40.jpeg";
import g41 from "../img/gallery/41.jpeg";
import g42 from "../img/gallery/42.jpeg";
import g43 from "../img/gallery/43.jpeg";
import g44 from "../img/gallery/44.jpeg";
export const photos = [
    {
        src: g1,
        width: 4,
        height: 3,
        alt:"Media Gallery"
    },
    {
        src: g2,
        width: 1,
        height: 1,
        alt:"conditional club of india (Dr. Surabhi Bhatnagar)"
    },
    {
        src: g3,
        width: 3,
        height: 4,
        alt:"Indian Astrology Awards"
    },
    {
        src: g4,
        width: 3,
        height: 4,
        alt:"Tero Queen Award"
    },
    {
        src: g5,
        width: 3,
        height: 4,
        alt:"64th Astrological convention Award (Dr. Surabhi Bhatnagar)"
    },
    {
        src: g6,
        width: 4,
        height: 3,
        alt:"Dr. Surabhi Bhatnagar"
    },
    {
        src: g9,
        width: 3,
        height: 4,
        alt:"Dr. Surabhi Bhatnagar"
    },
    {
        src: g7,
        width: 4,
        height: 3,
        alt:"International astrology fedration Inc.'s Certificate to Dr. Surbhi Bhatnagar"
    },
    {
        src: g8,
        width: 4,
        height: 3,
        alt:"Dr. Surabhi Bhatnagar"
    },
    {
        src: g10,
        width: 4,
        height: 3,
        alt:"Dr. Surabhi Bhatnagar"
    },
    {
        src: g11,
        width: 1,
        height: 1,
        alt:"Invitation card of astro Surabhi Bhatnagar"
    },
    {
        src: g13,
        width: 3,
        height: 4,
        alt:"Invitation card of astro Surabhi Bhatnagar"
    },
    {
        src: g14,
        width: 3,
        height: 4,
        alt:"Award of Jyotish Prangan"
    },
    {
        src: g12,
        width: 3,
        height: 4,
        alt:"Meeting with Mrs Nutan and Dr. harshvardhan Goal, Formar union minister of Health and FW"
    },
    {
        src: g15,
        width: 4,
        height: 3,
        alt:"Global Achiver Awards 2022 (Special Guest)"
    },
    {
        src: g16,
        width: 3,
        height: 4,
        alt:"Dr. Surabhi Bhatnagar"
    },
    {
        src: g19,
        width: 4,
        height: 3,
        alt:"Dr. Surabhi Bhatnagar"
    },
    {
        src: g18,
        width: 4,
        height: 3,
        alt:"Dr. Surabhi Bhatnagar (Global Achiver Award)"
    },
    {
        src: g20,
        width: 4,
        height: 3,
        alt:"Dr. Surabhi Bhatnagar "
    },
    {
        src: g23,
        width: 1,
        height: 1,
        alt:"Meeting with Mukangira Jacqueline High Commisssioner of Republic of Rwanda"
    },
    {
        src: g21,
        width: 3,
        height: 4,
        alt:"Global Achiver Awards 2022"
    },
    {
        src: g17,
        width: 3,
        height: 4,
        alt:"Dr. Surabhi Bhatnagar "
    },
    {
        src: g22,
        width: 3,
        height: 4,
        alt:"Global Achiver Awards 2022"
    },
    {
        src: g24,
        width: 4,
        height: 3,
        alt:"Dr. Surabhi Bhatnagar "
    },
    {
        src: g25,
        width: 4,
        height: 4,
        alt:"Dr. Surabhi Bhatnagar "
    },
    {
        src: g26,
        width: 2,
        height: 3,
        alt:"Dr. Surabhi Bhatnagar "
    },
    {
        src: g27,
        width: 3,
        height: 3,
        alt:"Dr. Surabhi Bhatnagar "
    },
    {
        src: g28,
        width: 3,
        height: 3,
        alt:"Dr. Surabhi Bhatnagar "
    },
    {
        src: g29,
        width: 3,
        height: 3,
        alt:"Award of Dr. Surabhi Bhatnagar"
    },
    {
        src: g30,
        width: 1,
        height: 1,
        alt:"Laal Kitab"
    },
    {
        src: g31,
        width: 3,
        height: 4,
        alt:"Award of Dr. Surabhi Bhatnagar"
    },
    {
        src: g32,
        width: 3,
        height: 4,
        alt:"Award of Sita Jyotish"
    },
    {
        src: g33,
        width: 3,
        height: 4,
        alt:"Award of Sita Jyotish"
    },
    {
        src: g34,
        width: 4,
        height: 3,
        alt:"Dr. Surabhi Bhatnagar "
    },
    {
        src: g35,
        width: 3,
        height: 4,
        alt:"Invitation letter"
    },
    {
        src: g36,
        width: 4,
        height: 3,
        alt:"Sita Jyotish"
    },
    {
        src: g37,
        width: 4,
        height: 3,
        alt:"The Indian Astro Award"
    },
    {
        src: g38,
        width: 4,
        height: 3,
        alt:"Excellence award for exemplary contribution"
    },
    {
        src: g39,
        width: 2,
        height: 3,
        alt:"Excellence award for exemplary contribution"
    },
    {
        src: g40,
        width: 2,
        height: 3,
        alt:"Excellence award for exemplary contribution"
    },
    {
        src: g41,
        width: 4,
        height: 2,
        alt:"Excellence award for exemplary contribution"
    },
    {
        src: g42,
        width: 2,
        height: 2,
        alt:"Excellence award for exemplary contribution"
    },
    {
        src: g43,
        width: 2,
        height: 3,
        alt:"Excellence award for exemplary contribution"
    }
    ,
    {
        src: g44,
        width: 1,
        height: 2,
        alt:"Excellence award for exemplary contribution"
    }
];
