import React, { Component, useState } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./footer.css";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

import { FaFacebookSquare, FaWhatsapp } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import logo from "../img/blessings-astrology-logo.png";
import { FiPhoneCall } from "react-icons/fi";

import ConsultForm from "../home/ConsultForm/ConsultForm";

function Footer() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* // <!-- Remove the container if you want to extend the Footer to full width. --> */}
      <Container className="mt-5 bg-light" fluid>
        <Container>
          <footer className="text-lg-start">
            {/* <!-- Grid container --> */}
            <Container className="py-3 border-bottom">
              {/* <!--Grid row--> */}
              <Row className="mt-4 pb-3">
                {/* <!--Grid column--> */}
                <Col md={12} lg={12} className="mb-4 mb-md-0 text-center">
                  <a href="/">
                    <img src={logo} className="mb-3 footer-logo" />
                  </a>
                  <p>
                    Thank you for choosing Blessings Astrology. <br /> We are
                    dedicated to providing you with personalized and insightful
                    astrological readings. <br />{" "}
                    <a className="f-book-link" href="/book-an-appointment">
                      {" "}
                      Book an Appointment Now!
                    </a>
                  </p>
                  <div className="mt-4 footer-sm mb-3">
                    {/* <h3>Follow Us</h3> */}
                    <a
                      className="ms-2"
                      href="https://m.facebook.com/blessings.astrologysolutions?_rdr"
                      target={"_blank"}>
                      <FaFacebookSquare />
                    </a>
                    <a
                      className="ms-2"
                      href="https://www.instagram.com/blessings.astrologysolutions/?r=nametag"
                      target={"_blank"}>
                      <FaInstagramSquare />
                    </a>
                    <a
                      className="ms-2"
                      href="https://youtube.com/channel/UCWOu9AwcqwDxXUa9ZRi7cEQ"
                      target={"_blank"}>
                      <FaYoutubeSquare />
                    </a>
                    <a
                      className="ms-2"
                      href="https://www.linkedin.com/in/dr-surabhi-bhatnagar-7a470612b/"
                      target={"_blank"}>
                      <FaLinkedin />
                    </a>
                  </div>
                  <a href="/privacy-policy" className="border-bottom border-2 pb-1 border-dark">Privacy Policy</a>
                </Col>
                {/* <!--Grid column--> */}
                {/* <Col md={6} lg={4} className="mb-4 mb-md-0 ps-lg-5">
                                    <h5 className="text-uppercase mb-4 pb-1">Quick Links</h5>

                                    <ul >
                                        <li className="mb-3">
                                            <a href="/"> <span className="fa-li"><i className="fas fa-home"></i></span><span className="ms-2">Home</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/"><span className="fa-li"><i className="fas fa-envelope"></i></span><span className="ms-2">About Us</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/"><span className="fa-li"><i className="fas fa-phone"></i></span><span className="ms-2">Remedies</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/"><span className="fa-li"><i className="fas fa-print"></i></span><span className="ms-2">Book An Appointment</span>
                                            </a>
                                        </li>
                                    </ul>
                                </Col> */}
                {/* <!--Grid column--> */}
                {/* <!--Grid column--> */}

                {/* <!--Grid column--> */}
                {/* <Col md={6} lg={2} className="mb-4 mb-md-0">
                                    <h5 className="text-uppercase mb-4">Contact Us</h5>

                                    
                                    <ul >
                                        <li className="mb-3">
                                            <a href="/"> <span className="fa-li"><i className="fas fa-home"></i></span><span className="ms-2">Delhi, INDIA</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/"><span className="fa-li"><i className="fas fa-envelope"></i></span><span className="ms-2">info@example.com</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/"><span className="fa-li"><i className="fas fa-phone"></i></span><span className="ms-2">+ 01 234 567 88</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/"><span className="fa-li"><i className="fas fa-print"></i></span><span className="ms-2">+ 01 234 567 89</span>
                                            </a>
                                        </li>
                                    </ul>
                                </Col> */}
                {/* <!--Grid column--> */}
                {/* <Col md={6} lg={4} className="mb-4 mb-md-0">
                                    <h5 className="text-uppercase mb-4">Timing</h5>

                                    <table className="table">
                                        <tbody className="font-weight-normal">
                                            <tr>
                                                <td>Monday to Friday:</td>
                                                <td>10.00 am to 5.30 pm</td>
                                            </tr>
                                            <tr>
                                                <td>Saturday and Sunday:</td>
                                                <td>12.00 pm to 5:00pm</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                    <ul >
                                        <li className="mb-3">
                                            <a href="/"> <span className="fa-li"><i className="fas fa-home"></i></span><span className="ms-2">Delhi, INDIA</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/"><span className="fa-li"><i className="fas fa-envelope"></i></span><span className="ms-2">info@example.com</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/"><span className="fa-li"><i className="fas fa-phone"></i></span><span className="ms-2">+ 01 234 567 88</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/"><span className="fa-li"><i className="fas fa-print"></i></span><span className="ms-2">+ 01 234 567 89</span>
                                            </a>
                                        </li>
                                    </ul>
                                </Col> */}
              </Row>
              {/* <!--Grid row--> */}
            </Container>
            {/* <!-- Grid container --> */}

            {/* <!-- Copyright --> */}
            <Row className="py-3 flex-column-reverse flex-lg-row justify-content-lg-between">
              <Col lg={6} className="text-center text-lg-start">
                Design{" "}
                <a href="http://globaltrendz.com/" target="_blank">
                  Global Trendz
                </a>
              </Col>
              <Col lg={6} className="text-center text-lg-end">
                &copy; {moment().format("YYYY")} Blessings Astrology. All Rights
                Reserved.
              </Col>
            </Row>
            {/* <!-- Copyright --> */}
          </footer>
        </Container>
      </Container>
      {/* <!-- End of .container --> */}

      {window.location.pathname.split("/")[1] != "book-a-slot" ? (
        <>
          <div class="floating_btn call_icon">
            <a href="https://wa.me/+919821155251" class="whatsapp-btn mb-3"><FaWhatsapp />
            </a>

            {/* <div class="call_icon"> */}
            <a href="tel:+919821155251">
              <FiPhoneCall /> Call Now
            </a>
            {/* </div> */}
          </div>
          <div class="floating_btn">
            <div class="contact_icon">
              <button
                className="button bounce"
                onClick={() => {
                  handleShow(true);
                }}>
                Ask Now
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* **** Ask consultent ****  */}

      {/* *** Consult button **** */}
      <Modal
        show={show}
        onHide={handleClose}
        className="d-flex align-items-center">
        <Modal.Header closeButton style={{ zIndex: "999" }}></Modal.Header>
        <Modal.Body>
          <ConsultForm setShow={setShow} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Footer;
