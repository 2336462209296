import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./services.css";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { BsChevronDoubleRight } from "react-icons/bs";
import sbg from "../../img/2.png";
import s1 from "../../img/service/1.png";
import s2 from "../../img/service/2.png";
import s3 from "../../img/service/3.png";
import s4 from "../../img/service/4.png";

function Services() {
    return (
        <>

            <Container fluid className="bg-light">
                <Row>
                    <Col lg={5} className="px-0">
                        <img src={sbg} className="img-fluid w-100 h-100" alt="Astrology Services"></img>
                    </Col>
                    <Col lg={7} className="d-flex p-3 section-head flex-column mb-0 py-5">

                        <h4>
                            <span> Our </span>Services
                        </h4>
                        <p>We offer a range of services to help you gain deeper insights into your life and make positive changes. Our experienced team of experts includes numerologists, palmists, tarot readers, Reiki (Grand Masters), angel healers, signature and handwriting analysts, dream analysts, and Muhurat experts.</p>

                        <Row>
                            <div className="col-md-6 py-md-2 px-md-3 mb-3">
                                <div className="service-card">
                                    <div className="icon-wrapper">
                                        <img src={s1} alt="Astrology Services"></img>
                                    </div>
                                    <h3>Astrology</h3>
                                    <p>
                                        Astrology, also known as Jyotish, is an ancient...
                                    </p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <a href="/astrology-service" className="button button-2">Read More </a>
                                        <a href="/book-an-appointment" className="button button-2">Book Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 py-md-2 px-md-3 mb-3">
                                <div className="service-card">
                                    <div className="icon-wrapper">
                                        <img src={s2} alt="Vastu Shastra services"></img>
                                    </div>
                                    <h3>Vastu Shastra</h3>
                                    <p>
                                        Vastu Shastra is a divine science that we have...
                                    </p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <a href="/vastu-shastra-service" className="button button-2">Read More</a>
                                        <a href="/book-an-appointment" className="button button-2">Book Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 py-md-2 px-md-3 mb-3 mb-lg-0">
                                <div className="service-card">
                                    <div className="icon-wrapper">
                                        <img src={s3} alt="Handwriting and Signature Analysis (Astrology)"></img>
                                    </div>
                                    <h3>Handwriting & Signature Analysis</h3>
                                    <p>
                                        "We can judge a person by looking at his or...
                                    </p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <a href="/handwriting-signature-analysis" className="button button-2">Read More</a>
                                        <a href="/book-an-appointment" className="button button-2">Book Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 py-md-2 px-md-3 mb-3 mb-lg-0">
                                <div className="service-card">
                                    <div className="icon-wrapper">
                                        <img src={s4} alt="Tarot Card Reading"></img>
                                    </div>
                                    <h3>Tarot</h3>
                                    <p>
                                        Tarot card reading is an ancient technique that was popular in Europe centuries ago and has...
                                    </p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <a href="/tarot-service" className="button button-2">Read More</a>
                                        <a href="/book-an-appointment" className="button button-2">Book Now</a>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <div className="text-center mt-3">
                            <a href="/services" className="button button-2">View More</a>
                        </div>
                    </Col>

                </Row>
            </Container>

        </>
    );
}
export default Services;