import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import abt from "../img/surabhi.png";
import NavBar from "../nav/nav";

import "./thankyou.css";

import w1 from "../img/wcu/astro.png";
import w2 from "../img/wcu/hq.png";
import w3 from "../img/wcu/trust.png";
import w4 from "../img/wcu/exp.png";
import w5 from "../img/wcu/pra.png";
import w6 from "../img/wcu/hol.png";
import { Helmet } from "react-helmet";
import aries from "../img/horoscope/aries.png"
import taurus from "../img/horoscope/taurus.png"
import gemini from "../img/horoscope/gemini.png"
import cancer from "../img/horoscope/cancer.png"
import leo from "../img/horoscope/leo.png"
import virgo from "../img/horoscope/virgo.png"
import libra from "../img/horoscope/libra.png"
import scorpio from "../img/horoscope/Scorpio.png"
import Sagittarius from "../img/horoscope/Saggitarius.png"
import capricorn from "../img/horoscope/Capricorn.png"
import aquarius from "../img/horoscope/Aquarius.png"
import pisces from "../img/horoscope/Pisces.png"




export default function thankyoupage() {
    return (
        <>
            <Helmet>

                <title>
                    Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar
                </title>
                <meta
                    name="description"
                    content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general."
                />
                <meta name="keywords" content="Horoscope Prediction, Aries horoscope, Taurus horoscope, taurus weekly horoscope, Gemini horoscope, Cancer horoscope, weekly Leo horoscope, Virgo horoscope, virgo weekly horoscope, weekly Libra horoscope" />

                <link rel="canonical" href="https://blessingsastrology.com/horoscope-prediction" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:site_name" content="Blessings Astrology" />
                <meta property="og:title" content="Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar" />
                <meta property="og:description" content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general." />
                <meta
                    property="og:url"
                    content="https://blessingsastrology.com/horoscope-prediction"
                />
                <meta
                    property="og:image"
                    content="https://blessingsastrology.com/static/media/horoscopebg.dc48382d7221fd269388.png"
                />
                <meta
                    name="twitter:title"
                    content="Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar"
                />
                <meta
                    name="twitter:description"
                    content="Predict your weekly Horoscope By Dr. Surabhi Bhatnagar - Best Astrologer in India, Check your Moon and Ascendant Signs. Aries Horoscope Prediction."
                />
                <meta
                    name="twitter:image"
                    content="https://blessingsastrology.com/static/media/horoscopebg.dc48382d7221fd269388.png"
                />
            </Helmet>
            <section className="inner-section horoscope-page">
                {/* <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                         <div className="page-title-content">
              <ul>
                <li><a href="/">Home</a></li>
                <li>Horoscope Prediction</li>
              </ul>
            </div> 
                    </div>
                </div> */}

                <section className="thankyou-section bg-light">
                    <div className="container">
                        <div className="row">
                            {/* <div className="section-head text-center mb-0 col-12">
                <h1 className="pb-2"><span>Weekly</span> Horoscope Prediction</h1>
                <h5>25 February - 3rd March</h5>
                <p>Check your Moon and Ascendant Signs</p>
              </div> */}
                            <div className="col-md-6 mx-auto">
                                <div className="service-card text-center  ">
                                    <div className="icon-wrapper mb-3 ">
                                    </div>
                                    <h3>Thank You !</h3>
                                    <p className=" ">
                                    Thank you for choosing Blessings Astrology!
Your request has been successfully received, and our expert astrologer is diligently working to provide you with a personalized and insightful response.
                                    </p>
                                    <div className="text-center w-100 mb-4">

                                        <span>

                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center">
                                        {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                                        <a href="/" className="button button-2">Go To Home</a>
                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}