import React from "react";
import "../servicepage.css";
import NavBar from "../../nav/nav";
import s2 from "../../img/service/inner/vastu-imagee.png";
import { Helmet } from "react-helmet";
export default function vastushastraypage() {
    return (
        <>
        <Helmet>
        
        <title>
          Vastu Shastra consultant | Blessings Astrology
        </title>
        <meta
          name="description"
          content="Vastu Shastra is a divine science that we have used since ancient times to plan and build our homes, institutions, workplaces, and cities. "
        />
        <meta name="keywords" content="astrology services, best astrologer in gurgaon, astrologer in gurgaon, Jyotis shastra, vastu shastra, vastu shastra consultant" />
        <link rel="canonical" href="https://www.blessingsastrology.com/vastu-shastra-service" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta property="og:title" content="Vastu Shastra consultant | Blessings Astrology" />
        <meta property="og:description" content="Vastu Shastra is a divine science that we have used since ancient times to plan and build our homes, institutions, workplaces, and cities. " />
        <meta
          property="og:url"
          content="https://www.blessingsastrology.com/vastu-shastra-service"
        />
        <meta
          property="og:image"
          content="https://www.blessingsastrology.com/static/media/vastu.1875170fb67234b6e281.png"
        />
        <meta
          name="twitter:title"
          content="Vastu Shastra consultant | Blessings Astrology"
        />
        <meta
          name="twitter:description"
          content="Vastu Shastra is a divine science that we have used since ancient times to plan and build our homes, institutions, workplaces, and cities. 
"
        />
        <meta
          name="twitter:image"
          content="https://www.blessingsastrology.com/static/media/vastu.1875170fb67234b6e281.png"
        />
      </Helmet>
            <section className="inner-section service-page">
                <NavBar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/services">Services</a></li>
                                <li>Vastu Shastra</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">

                    <div className="row">
                        <div className="section-head t-head mb-0 col-12 mb-4">
                            <h4><span>Vastu</span> Shastra</h4>
                        </div>
                        <div className="col-lg-4">
                            <img src={s2} className="img-fluid w-100 h-100" alt="Vastu Shastra services" />
                        </div>
                        <div className="col-lg-8 service-inner-card">
                            <p>
                                Vastu Shastra is a divine science that we have used since ancient times to plan and build our homes, institutions, workplaces, and cities.
                            </p>
                            <p>
                                Ancient Architects (vastukar) used to align the energy of the location so that we could build a profitable and positive structure.
                            </p>
                            <p>
                                Vastu Shastra is concerned with assessing the energy of a location, including the soil and surroundings, and then collaborating with the people who will live or work there to design the structure so that it is a positive, profitable, and happy place to be.
                            </p>
                            <p>
                                This science analyses directions and uses their characteristics to produce results using the five element theory and the elements in the universe.
                            </p>
                            <p>
                                Vastu is done from the moment the foundation is laid. It is also possible if a structure is already built and there is no need to demolish it. If the dwellers have any problems, they can be solved by correcting the disturbed energies and properly positioning vastu objects.
                            </p>
                            <p>
                                Dr. Surabhi Bhatnagar specialises in vedic vastu, 64-direction vastu, and Astro vastu for homes, businesses, and institutions. She assisted many organisations in achieving their objectives while removing roadblocks caused by incorrect vastu alignment at their workplaces or homes.
                            </p>
                            <a href="/book-an-appointment" className="button button-2">Book An Appointment</a>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}