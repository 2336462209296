import React from "react";
import "./servicepage.css";
import NavBar from "../nav/nav";
import s1 from "../img/service/1.png";
import s2 from "../img/service/2.png";
import s3 from "../img/service/3.png";
import s4 from "../img/service/4.png";
import s5 from "../img/service/pa.png";
import s6 from "../img/service/nu.png";
import s7 from "../img/service/re.png";
import corporate from "../img/corporate-training.png";
import { BsChevronDoubleRight } from "react-icons/bs";
import { Helmet } from "react-helmet";
import { Accordion, Tab, Tabs } from "react-bootstrap";

import { FaWhatsappSquare } from "react-icons/fa"
export default function servicepage() {
  return (
    <>
      <Helmet>
        <title>Best Astrology Services in India | Blessings Astrology</title>
        <meta
          name="description"
          content="Our comprehensive range of astrological services is designed to guide and empower you on your spiritual journey. "
        />
        <meta
          name="keywords"
          content="astrology services, best astrologer in gurgaon, astrologer in gurgaon, Jyotis shastra, astrological services"
        />
        <link
          rel="canonical"
          href="https://www.blessingsastrology.com/services"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta
          property="og:title"
          content="Best Astrology Services in India | Blessings Astrology"
        />
        <meta
          property="og:description"
          content="Our comprehensive range of astrological services is designed to guide and empower you on your spiritual journey. "
        />
        <meta
          property="og:url"
          content="https://www.blessingsastrology.com/services"
        />
        <meta
          property="og:image"
          content="https://www.blessingsastrology.com/static/media/4.b58daed3a0427f04f017.png"
        />
        <meta
          name="twitter:title"
          content="Best Astrology Services in India | Blessings Astrology"
        />
        <meta
          name="twitter:description"
          content="Our comprehensive range of astrological services is designed to guide and empower you on your spiritual journey. 
"
        />
        <meta
          name="twitter:image"
          content="https://www.blessingsastrology.com/static/media/4.b58daed3a0427f04f017.png"
        />
      </Helmet>
      <section className="inner-section service-page">
        <NavBar />
        <div className="page-title-area">
          <div className="container-fluid pe-md-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Our Services</li>
              </ul>
            </div>
            <div className="position-absolute text-center w-100 ">
              <a
                href="#pricedown"
                className="button scroll-down-price-btn text-white mx-md-0 ms-4"
              >
                Click Here to Check the Guide of Astrology
              </a>
              <div class="waviy my-3 mx-md-0 ms-4">
                {" "}
                <span className="servicew-whatsapp-icon-poiunter scroll-down-price-btn">
                  <a href="https://wa.me/+919821155251" target="blank" className="text-white">Click Here to Book Your Slots for the Upcoming Webinar <FaWhatsappSquare className="service-whatsapp-icon" /></a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <div className="row">
            <div className="col-12">
              <div className="section-head text-center mb-0 col-12">
                <h4 className="pb-2">
                  <span>Explore Our Astrological</span> Services
                </h4>
                <h5>Nurture Your Spiritual Journey with Our Offerings</h5>
                <p>
                  Our comprehensive range of astrological services are designed
                  to guide and empower you on your spiritual journey. With a
                  deep understanding of astrology, vastu shastra, handwriting
                  and signature analysis, tarot card reading, palmistry,
                  numerology, and reiki healing, we offer a holistic approach to
                  unravelling the mysteries of life and tapping into your true
                  potential.
                </p>
              </div>
            </div>
            <div className="col-md-4 py-md-2 px-md-3 mb-3">
              <div className="service-card">
                <div className="icon-wrapper">
                  <img src={s1} alt="Astrology Services" />
                </div>
                <h3>Astrology</h3>
                <p>
                  Astrology, also known as Jyotish, is an ancient and divine
                  knowledge that can see into our past...
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <a href="/astrology-service" className="button button-2 me-3">
                    Read More{" "}
                  </a>
                  <a href="/book-an-appointment" className="button button-2">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 py-md-2 px-md-3 mb-3">
              <div className="service-card">
                <div className="icon-wrapper">
                  <img src={s2} alt="Vastu Shastra services" />
                </div>
                <h3>Vastu Shastra</h3>
                <p>
                  Vastu Shastra is a divine science that we have used since
                  ancient times to plan and build our...
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <a href="/vastu-shastra-service" className="button button-2">
                    Read More
                  </a>
                  <a href="/book-an-appointment" className="button button-2">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 py-md-2 px-md-3 mb-3">
              <div className="service-card">
                <div className="icon-wrapper">
                  <img
                    src={s3}
                    alt="Handwriting and Signature Analysis (Astrology)"
                  />
                </div>
                <h3>Handwriting & Signature Analysis</h3>
                <p>
                  "We can judge a person by looking at his or her handwriting,"
                  as the...
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <a
                    href="/handwriting-signature-analysis"
                    className="button button-2"
                  >
                    Read More
                  </a>
                  <a href="/book-an-appointment" className="button button-2">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 py-md-2 px-md-3 mb-3">
              <div className="service-card">
                <div className="icon-wrapper">
                  <img src={s4} alt="Tarot Card Reading" />
                </div>
                <h3>Tarot</h3>
                <p>
                  Tarot card reading is an ancient technique that was popular in
                  Europe centuries ago and has now spread all over the world...
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <a href="/tarot-service" className="button button-2">
                    Read More
                  </a>
                  <a href="/book-an-appointment" className="button button-2">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 py-md-2 px-md-3 mb-3">
              <div className="service-card">
                <div className="icon-wrapper">
                  <img src={s5} alt="Palmistry Services" />
                </div>
                <h3>Palmistry</h3>
                <p>
                  Palmistry is an age old technique in which an astrologer reads
                  and analyzes the palm of the native. It is the art of reading
                  and predicting...
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <a href="/palmistry-service" className="button button-2">
                    Read More
                  </a>
                  <a href="/book-an-appointment" className="button button-2">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 py-md-2 px-md-3 mb-3">
              <div className="service-card">
                <div className="icon-wrapper">
                  <img src={s6} alt="Numerology services" />
                </div>
                <h3>Numerology</h3>
                <p>
                  Numerology is the wing of astrology where we consider numbers
                  and predict the past, present and future. Numerology helps
                  people...
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <a href="/numerology-service" className="button button-2">
                    Read More
                  </a>
                  <a href="/book-an-appointment" className="button button-2">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 py-md-2 px-md-3 mb-3">
              <div className="service-card">
                <div className="icon-wrapper">
                  <img src={s7} alt="Reiki Healing Services" />
                </div>
                <h3>Reiki Healing</h3>
                <p>
                  It is the japanese technique which makes use of hands and
                  invokes energy and blessings from the universe. It is an
                  age-old...
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <a href="/reikihealing-service" className="button button-2">
                    Read More
                  </a>
                  <a href="/book-an-appointment" className="button button-2">
                    Book Now
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-4 py-md-2 px-md-3 mb-3">
              <div className="service-card">
                <div className="icon-wrapper">
                  <img src={corporate} alt="Reiki Healing Services" />
                </div>
                <h3>Corporate Training</h3>
                <p>
                  Blessings Astrology offers unique and comprehensive corporate training sessions designed to enhance employee well-being...
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <a href="/corporate-training-service" className="button button-2">
                    Read More
                  </a>
                  <a href="/book-an-appointment" className="button button-2">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="title-container text-center">
              <h4>
                <span>Key Areas Explored in </span> Astrology Readings
              </h4>
              <div className="bottom_content px-3 text-center">
                <p>
                  Below is a detailed breakdown of our astrology session
                  offerings and their corresponding pricing in Indian Rupees
                  (INR). Feel free to select the session duration that aligns
                  with your preferences and needs.
                </p>
              </div>
            </div>
            <div class="wrapper col-12 col-lg-6 text-center" id="pricedown">
              <div class="service-price-table me-md-1 mx-2">
                <div class="table-responsive">
                  <table className="border">
                    <thead>
                      <tr>
                        <th colSpan="3" className="text-center">
                          Choose Your Astrological Focus
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Personal Life</td>
                        <td>Family</td>
                        <td>Love Life</td>
                      </tr>
                      <tr>
                        <td>Marriage</td>

                        <td>Happiness</td>
                        <td>Success</td>
                        {/* <td>Siblings</td> */}
                      </tr>
                      <tr>
                        <td>Wealth</td>
                        <td>Career</td>
                        <td>Health</td>
                      </tr>
                      <tr>
                        <td>Illness</td>

                        <td>Travel</td>
                        <td>Business</td>
                      </tr>
                      <tr>
                        {/* <td>Job Prospects</td> */}
                        {/* <td>Children</td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="wrapper col-12 col-lg-6 text-center ">
              <div class="service-price-table ms-md-1 mx-2">
                <div class="table-responsive">
                  <table className="border ">
                    <thead>
                      <tr>
                        <th colSpan="3" className="text-center">
                          {" "}
                          Guide for Astrology Services
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Premium Package</td>
                        <td className="service-price-offer">
                          <p className="service-price-original-value">
                            {" "}
                            {/* <span className="service-price-offer-cancel-value space-price-value down">
                              ₹4100
                            </span> */}
                            1-Hour Session
                          </p>
                        </td>
                        <td>
                          <a
                            href="https://blessingsastrology.com/book-a-slot"
                            target="_blank"
                          >
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Most Popular</td>
                        <td className="service-price-offer">
                          <p className="service-price-original-value">
                            {" "}
                            {/* <span className="service-price-offer-cancel-value space-price-value down">
                              ₹3100
                            </span> */}
                            40-min Session
                          </p>
                        </td>
                        <td>
                          <a
                            href="https://blessingsastrology.com/book-a-slot"
                            target="_blank"
                          >
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Standard Package</td>
                        <td className="service-price-offer">
                          <p className="service-price-original-value">
                            {" "}
                            {/* <span className="service-price-offer-cancel-value space-price-value down">
                              ₹2100
                            </span> */}
                            30-min Session                          </p>
                        </td>
                        <td>
                          <a
                            href="https://blessingsastrology.com/book-a-slot"
                            target="_blank"
                          >
                            Book Now
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Basic Package</td>
                        <td>
                          {/* <span className="service-price-offer-cancel-value space-price-value down">
                            ₹1100
                          </span> */}
                          15-min Session
                        </td>

                        <td>
                          <a
                            href="https://blessingsastrology.com/book-a-slot"
                            target="_blank"
                          >
                            Book Now
                          </a>
                        </td>
                      </tr>
                      {/* <tr>
                    
                    </tr>
                    <tr>
                      <td>1100</td>
                      <td>40 min</td>
                    </tr>
                    <tr>
                      <td>3100</td>
                      <td>30 min</td>
                    </tr>
                    <tr>
                      <td>3100</td>
                      <td>20 min</td>
                    </tr>
                    <tr>
                      <td>2100</td>
                      <td>10 min</td>
                    </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="section-head text-center mb-0 col-12">
              <h4 className="pb-2">
                <span>FAQs</span>
              </h4>
              <h5>Wondering About Astrology? Find Your Answers Here</h5>
            </div>
            <div className="col-12 faq-main">
              <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                <Tab eventKey={1} title="Astrology">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Why do I need astrology?
                      </Accordion.Header>
                      <Accordion.Body>
                        Astrology is the divine guidance where an astrologer
                        answers your questions after reading your birth chart
                        and gives solutions to your problems.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        What is a birth chart?
                      </Accordion.Header>
                      <Accordion.Body>
                        Birth chart is made after studying the position of
                        planets at the time of your birth. It needs the time ,
                        date and place of birth to be constructed.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        What if I don't have my birth details and I want
                        guidance through an astrological chart?
                      </Accordion.Header>
                      <Accordion.Body>
                        If you do not have birth details, then various other
                        methods like palmistry, face reading, tarot will help in
                        giving guidance. If you want astrological guidance
                        without sharing your birth details then Prashna Chart or
                        Horary chart helps in giving your answer.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        What is a Prashna Chart?
                      </Accordion.Header>
                      <Accordion.Body>
                        Prashna Chart is prepared keeping in mind the time of
                        question. It does not require any kind of details. It is
                        made after seeing the current position of the planets.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        Is Prashna chart accurate and how many questions it can
                        answer?
                      </Accordion.Header>
                      <Accordion.Body>
                        The Prashna chart is as accurate as any birth chart. It
                        can answer only one question at a time unlike birth
                        charts which can put light on past , present and future
                        in detail.. Every new question has a new Prashna chart.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        What is a Horary Chart ?
                      </Accordion.Header>
                      <Accordion.Body>
                        It is a chart which is made after asking you to choose
                        between some numbers. It then answers your questions. It
                        is also accurate.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        Which chart is more accurate; Horary or Prashna?
                      </Accordion.Header>
                      <Accordion.Body>
                        It only depends on the enquirer and the astrologer which
                        method to choose to give an answer.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>
                        How many questions can be answered by a birth chart?
                      </Accordion.Header>
                      <Accordion.Body>
                        It represents you , hence it is capable of answering
                        your paste , present and future.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>Do remedies work? </Accordion.Header>
                      <Accordion.Body>
                        Yes, remedies work if done with full dedication and
                        keeping faith. Remedies which are not harming anyone
                        else and done only to help yourself will definitely
                        work.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                      <Accordion.Header>
                        What kind of remedies are given by Blessings Astrology?
                      </Accordion.Header>
                      <Accordion.Body>
                        We give you simple, doable, easy, pocket friendly
                        remedies.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab>
                <Tab eventKey={2} title="Palmistry">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        How to get palm reading done?
                      </Accordion.Header>
                      <Accordion.Body>
                        You can send the clear picture of both palms front ,
                        back and sideways.{" "}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        How long does a palm reading last?
                      </Accordion.Header>
                      <Accordion.Body>
                        The lines in the palm keep on changing as time
                        progresses. It is better to reconsult after every 6
                        Months.{" "}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        How accurate is the palm reading?
                      </Accordion.Header>
                      <Accordion.Body>
                        Palm reading gives accurate answers and guides
                        correctly.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        Can I send only one hand for reading?
                      </Accordion.Header>
                      <Accordion.Body>
                        It is better to send pics of both hands for more
                        accurate reading.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab>
               
              </Tabs>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}
