import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import p1 from "../../img/products/1.png";
import p2 from "../../img/products/2.png";
import p3 from "../../img/products/3.png";
import p4 from "../../img/products/4.png";
import p5 from "../../img/products/5.png";
import p6 from "../../img/products/6.png";
import p7 from "../../img/products/7.png";
import p8 from "../../img/products/8.png";
import p9 from "../../img/products/9.png";
import p10 from "../../img/products/10.png";
import p11 from "../../img/products/17.png";
import p12 from "../../img/products/18.png";
import p13 from "../../img/products/19.png";
import p14 from "../../img/products/20.png";
import p15 from "../../img/products/21.png";
import p16 from "../../img/products/22.png";
import p17 from "../../img/products/23.png";
import p18 from "../../img/products/24.png";
import p19 from "../../img/products/25.png";
import p20 from "../../img/products/26.png";
import p21 from "../../img/products/27.png";
import p22 from "../../img/products/28.png";
import p23 from "../../img/products/29.png";
import p24 from "../../img/products/30.png";
import p25 from "../../img/products/31.png";
import p26 from "../../img/products/32.png";
import p27 from "../../img/products/33.png";


import p28 from "../../img/products/34.png";
import p29 from "../../img/products/35.png";
import p30 from "../../img/products/36.png";
// Import Swiper styles
import "swiper/css";


import "./remedies.css";

// import required modules
import { Autoplay, Pagination } from "swiper";

function remedies() {
    return (
        <>
            <Container fluid className="bg-light mt-5 py-5">
                <Container>
                    <Row>
                        <Col xs={12} className="section-head t-head mb-0">
                            <h4><span>Our</span> Products</h4>
                        </Col>

                        <Swiper
                            spaceBetween={30}
                            loop={true}
                            slidesPerGroup={3}
                            pagination={{
                                clickable: true,
                              }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 5,
                                    spaceBetween: 30,
                                },
                            }}
                            autoplay={{
                                delay: 2000,
                                pauseOnMouseEnter: true,
                                disableOnInteraction: false,
    
                            }}
                            modules={[Autoplay, Pagination]}
                            className="mySwiper remedies-swiper pt-4 pb-5"
                        >
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p1} className="img-fluid" alt="Citrine" />
                                    </div>
                                    <a href="/our-products">
                                    Citrine
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p2} alt="Amethyst bracelet" />
                                    </div>
                                    <a href="/our-products">
                                    Amethyst bracelet
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p3} className="img-fluid" alt="Clear quartz bracelet" />
                                    </div>
                                    <a href="/our-products">
                                    Clear quartz bracelet
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p4} alt="Amethyst" />
                                    </div>
                                    <a href="/our-products">
                                    Amethyst
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p5} className="img-fluid" alt="Rose quartz bracelet" />
                                    </div>
                                    <a href="/our-products">
                                    Rose quartz bracelet
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p6} alt="Golden pyrite" />
                                    </div>
                                    <a href="/our-products">
                                    Golden pyrite
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p7} alt="Aventurine Bracelet" />
                                    </div>
                                    <a href="/our-products">
                                    Aventurine Bracelet
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p8} alt="Hematite" />
                                    </div>
                                    <a href="/our-products">
                                    Hematite
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p9} alt="Hematite Bracelet" />
                                    </div>
                                    <a href="/our-products">
                                    Hematite Bracelet
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p10} alt="Aventurine" />
                                    </div>
                                    <a href="/our-products">
                                    Aventurine
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p11} alt="lava" />
                                    </div>
                                    <a href="/our-products">
                                    lava
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p12} alt="Lava bracelet   " />
                                    </div>
                                    <a href="/our-products">
                                    Lava bracelet   
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p13} alt="carnelin" />
                                    </div>
                                    <a href="/our-products">
                                    carnelin
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p14} alt="carnelin bracelet" />
                                    </div>
                                    <a href="/our-products">
                                    carnelin bracelet
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p15} alt="seven chakra bracelet" />
                                    </div>
                                    <a href="/our-products">
                                    seven chakra bracelet
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p16} alt="Sage" />
                                    </div>
                                    <a href="/our-products">
                                    Sage
                                    </a>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p17} alt="tiger eye bracelet" />
                                    </div>
                                    <a href="/our-products">
                                    tiger eye bracelet
                                    </a>
                                </a>
                            </SwiperSlide>


                            
                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p18} alt="tiger eye " />
                                    </div>
                                    <a href="/our-products">
                                    tiger eye 
                                    </a>
                                </a>
                            </SwiperSlide>


                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p19} alt="selenite" />
                                    </div>
                                    <a href="/our-products">
                                    selenite
                                    </a>
                                </a>
                            </SwiperSlide>


                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p20} alt="moon stone" />
                                    </div>
                                    <a href="/our-products">
                                    moon stone
                                    </a>
                                </a>
                            </SwiperSlide>


                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p21} alt="moon stone bracelet" />
                                    </div>
                                    <a href="/our-products">
                                    moon stone bracelet
                                    </a>
                                </a>
                            </SwiperSlide>


                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p22} alt="Jasper stone" />
                                    </div>
                                    <a href="/our-products">
                                    Jasper stone
                                    </a>
                                </a>
                            </SwiperSlide>


                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p23} alt="Jasper bracelet" />
                                    </div>
                                    <a href="/our-products">
                                    Jasper bracelet
                                    </a>
                                </a>
                            </SwiperSlide>


                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p24} alt="amazonite" />
                                    </div>
                                    <a href="/our-products">
                                    amazonite
                                    </a>
                                </a>
                            </SwiperSlide>


                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p25} alt="amazonite bracelet" />
                                    </div>
                                    <a href="/our-products">
                                    amazonite bracelet
                                    </a>
                                </a>
                            </SwiperSlide>


                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p26} alt="sodalite" />
                                    </div>
                                    <a href="/our-products">
                                    sodalite
                                    </a>
                                </a>
                            </SwiperSlide>


                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p27} alt="sodalite bracelet" />
                                    </div>
                                    <a href="/our-products">
                                    sodalite bracelet
                                    </a>
                                </a>
                            </SwiperSlide>

                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p28} alt="Apatite" />
                                    </div>
                                    <a href="/our-products">
                                    Apatite
                                    </a>
                                </a>
                            </SwiperSlide>

                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p29} alt="Apatite bracelet" />
                                    </div>
                                    <a href="/our-products">
                                    Apatite bracelet
                                    </a>
                                </a>
                            </SwiperSlide>

                            <SwiperSlide>
                                <a href="/our-products" className="r-card">
                                    <div className="r-img">
                                        <img src={p30} alt="smoky quartz" />
                                    </div>
                                    <a href="/our-products">
                                    smoky quartz
                                    </a>
                                </a>
                            </SwiperSlide>

                        </Swiper>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

export default remedies;