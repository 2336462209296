// import logo from './logo.svg';
import "./App.css";
import TopBtn from "./backtotop/top";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./home/home";
import NavBar from "./nav/nav";
import Footer from "./footer/footer";
import Services from "./servicepage/servicepage";
import Astrology from "./servicepage/serviceinner/astrology";
import Vastushastra from "./servicepage/serviceinner/vastushastra";
import Handwriting from "./servicepage/serviceinner/handwriting";
import Tarot from "./servicepage/serviceinner/tarot";
import Palmistry from "./servicepage/serviceinner/palmistry";
import Numerology from "./servicepage/serviceinner/numerology";
import Reikihealing from "./servicepage/serviceinner/reikihealing";
import About from "./aboutpage/aboutpage";
import Products from "./productspage/productspage";
import Appointment from "./appointmentpage/appointmentpage";
import Gallerypage from "./photogallery/gallerypage";
import consultform from "./home/ConsultForm/ConsultForm";
import Paymentpage from "./paymentpage/PaymentComponet";
import Horoscope from "./horoscopepage/horoscopepage";
import Blogpage from "./blogpage/blog";
import Ariespage from "./ariespage/aries";
import Geminipage from "./geminipage/gemini";
import Cancerpage from "./cancerpage/cancer";
import Leopage from "./leopage/leo";
import PrivacyPolicypage from "./Privacy/PrivacyPolicy"
import TaurusPage from "./tauruspage/taurus";
import MediaPage from "./mediapage/media";
import thankyoupage from "./thankyoupage/thankyou";
import leopage from "./leopage/leo";
import Virgopage from "./virgopage/virgo";
import BooksPage from "./bookspage/books";
import Corporatetraining from "./servicepage/serviceinner/corporatetraining";


function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <>
      <BrowserRouter>
        {/* {window.location.pathname.split("/")[1] != "book-a-slot" ? <TopBtn /> : <></>} */}
        <TopBtn />

        <Switch>

          <Route exact path="/" component={Home} />
          <Route exact path="/about-us" component={About} />
          <Route exact path="/horoscope-prediction" component={Horoscope} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/astrology-service" component={Astrology} />
          <Route exact path="/vastu-shastra-service" component={Vastushastra} />
          <Route exact path="/blogs" component={Blogpage} />
          <Route exact path="/aries" component={Ariespage} />
          <Route exact path="/gemini" component={Geminipage} />
          <Route exact path="/taurus" component={TaurusPage} />
          <Route exact path="/cancer" component={Cancerpage} />
          <Route exact path="/leo" component={leopage} />
          <Route exact path="/virgo" component={Virgopage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicypage} />
          <Route exact path="/media" component={MediaPage} />
          <Route exact path="/our-books" component={BooksPage} />
          <Route exact path="/thankyou" component={thankyoupage} />






          <Route
            exact
            path="/handwriting-signature-analysis"
            component={Handwriting}
          />
          <Route exact path="/tarot-service" component={Tarot} />
          <Route exact path="/palmistry-service" component={Palmistry} />
          <Route exact path="/numerology-service" component={Numerology} />
          <Route exact path="/reikihealing-service" component={Reikihealing} />
          <Route exact path="/corporate-training-service" component={Corporatetraining} />
          <Route exact path="/our-products" component={Products} />
          <Route exact path="/gallery" component={Gallerypage} />
          <Route exact path="/book-an-appointment" component={Appointment} />
          <Route exact path="/consultform" component={consultform} />
          <Route exact path="/book-a-slot" component={Paymentpage} />




        </Switch>
        {/* <Footer /> */}
        {window.location.pathname.split("/thankyou")[1] != "" ? <Footer /> : <></>}

      </BrowserRouter>
    </>
  );
}

export default App;
