import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
// Import Swiper styles
import "swiper/css";


import "./testimonial.css";

// import required modules
import { Autoplay, Pagination } from "swiper";

function Testimonial() {
    return (
        <>
            <Container className="mt-5">
                <Row>
                    <Col xs={12} className="section-head t-head mb-0">
                        <h4><span>What Do Our Clients Say</span> About Us?</h4>
                    </Col>

                    <Swiper
                        spaceBetween={30}
                        loop={true}
                        pagination={{
                            clickable: true,
                          }}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                        }}
                        autoplay={{
                            delay: 5000,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: false,

                        }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper testimonial-swiper pt-5"
                    >
                        <SwiperSlide>
                            <div className="single-testimonial">
                                <div className="round-1 round"></div>
                                <div className="round-2 round"></div>
                                <p>I never believed in astrology.But if u have a friend who is an astrologer, u r always keen to ask for her guidance whenever u feel the need .I consulted my friend Dr Surabhi Bhatnagar and was truly bowled over by her analysis of my chart . She is highly meticulous in her work , she helps u understand about planets and its effects in your life. The best part about Surabhi is her reading which is so honest and truthful. She handles predictions very gracefully.I highly recommend her as she has been into spirituality for about 15 years now.
                                    Surabhi is a must go to person, when u need a perfect guidance and direction in life.
                                <br />
                                    I m sure u will never be disappointed and come feeling sorted and balanced in life .

                                </p>
                                <div className="client-info">
                                    <div className="client-details">
                                        <h6>Navita Anand</h6>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        
                        
                        <SwiperSlide>
                            <div className="single-testimonial">
                                <div className="round-1 round"></div>
                                <div className="round-2 round"></div>
                                <p>Hello surbhi ji mujhe aapne meri life se related jo bhi baate batain wo bahut accurate lagi my experience with you is good but Mera upay abhi nhi ho paya
                                    Surabhi Bhatnagar Blessings: M waise bhi aapse soch rhi thi baat karne k liye Time lungi
                                    Surabhi Bhatnagar Blessings: One thing I also want to share about you i feel very positive and comfortable with you once again thankyou for guiding
                                </p>
                                <div className="client-info">
                                    <div className="client-details">
                                        <h6>Seema Gupta</h6>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="single-testimonial">
                                <div className="round-1 round"></div>
                                <div className="round-2 round"></div>
                                <p>I acknowledge that i am little late in posting this but would like to share feedback for my experience with DR. Surabhi on astrology prediction and remedies. I approached Srabhi for self and for my sister as well and her predictions are so accurate and remedies are very very simple that any one can follow it along with daily responsibilities.
                                <br />
                                    Most importantly very very effective. Would like to Thank her for guiding us. Personally also she is such a positive person that u feel so comfortable with her.
                                </p>
                                <div className="client-info">
                                    <div className="client-details">
                                        <h6>Kiran Chaudhary
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="single-testimonial">
                                <div className="round-1 round"></div>
                                <div className="round-2 round"></div>
                                <p> Dr. Surbhi is very positive n encouraging person. Her simple methods for problem solving are very good. Her predictions were correct.
                                    Surabhi Bhatnagar Blessings: After using those methods I feel very relaxed n peaceful. May God shower blessings on you. Thank you.
                                </p>
                                <div className="client-info">
                                    <div className="client-details">
                                        <h6>Pallavi</h6>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="single-testimonial">
                                <div className="round-1 round"></div>
                                <div className="round-2 round"></div>
                                <p>
                                    I had a session with Surabhiji regarding the personal loss which I was going through. It was satisfying session and I could easily share my emotions with Surabhiji. Thanks to her.

                                </p>

                                <div className="client-info">
                                    <div className="client-details">
                                        <h6>Deep Srivastav
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="single-testimonial">
                                <div className="round-1 round"></div>
                                <div className="round-2 round"></div>
                                <p>Surabhi is the most knowledgeable astrologer I have ever met. She is very considerate and understands every situation in depth then tries to give the best solution . Her Reiki sessions are really helpful and we have seen significant improvement in mother's health. She is not just an astrologer but also an angel for us because whenever we are in a tough situation she is a great healer. I really recommend her for all her astrological services 🙏.</p>
                                <div className="client-info">
                                    <div className="client-details">
                                        <h6>Rakshica Srivastava</h6>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="single-testimonial">
                                <div className="round-1 round"></div>
                                <div className="round-2 round"></div>
                                <p>
                                    Surabhi ji hi
                                <br />
                                    I just wanted to thank you for being the best guru and a constant support. Thanks for teaching each topic patiently and vividly. I will now and forever cherish you as my “Guru”. Please do visit us when you come to Australia. We will be very happy to host. I am sure our teaching journey continues further with each passing year.I will also take guidance regularly and vastu details for our new home before we set it up. I wish you the best and may you succeed in all your future endeavours. Take care!!

                                </p>

                                <div className="client-info">
                                    <div className="client-details">
                                        <h6>Pooja
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="single-testimonial">
                                <div className="round-1 round"></div>
                                <div className="round-2 round"></div>
                                <p>
                                    Happy New Year & Prosperous 2023 Dear Surabhi & Your family members, stay blessed.
                                <br />
                                    I forgot to update you about My Cataract Operation which was successfully with your blessings and guidance done on 22-Dec-2022.
                                    Free Lens Given to me by Hospital not much expense done.

                                </p>

                                <div className="client-info">
                                    <div className="client-details">
                                        <h6>
                                            Alok Ratan Bhatnagar
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </Row>
            </Container>
        </>
    );
}

export default Testimonial;