import React from "react";
import NavBar from "../nav/nav";
import "./appointmentpage.css";
import { FiMail } from "react-icons/fi";
import { BiPhoneCall } from "react-icons/bi";
import { Helmet } from "react-helmet";
export default function appointmentpage() {
  return (
    <>
      <Helmet>
        <title>Book An Appointment | Blessing Astrology</title>
        <meta
          name="description"
          content="Schedule Your Blessings Astrology Session Now! Get Remedial and Divine Solutions for Your Happy Life"
        />
        <meta
          name="keywords"
          content="astrology services, best astrologer in gurgaon, astrologer in gurgaon, Jyotis shastra, "
        />
        <link
          rel="canonical"
          href="https://www.blessingsastrology.com/book-an-appointmentx"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta
          property="og:title"
          content="Book An Appointment | Blessing Astrology"
        />
        <meta
          property="og:description"
          content="Schedule Your Blessings Astrology Session Now! Get Remedial and Divine Solutions for Your Happy Life"
        />
        <meta
          property="og:url"
          content="https://www.blessingsastrology.com/book-an-appointmentx"
        />
        <meta
          property="og:image"
          content="https://www.blessingsastrology.com/static/media/app.8ca2957d6820b00dfe86.png"
        />
        <meta
          name="twitter:title"
          content="Book An Appointment | Blessing Astrology"
        />
        <meta
          name="twitter:description"
          content="Schedule Your Blessings Astrology Session Now! Get Remedial and Divine Solutions for Your Happy Life
"
        />
        <meta
          name="twitter:image"
          content="https://www.blessingsastrology.com/static/media/app.8ca2957d6820b00dfe86.png"
        />
      </Helmet>
      <section className="inner-section appointment-page">
        <NavBar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Book An Appointment</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="section-head text-center col-12 t-head mb-4">
              <h4>
                <span>Book An</span> Appointment
              </h4>
              <h5>The Divine Guidance Awaits!</h5>
              <p>
                Schedule Your Blessings Astrology Session Now! Get Remedial and
                Divine Solutions for Your Happy Life
              </p>
            </div>
            <div className="col-12 mb-5 contact-form">
              <iframe src="https://globaltrendz.info/blessingenquiry/1044/enquiry"></iframe>
            </div>
            <div className="col-md-6 mb-4 mb-md-0">
              <div className="service-card contact-card px-3 px-md-0">
                <h3>Contact Info</h3>
                <a href="mailto:surabhibhatnagar@blessingsastrology.com">
                  <FiMail /> surabhibhatnagar@<br className="d-block d-md-none" />blessingsastrology.com
                </a>
                {/* <a href=""><FiMail /> asd@gmail.com</a> */}
                <a href="tel:+919821155251">
                  <BiPhoneCall /> +91 98211 55251
                </a>
              </div>
            </div>
            {/* <div className="col-lg-4">
                            <div className="service-card contact-card">
                                <h3>Address</h3>
                                <a href=""> Delhi, INDIA.</a>
                            </div>
                        </div> */}
            <div className="col-md-6">
              <div className="service-card contact-card">
                <h3>Timing</h3>
                <p>Monday to Friday: 10:00 am to 5:30 pm</p>
                <p>Saturday and Sunday: 12:00 pm to 5:00 pm</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
