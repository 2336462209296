import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import abt from "../img/surabhi.png";
import NavBar from "../nav/nav";
import "./productspage.css";
import p1 from "../img/products/1.png";
import p2 from "../img/products/2.png";
import p3 from "../img/products/3.png";
import p4 from "../img/products/4.png";
import p5 from "../img/products/5.png";
import p6 from "../img/products/6.png";
import p7 from "../img/products/7.png";
import p8 from "../img/products/8.png";
import p9 from "../img/products/9.png";
import p10 from "../img/products/10.png";
import p11 from "../img/products/17.png";
import p12 from "../img/products/18.png";
import p13 from "../img/products/19.png";
import p14 from "../img/products/20.png";
import p15 from "../img/products/21.png";
import p16 from "../img/products/22.png";
import p17 from "../img/products/23.png";
import p18 from "../img/products/24.png";
import p19 from "../img/products/25.png";
import p20 from "../img/products/26.png";
import p21 from "../img/products/27.png";
import p22 from "../img/products/28.png";
import p23 from "../img/products/29.png";
import p24 from "../img/products/30.png";
import p25 from "../img/products/31.png";
import p26 from "../img/products/32.png";
import p27 from "../img/products/33.png";
import p28 from "../img/products/34.png";
import p29 from "../img/products/35.png";
import p30 from "../img/products/36.png";
import p31 from "../img/products/37.png";
import p32 from "../img/products/38.png";
import p33 from "../img/products/39.png";
import p34 from "../img/products/40.png";
import p35 from "../img/products/41.png";
import p36 from "../img/products/42.png";
import p37 from "../img/products/43.png";
import p38 from "../img/products/44.png";
import p39 from "../img/products/45.png";
import p40 from "../img/products/46.png";
import p41 from "../img/products/47.png";
import p42 from "../img/products/48.png";
import p43 from "../img/products/49.png";
import p44 from "../img/products/50.png";
import p45 from "../img/products/51.png";
import p46 from "../img/products/52.png";
import p47 from "../img/products/53.png";
import p48 from "../img/products/54.png";
import p49 from "../img/products/55.png";
import { Helmet } from "react-helmet";
export default function productspage() {
    return (
        <>
         <Helmet>
        
        <title>
        Blessings Astrology'S Products | Divine Solutions For a Happy Life
        </title>
        <meta
          name="description"
          content="Our curated collection features captivating gems such as citrine, known for abundance and manifestation, and bracelets infused with astrological."
        />
        <meta name="keywords" content="astrology services, best astrologer in gurgaon, astrologer in gurgaon, Jyotis shastra, " />
        <link rel="canonical" href="https://www.blessingsastrology.com/our-products" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta property="og:title" content="Blessings Astrology'S Products | Divine Solutions For a Happy Life" />
        <meta property="og:description" content="Our curated collection features captivating gems such as citrine, known for abundance and manifestation, and bracelets infused with astrological." />
        <meta
          property="og:url"
          content="https://www.blessingsastrology.com/our-products"
        />
        <meta
          property="og:image"
          content="https://www.blessingsastrology.com/static/media/4.008112a51abc0ff20abf.png"
        />
        <meta
          name="twitter:title"
          content="Blessings Astrology'S Products | Divine Solutions For a Happy Life"
        />
        <meta
          name="twitter:description"
          content="Our curated collection features captivating gems such as citrine, known for abundance and manifestation, and bracelets infused with astrological.
"
        />
        <meta
          name="twitter:image"
          content="https://www.blessingsastrology.com/static/media/4.008112a51abc0ff20abf.png"
        />
      </Helmet>
            <section className="inner-section products-page">
                <NavBar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Our Products</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container py-5">
                    <div className="row">
                        <div className="section-head col-12 text-center t-head mb-4">
                            <h4><span>Our</span> Products</h4>
                            <h5>Explore Our Collection of Mystical Artifacts</h5>
                            <p>
                            Our curated collection features captivating gems such as citrine, known for abundance and manifestation, as well as bracelets infused with astrological significance to enhance your unique qualities. Explore our wide range of astrological items, each carrying its own distinctive energy and purpose.
                            </p>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p1} className="img-fluid" alt="Blessing Products" />
                                </div>
                                <div>
                                    Citrine
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p2} alt="Amethyst bracelet" />
                                </div>
                                <div>
                                    Amethyst bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p3} className="img-fluid" alt="Clear quartz bracelet" />
                                </div>
                                <div>
                                    Clear quartz bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p4} alt="Amethyst" />
                                </div>
                                <div>
                                    Amethyst
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p5} className="img-fluid" alt="Rose quartz bracelet" />
                                </div>
                                <div>
                                    Rose quartz bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p6} alt="Golden pyrite" />
                                </div>
                                <div>
                                    Golden pyrite
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p7} alt="Aventurine Bracelet" />
                                </div>
                                <div>
                                    Aventurine Bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p8} alt="Hematite" />
                                </div>
                                <div>
                                    Hematite
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p9} alt="Hematite Bracelet" />
                                </div>
                                <div>
                                    Hematite Bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p10} alt="Aventurine" />
                                </div>
                                <div>
                                    Aventurine
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p11} alt="lava" />
                                </div>
                                <div>
                                    lava
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p12} alt="Lava bracelet" />
                                </div>
                                <div>
                                    Lava bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p13} alt="carnelin" />
                                </div>
                                <div>
                                    carnelin
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p14} alt="carnelin bracelet" />
                                </div>
                                <div>
                                    carnelin bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p15} alt="seven chakra bracelet" />
                                </div>
                                <div>
                                    seven chakra bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p16} alt="Sage" />
                                </div>
                                <div>
                                    Sage
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p17} alt="tiger eye bracelet" />
                                </div>
                                <div>
                                    tiger eye bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p18} alt="tiger eye" />
                                </div>
                                <div>
                                    tiger eye 
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p19} alt="selenite" />
                                </div>
                                <div>
                                selenite
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p20} alt="moon stone" />
                                </div>
                                <div>
                                moon stone
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p21} alt="moon stone bracelet" />
                                </div>
                                <div>
                                moon stone bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p22} alt="Jasper stone" />
                                </div>
                                <div>
                                Jasper stone
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p23} alt="Jasper bracelet" />
                                </div>
                                <div>
                                Jasper bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p24} alt="amazonite" />
                                </div>
                                <div>
                                amazonite
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p25} alt="amazonite bracelet" />
                                </div>
                                <div>
                                amazonite bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p26} alt="sodalite" />
                                </div>
                                <div>
                                sodalite
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p27} alt="sodalite bracelet" />
                                </div>
                                <div>
                                sodalite bracelet
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p28} alt="Apatite" />
                                </div>
                                <div>
                                Apatite
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p29} alt="Apatite bracelet" />
                                </div>
                                <div>
                                Apatite bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p30} alt="smoky quartz" />
                                </div>
                                <div>
                                smoky quartz
                                </div>
                            </div>
                        </div>
                       
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p31} alt="smoky quartz bracelet" />
                                </div>
                                <div>
                                smoky quartz bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p32} alt="red garnet" />
                                </div>
                                <div>
                                red garnet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p33} alt="red garnet bracelet" />
                                </div>
                                <div>
                                red garnet bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p34} alt="green jade" />
                                </div>
                                <div>
                                green jade
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p35} alt="green jade bracelet" />
                                </div>
                                <div>
                                green jade bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p36} alt="malachite bracelet" />
                                </div>
                                <div>
                                malachite bracelet
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p37} alt="malachite" />
                                </div>
                                <div>
                                malachite
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p38} alt="bloodstone" />
                                </div>
                                <div>
                                bloodstone
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p39} alt="bloodstone bracelet" />
                                </div>
                                <div>
                                bloodstone bracelet
                                </div>
                            </div>
                        </div>
                       
                        
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p40} alt="blude Apatite" />
                                </div>
                                <div>
                                blude Apatite
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p41} alt="blude Apatite bracelet" />
                                </div>
                                <div>
                                blude Apatite bracelet
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p42} alt="flourite" />
                                </div>
                                <div>
                                flourite
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p43} alt="flourite bracelet" />
                                </div>
                                <div>
                                flourite bracelet
                                </div>
                            </div>
                        </div>

                       

                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p45} alt="crystal quartz pencil" />
                                </div>
                                <div>
                                crystal quartz pencil
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p46} alt="Rose quartz pencil" />
                                </div>
                                <div>
                                Rose quartz pencil
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p47} alt="crystal quartz tree" />
                                </div>
                                <div>
                                crystal quartz tree
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p48} alt="Amethyst" />
                                </div>
                                <div>
                                Amethyst
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p49} alt="seven chakra tree" />
                                </div>
                                <div>
                                seven chakra tree
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="r-card">
                                <div className="r-img">
                                    <img src={p44} alt="zade money switchword & zebu symbol" />
                                </div>
                                <div>
                                zade money switchword & zebu symbol
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    );
}