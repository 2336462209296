import React from "react";
import "../servicepage.css";
import NavBar from "../../nav/nav";
import s1 from "../../img/service/1.png";
import s2 from "../../img/service/2.png";
import s3 from "../../img/service/3.png";
import s4 from "../../img/service/inner/num.png";
import { Helmet } from "react-helmet";
export default function numerologypage() {
    return (
        <>
         <Helmet>
        
        <title>
          Best Numerologist in India | Numerology Predictions | Blessings Astrology
        </title>
        <meta
          name="description"
          content="Numerology is the wing of astrology where we consider numbers and predict the past, present, and future. Numerology helps people who don't know their exact time of birth or place."
        />
        <meta name="keywords" content="astrology services, best astrologer in gurgaon, astrologer in gurgaon, Jyotis shastra, numerology Predictions, best numerologist in india" />
        <link rel="canonical" href="https://www.blessingsastrology.com/numerology-service" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta property="og:title" content="Best Numerologist in India | Numerology Predictions | Blessings Astrology" />
        <meta property="og:description" content="Numerology is the wing of astrology where we consider numbers and predict the past, present, and future. Numerology helps people who don't know their exact time of birth or place." />
        <meta
          property="og:url"
          content="https://www.blessingsastrology.com/numerology-service"
        />
        <meta
          property="og:image"
          content="https://www.blessingsastrology.com/static/media/num.3ae731eddab6d1884860.png"
        />
        <meta
          name="twitter:title"
          content="Best Numerologist in India | Numerology Predictions | Blessings Astrology"
        />
        <meta
          name="twitter:description"
          content="Numerology is the wing of astrology where we consider numbers and predict the past, present, and future. Numerology helps people who don't know their exact time of birth or place.
"
        />
        <meta
          name="twitter:image"
          content="https://www.blessingsastrology.com/static/media/num.3ae731eddab6d1884860.png"
        />
      </Helmet>
            <section className="inner-section service-page">
                <NavBar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/services">Services</a></li>
                                <li>Numerology</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">

                    <div className="row">
                        <div className="section-head t-head mb-0 col-12 mb-4">
                            <h4><span>Numerology</span></h4>
                        </div>
                        <div className="col-lg-4">
                            <img src={s4} className="img-fluid w-100 h-100" alt="Numerology services" />
                        </div>
                        <div className="col-lg-8 service-inner-card">
                            <p>
                            Numerology is the wing of astrology where we consider numbers and predict the past, present and future. Numerology helps people who dont know their exact time of birth or place. Infact if we just know our date of birth then numerology can predict or us accurately. Numerology help when we want to know many questions like, lucky number, color, house number, Number of the car, interview date, compatibility of partners. It can help if a sudden question is asked by a quadrant and the astrologer is only equipped with a pen and a paper. It helps when we want to know the predictions related to  your  personal day, month, year. It helps when  we want predictions related to guidance like what kind of personality a native is. 
                            </p>
                            <p>
                            Dr. Surabhi Bhatnagar uses her expertise in Numerology  to guide, assist, and solve problems, as well as suggest corrective actions.
                            </p>
                            <a href="/book-an-appointment" className="button button-2">Book An Appointment</a>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    );
}