import React from "react";
import NavBar from "../nav/nav";
import { Helmet, HelmetProvider } from 'react-helmet-async';


import "./PrivacyPolicy.css";


export default function PrivacyPolicypage() {
    return (
        <>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>
                            Blessings Astrology | Privacy policy
                        </title>
                        <meta
                            name="description"
                            content="Get all the information you need about Privacy Policy. Blessings Astrology takes your privacy very seriously. This (“Privacy Policy”) is intended to inform you about the information that is collected from you when you visit website and applications."
                        />
                        <meta name="keywords" content="astrology services, best astrologer in gurgaon, astrologer in gurgaon, Jyotis shastra, Privacy Policy" />
                        <link rel="canonical" href="https://blessingsastrology.com/privacy-policy" />
                        <meta property="og:locale" content="en_US" />
                        <meta property="og:site_name" content="Blessings Astrology" />
                        <meta property="og:title" content=" Blessings Astrology | Privacy policy" />
                        <meta property="og:description" content="Get all the information you need about Privacy Policy. Blessings Astrology takes your privacy very seriously. This (“Privacy Policy”) is intended to inform you about the information that is collected from you when you visit website and applications." />
                        <meta
                            property="og:url"
                            content="https://blessingsastrology.com/privacy-policyOG"
                        />
                        <meta
                            property="og:image"
                            content=" https://blessingsastrology.com/static/media/app.1ef3a7123b825bd66135.png"
                        />
                        <meta
                            name="twitter:title"
                            content=" Blessings Astrology | Privacy policy"
                        />
                        <meta
                            name="twitter:description"
                            content="Get all the information you need about Privacy Policy. Blessings Astrology takes your privacy very seriously. This (“Privacy Policy”) is intended to inform you about the information that is collected from you when you visit website and applications."
                        />
                    </Helmet>
                </div>
            </HelmetProvider>

            <section className="bg-white py-5 mb-4">
                <NavBar />
            </section>
            <section className="blogsection">
                <div className="container">
                    <div className="row">
                        <div className="section-head  mb-0 col-12 mt-lg-5 mt-5">
                            <h1 className="pb-2 "><span className="px-2">Privacy </span>Policy </h1>
                        </div>
                        <div className="col-12">

                            {/* <img src={ariesblog} className="w-100 ariesimage" alt="Astrology Services"></img> */}
                            <div className="card-body ">
                                <h4 className="card-title mt-3">

                                </h4>

                                <p className="card-text text-dark">At Blessings Astrology, we are committed to protecting the privacy and confidentiality of our user's personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our website and services, including but not limited to astrological services, numerology, tarot card readings, palmistry, reiki healing, and all other related services. Additionally, this policy covers payment-related policies and session booking procedures.

                                </p>
                                {/* <p className="text-dark">People belonging to this are in  the first sign and therefore the most ~raw~ with their needs, demands and emotions.   They can  be  needy. They command your total attention and loyality. They get bored very fast.
                                </p>
                                <p className="text-dark">Aries have a very warm and passionate nature. Mars is the ruler and it  makes them highly energetic and  competitive. They have a big presence, they can make any  gathering lively with their presence. And, mostly, they're a blessing to be around because they genuinely care about other people. Just don’t get into a competition with them, we will be at a loss or at least drained up due to their high energy moves and never die spirit.
                                </p>
                                <p className="text-dark">They like to  win, attention seeking and being on top.
                                </p>
                                <img src={ariesblog} className="  ariesimage img-fluid w-100" alt="Astrology Services"></img> */}

                                {/* <ul className="bulletpoints mt-4">
                                    <li>
                                        <strong>They are courageous :- </strong>  They act first and think later, and have little to no personal anxiety about their safety or ability to do dangerous things. They are not required to be persuaded to take up jobs or acts that need bravery. They are naturally brave which is reflected in their everyday behavior also.


                                    </li>
                                    <li><strong>Competitive :- </strong> They take even a small quiz with friends or siblings as a full on competition. They take it up as a challenge and it immediately becomes a battle for them.  Underneath this hard-wired competitive streak lies a really vulnerable insecurity. Deep inside, all Aries wonder if they’re really, truly good enough.
                                    </li>
                                    <li><strong>Honest :- </strong> The most guileless, innocent and trusting of the signs - maybe because they are the zodiac’s baby i.e. the first sign. Ask them anything and they’ll tell you what they are thinking. But they do not possess any  malice, snideness or bad intentions in their outspokenness. They just wear their heart on their sleeve.
                                    </li>
                                    <li><strong>Generous :- </strong>Aries are all giving, warm and generous people. They love  to make others smile and be happy. But they can see that as a competition too and in an instant  they’re 
                                    showing to explain  their well-meaning  advice which is generally uninvited.
                                    </li>
                                    <li><strong>Energetic and driven :- </strong> Aries would happily bounce and run around from dawn till  dusk, as they have  never-ending energy levels. All Aries want to succeed, get to the top, to ‘win’  and hence, they’re usually all  ambitious and  go-getters. They can be nagging and this increases stress and pressure which is all self-inflicted. Aries needs to learn to chill out and be nicer to themselves sometimes.
                                    </li>
                                </ul> */}
                                <p className="text-dark"><strong>Information Collection:</strong> </p>
                                <p className=" text-dark">We may collect personal information from you when you voluntarily provide it to us, such as when you sign up for an account, request a service, make a payment, or contact us with inquiries. This information may include your name, email address, birthdate, gender, billing information, and other relevant details.

                                </p>
                                <p className="text-dark"><strong>Use of Information:</strong> </p>
                                <p className="text-dark">We use the information we collect to provide and improve our services, personalize your experience, communicate with you, process payments, and fulfill your requests. This may include providing astrological readings, numerology consultations, tarot card readings, palmistry sessions, reiki healing, and other related services. We may also use your information to send you promotional materials or updates about our services, but you can opt-out of receiving these communications at any time.</p>
                                <p className="text-dark"><strong>Data Security:
                                </strong></p>

                                {/* <ul>
                                    <li>
                                        They have big, genuine smiles which light up their face when they see people they love and like.


                                    </li>
                                    <li>They are adventurous, passionate and spontaneous, and being around them means adventures are likely to happen.
                                    </li>
                                    <li>They stand out from the crowd and get noticed the moment they enter a room.

                                    </li>
                                </ul> */}
                                <p className="text-dark">We take reasonable measures to protect the security of your personal information and prevent unauthorized access, disclosure, alteration, or destruction. We use industry-standard encryption and security protocols to safeguard your data, and we regularly review and update our security practices to ensure compliance with best practices and applicable regulations.

                                </p>
                                <p className="text-dark"><strong>Payment Processing:</strong> </p>
                                <p className="text-dark">When you make a payment for our services, we may collect and process payment-related information, such as transaction screenshot, reference/ transaction other payment details. We use this information solely for the purpose of booking the slots for you. We do not store or retain your payment information after the transaction is complete.

                                </p>
                                <p className="text-dark"><strong>Session Booking:

                                </strong></p>
                                <p className="text-dark">When you book a session with us, we may collect information about your preferences, availability, and session requirements. This information is used to schedule and confirm your appointment and to provide you with the best possible service. We may also use your contact information to communicate with you about your session and to send you appointment reminders.


                                </p>


                                <p className="text-dark"><strong>Disclosure of Information:

                                </strong></p>
                                <p className="text-dark">We may disclose your personal information if required to do so by law or in response to valid legal requests, such as court orders or subpoenas. We may also disclose your information to third-party service providers who assist us in delivering our services, processing payments, or managing our website, but only to the extent necessary to perform these tasks on our behalf.


                                </p>

                                <p className="text-dark"><strong>Data Retention:


                                </strong></p>
                                <p className="text-dark">We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. When we no longer need to retain your information, we will securely delete or anonymize it to prevent unauthorized access or use.


                                </p>

                                <p className="text-dark"><strong>Children's Privacy:


                                </strong></p>
                                <p className="text-dark">Our services are not intended for children under the age of 18, and we do not knowingly collect personal information from individuals under this age. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us immediately to have it removed.



                                </p>

                                <p className="text-dark"><strong>Changes to This Policy:



                                </strong></p>

                                <p className="text-dark">We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes to this policy by posting a notice on our website or by sending you an email notification. By continuing to use our services after such changes are made, you acknowledge and agree to the updated policy.




                                </p>

                                <p className="text-dark"><strong>Contact Us:


                                </strong></p>
                                <p className="text-dark">If you have any questions or concerns about this Privacy Policy or our practices regarding your personal information, please contact us at <a className="fs-6" href="mailto:surabhibhatnagar@blessingsastrology.com"><strong>surabhibhatnagar@blessingsastrology.com </strong></a> or call us at  <a className="fs-6" href="tel:+91 9821155251"><strong>+91 98211 55251</strong></a>




                                </p>
                                <p className="text-dark">By using our website and services, you consent to the collection, use, and disclosure of information as described in this Privacy Policy.
                                </p>


                            </div>


                        </div>
                    </div>
                </div>
            </section>


        </>

    );
}
