import React from "react";
import "../servicepage.css";
import NavBar from "../../nav/nav";
import s1 from "../../img/service/1.png";
import s2 from "../../img/service/2.png";
import s3 from "../../img/service/inner/ri.png";
import s4 from "../../img/service/4.png";
import { Helmet } from "react-helmet";
export default function reikihealingpage() {
    return (
        <>
          <Helmet>
        
        <title>
          Best Reiki Healing in India | Reiki Healer | Blessings Astrology
        </title>
        <meta
          name="description"
          content="It is the Japanese technique that makes use of hands and invokes energy and blessings from the universe. which is very powerful and is used for stress reduction and relaxation"
        />
        <meta name="keywords" content="astrology services, best astrologer in gurgaon, astrologer in gurgaon, Jyotis shastra, Reiki Healing" />
        <link rel="canonical" href="https://www.blessingsastrology.com/reikihealing-service" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta property="og:title" content="Best Reiki Healing in India | Reiki Healer | Blessings Astrology" />
        <meta property="og:description" content="It is the Japanese technique that makes use of hands and invokes energy and blessings from the universe. which is very powerful and is used for stress reduction and relaxation" />
        <meta
          property="og:url"
          content="https://www.blessingsastrology.com/reikihealing-service"
        />
        <meta
          property="og:image"
          content="https://www.blessingsastrology.com/static/media/ri.61ebfccf262f12aa82ee.png"
        />
        <meta
          name="twitter:title"
          content="Best Reiki Healing in India | Reiki Healer | Blessings Astrology"
        />
        <meta
          name="twitter:description"
          content="It is the Japanese technique that makes use of hands and invokes energy and blessings from the universe. which is very powerful and is used for stress reduction and relaxation
"
        />
        <meta
          name="twitter:image"
          content="https://www.blessingsastrology.com/static/media/ri.61ebfccf262f12aa82ee.png"
        />
      </Helmet>
            <section className="inner-section service-page">
                <NavBar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/services">Services</a></li>
                                <li>Reiki Healing</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">

                    <div className="row">
                        <div className="section-head t-head mb-0 col-12 mb-4">
                            <h4><span>Reiki</span> Healing</h4>
                        </div>
                        <div className="col-lg-4">
                            <img src={s3} className="img-fluid w-100 h-100" alt="Reiki Healing Services" />
                        </div>
                        <div className="col-lg-8 service-inner-card">
                            <p>
                            It is the japanese technique which makes use of hands and invokes energy and blessings from the universe. It is an age-old technique which is very powerful and is used for stress reduction and relaxation. It is  guided by spiritual  consciousness and is defined as spiritually guided life energy. Reiki is the special kind of  healing energy that can only be channeled by someone who has been attuned to it. 
                            </p>
                            <p>
                            Reiki can be used for healing ..healing can be of any kind : diseases, mind, situation healing for example problems in relations, depression, problems in property matters, problems in job, problems in   adjustment, studies, manifestation etc. 
                            </p>
                            <p>
                            Dr. Surabhi Bhatnagar uses her expertise in Reiki Healing  to guide, assist, and solve problems, as well as suggest corrective actions.
                            </p>
                            <a href="/book-an-appointment" className="button button-2">Book An Appointment</a>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    );
}