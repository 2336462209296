import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import abt from "../img/surabhi.png";
import NavBar from "../nav/nav";
import { Helmet } from "react-helmet";
import Ariesblog from "../img/blog/blogaries.png";
import Taurasblog from "../img/blog/taurusblog.png";
import Geminiblog from "../img/blog/bloggemini.png";
// import Geminiblog from "../img/blog/bloggemini.png";
import Cancerblog from "../img/blog/blogleo.png";
import bloggemini from "../img/blog/bloggemini.png";
import blogleo from "../img/blog/blogleo.png";
import blogvirgo from "../img/blog/blogvirgo.png";



import "./blog.css";


export default function blogpage() {
    return (
        <>
            <Helmet>

                <title>
                    A Comprehensive Guide To Zodiac Blogs: Dr. Surabhi Bhatnagar
                </title>
                <meta
                    name="description"
                    content="If you're interested in exploring the world of zodiac signs, our comprehensive guide to zodiac blogs can be a great resource for you."
                />
                <meta name="keywords" content="zodiac signs symbol, astrology, astrologer, blessings  astrologer, vastu shastra, numerology services" />

                <link rel="canonical" href="https://blessingsastrology.com/blogs" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:site_name" content="Blessings Astrology" />
                <meta property="og:title" content="A Comprehensive Guide To Zodiac Blogs: Dr. Surabhi Bhatnagar" />
                <meta property="og:description" content="If you're interested in exploring the world of zodiac signs, our comprehensive guide to zodiac blogs can be a great resource for you." />
                <meta
                    property="og:url"
                    content="https://blessingsastrology.com/blogs
"
                />
                <meta
                    property="og:image"
                    content="https://blessingsastrology.com/static/media/blogbannerr.a1de16b1f3fbf2d56d39.png"
                />
                <meta
                    name="twitter:title"
                    content="  A Comprehensive Guide To Zodiac Blogs: Dr. Surabhi Bhatnagar"
                />
                <meta
                    name="twitter:description"
                    content="If you're interested in exploring the world of zodiac signs, our comprehensive guide to zodiac blogs can be a great resource for you."
                />
                <meta
                    name="twitter:image"
                    content=" https://blessingsastrology.com/static/media/blogbannerr.a1de16b1f3fbf2d56d39.png"
                />
            </Helmet>

            <section className="inner-section blog-page">
                <NavBar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Horoscope Prediction</li>

                            </ul>
                        </div>
                    </div>
                </div>

            </section>
            {/* <section className="blogsection">
                <div className="container">
                    <div className="row">
                        <div className="section-head  mb-0 col-12 mt-lg-5 mt-5">
                            <h1 className="pb-2"><span>Zodiac</span> Blog</h1>
                            <p className="text-dark">Astrology is the divine knowledge and pseudoscience which involves nine planets, twenty seven constellations, longitudes and latitudes and celestial degrees to predict the events and changes in one’s life. </p>
                            <p className="text-dark">The practice probably began as soon as humans first started to observe astronomical cycles. Astrology explains  that everything that you do in life is influenced by the positions of the stars and Planets at the moment of your birth. It asserts that you are born according to karmic justice and judgment and that karma is written out into the stars at that exact moment. The alignment of stars and planets at the time of your birth is based on the karma you have earned in your previous births and hence results are awarded to you accordingly.
                            </p>
                            <p className="text-dark">When we study Astrology  one of the basis we work on is  Zodiac.
                                Zodiac is the imaginary celestial sphere around the earth  which is divided into 12 divisions . These divisions are known as Rashis or signs. These are:
                                ARIES, TAURUS, GEMINI, CANCER, LEO, VIRGO,LIBRA, SCORPIO, SAGITTARIUS, CAPRICORN, AQUARIUS, PISCES.

                            </p>

                            <p className="text-dark"><strong>Let us learn more about these :- </strong></p>
                        </div>
                        <div className="col-12">

                            <img src={Ariesblog} className="w-100 ariesimage" alt="Astrology Services"></img>
                            <div className="card-body ">
                                <h4 className="card-title mt-3">Aries</h4>
                                
                                <p className="card-text text-dark">Aries is the first sign of the zodiac, considered as governing the period from about March 21 to about April 20. Its representation is a Ram.
                                </p>
                                <p className="text-dark">People belonging to this are in  the first sign and therefore the most ~raw~ with their needs, demands and emotions.   They can  be  needy. They command your total attention and loyality. They get bored very fast.
                                </p>
                                <p className="text-dark">Aries have a very warm and passionate nature. Mars is the ruler and it  makes them highly energetic and  competitive. They have a big presence, they can make any  gathering lively with their presence. And, mostly, they're a blessing to be around because they genuinely care about other people. Just don’t get into a competition with them, we will be at a loss or at least drained up due to their high energy moves and never die spirit.
                                </p>
                                <p className="text-dark">They like to  win, attention seeking and being on top.
                                </p>
                                <ul className="bulletpoints">
                                    <li>
                                        <strong>They are courageous :- </strong>  They act first and think later, and have little to no personal anxiety about their safety or ability to do dangerous things. They are not required to be persuaded to take up jobs or acts that need bravery. They are naturally brave which is reflected in their everyday behavior also.


                                    </li>
                                    <li><strong>Competitive :- </strong> They take even a small quiz with friends or siblings as a full on competition. They take it up as a challenge and it immediately becomes a battle for them.  Underneath this hard-wired competitive streak lies a really vulnerable insecurity. Deep inside, all Aries wonder if they’re really, truly good enough.
                                    </li>
                                    <li><strong>Honest :- </strong> The most guileless, innocent and trusting of the signs - maybe because they are the zodiac’s baby i.e. the first sign. Ask them anything and they’ll tell you what they are thinking. But they do not possess any  malice, snideness or bad intentions in their outspokenness. They just wear their heart on their sleeve.
                                    </li>
                                    <li><strong>Generous :- </strong>Aries are all giving, warm and generous people. They love  to make others smile and be happy. But they can see that as a competition too and in an instant  they’re showing to explain  their well-meaning  advice which is generally uninvited.
                                    </li>
                                    <li><strong>Energetic and driven :- </strong> Aries would happily bounce and run around from dawn till  dusk, as they have  never-ending energy levels. All Aries want to succeed, get to the top, to ‘win’  and hence, they’re usually all  ambitious and  go-getters. They can be nagging and this increases stress and pressure which is all self-inflicted. Aries needs to learn to chill out and be nicer to themselves sometimes.
                                    </li>
                                </ul>
                                <p className=" text-dark">They love sports either playing or watching or betting. Their  interest in other people and helping   draws them  into charity, campaigning and mentoring work. They generally want to enjoy out and explore surroundings and places. They love being the center of attention  and hence  they love receiving applause and basking in the limelight.
                                    They take a great deal of pride in their homes and they beautify it with their own hands or showing their presence at every step of construction or beautifying.
                                </p>
                                <p className="text-dark"><strong>Traits:</strong> </p>
                                <p className="text-dark">Aries people demand to be noticed and attention seeking. They are physical, funny, warm, sexy and dynamic, and when they enter a room all eyes turn to them because of the force of their personality and humour. They tend to have dramatic brows and an intense gaze, fixing their object of attention with all their charisma and warmth. </p>
                                <p className="text-dark"><strong>Aries are attractive because :- </strong></p>
                                <ul>
                                    <li>
                                        They have big, genuine smiles which light up their face when they see people they love and like.


                                    </li>
                                    <li>They are adventurous, passionate and spontaneous, and being around them means adventures are likely to happen
                                    </li>
                                    <li>They stand out from the crowd and get noticed the moment they enter a room

                                    </li>
                                </ul>
                                <p className="text-dark"><strong>Career Choices :-</strong> </p>
                                <p className="text-dark">Mars, their ruler energises  them to try and get to the epitome  of whatever career path they choose to pursue. They aren’t content to follow or play at the second position . They want to lead.  Careers in sports, entertainment, physical therapies, sales, business, entrepreneurial ventures, tourism, hospitality, etc are their favourite career choices.
                                    Aries  gets  bored very fast hence they need  challenges, stimulus, and opportunity for progress.
                                </p>
                                <p className="text-dark"><strong>Aries Man:
                                </strong></p>
                                <ul>
                                    <li>
                                        Aries man wants to have fun.
                                    </li>
                                    <li>They are natural playful and passionate in nature.
                                    </li>
                                    <li>They get bored  easily but outings however boring are exciting for them.
                                    </li>
                                    <li>His plans will  be very exciting and full of adventure.</li>
                                    <li>He is always trying to to do something new and exciting.
                                    </li>
                                    <li>Adventurous games like skydiving, invention, exploring new areas, exploring new paths, etc are their favourites.
                                    </li>
                                    <li>They are highly temperamental.
                                    </li>
                                    <li>They take every opportunity or even a small quiz or game as a competition to the extent of a battle and they will compete fiercely to be at the top.
                                    </li>
                                    <li>They have high energy so they can be argumentative too.
                                    </li>
                                    <li>Hey sometimes can act immature and childish and want others in the surroundings to behave in the same manner as them.
                                    </li>
                                    <li>As a lover, they are very passionate and initiator and can become on the bossier side. They can always experiment with their love life and in the bedroom.
                                    </li>
                                </ul>
                                <p className="text-dark"><strong>Aries Women:
                                </strong></p>
                                <ul>
                                    <li>They are free spirited and fiercely independent.
                                    </li>
                                    <li>  They are full of energy and containing an element of fire in them.
                                    </li>
                                    <li>They have a spark in life and ignites whomsoever comes in their touch.
                                    </li>
                                    <li>They are highly creative, passionate and never die attitude person.
                                    </li>
                                    <li>They are dominating, wants to lead and always be on the top.
                                    </li>
                                    <li>They can be short tempered and can easily pick up fights.
                                    </li>
                                    <li>They are great in getting things going, initiating, getting the endeavors running using their own energy and being too enthusiastic all along the way.
                                    </li>
                                    <li>Aries' woman needs a lot of tendering and love and attention to keep the spark going and burning or she will sulk and lose her identity which will be detrimental for her personality.
                                    </li>
                                    <li>They are always energetic and youthful and hence look younger from their actual age.
                                    </li>
                                    <li>They are natural leaders.
                                    </li>
                                    <li>They are creative and very intelligent.They refuse to accept anything less than the best.</li>
                                    <li>They are fun to be around.</li>
                                    <li>They are very impatient and cannot wait for things to happen.
                                    </li>
                                    <li>They are moody and impulsive and hence can take wrong decisions and drive things away.
                                    </li>
                                    <li>They get aggressive easily and are emotionally weak.
                                    </li>
                                </ul>
                                
                            </div>
                            

                        </div>
                    </div>
                </div>
            </section> */}
            <section className="blogsection">
                <div className="container">
                    <div className="row">
                        <div className="section-head  mb-0 col-12 mt-lg-5 mt-5">
                            <h1 className="pb-2"><span>Zodiac </span> Blogs</h1>
                            <p className="text-dark">Astrology is the divine knowledge and pseudoscience which involves nine planets, twenty seven constellations, longitudes and latitudes and celestial degrees to predict the events and changes in one’s life. </p>
                            <p className="text-dark">The practice probably began as soon as humans first started to observe astronomical cycles. Astrology explains  that everything that you do in life is influenced by the positions of the stars and Planets at the moment of your birth. It asserts that you are born according to karmic justice and judgment and that karma is written out into the stars at that exact moment. The alignment of stars and planets at the time of your birth is based on the karma you have earned in your previous births and hence results are awarded to you accordingly.
                            </p>
                            <p className="text-dark">When we study Astrology  one of the basis we work on is  Zodiac.
                                Zodiac is the imaginary celestial sphere around the earth  which is divided into 12 divisions . These divisions are known as Rashis or signs. These are:
                                ARIES, TAURUS, GEMINI, CANCER, LEO, VIRGO,LIBRA, SCORPIO, SAGITTARIUS, CAPRICORN, AQUARIUS, PISCES.

                            </p>

                            {/* <p className="text-dark"><strong>Let us learn more about these :- </strong></p> */}
                        </div>
                        {/* <div className="col-12">

                            <img src={Ariesblog} className="w-100 ariesimage" alt="Astrology Services"></img>
                            <div className="card-img-overlay">
                               
                            </div>
                            <div className="card-body ">
                                <h4 className="card-title mt-3">Aries</h4>
                               
                                <p className="card-text text-dark">Aries is the first sign of the zodiac, considered as governing the period from about March 21 to about April 20. Its representation is a Ram.
                                </p>
                                <p className="text-dark">People belonging to this are in  the first sign and therefore the most ~raw~ with their needs, demands and emotions.   They can  be  needy. They command your total attention and loyality. They get bored very fast.
                                </p>
                                <p className="text-dark">Aries have a very warm and passionate nature. Mars is the ruler and it  makes them highly energetic and  competitive. They have a big presence, they can make any  gathering lively with their presence. And, mostly, they're a blessing to be around because they genuinely care about other people. Just don’t get into a competition with them, we will be at a loss or at least drained up due to their high energy moves and never die spirit.
                                </p>
                                <p className="text-dark">They like to  win, attention seeking and being on top.
                                </p>
                                <ul className="bulletpoints">
                                    <li>
                                        <strong>They are courageous :- </strong>  They act first and think later, and have little to no personal anxiety about their safety or ability to do dangerous things. They are not required to be persuaded to take up jobs or acts that need bravery. They are naturally brave which is reflected in their everyday behavior also.


                                    </li>
                                    <li><strong>Competitive :- </strong> They take even a small quiz with friends or siblings as a full on competition. They take it up as a challenge and it immediately becomes a battle for them.  Underneath this hard-wired competitive streak lies a really vulnerable insecurity. Deep inside, all Aries wonder if they’re really, truly good enough.
                                    </li>
                                    <li><strong>Honest :- </strong> The most guileless, innocent and trusting of the signs - maybe because they are the zodiac’s baby i.e. the first sign. Ask them anything and they’ll tell you what they are thinking. But they do not possess any  malice, snideness or bad intentions in their outspokenness. They just wear their heart on their sleeve.
                                    </li>
                                    <li><strong>Generous :- </strong>Aries are all giving, warm and generous people. They love  to make others smile and be happy. But they can see that as a competition too and in an instant  they’re showing to explain  their well-meaning  advice which is generally uninvited.
                                    </li>
                                    <li><strong>Energetic and driven :- </strong> Aries would happily bounce and run around from dawn till  dusk, as they have  never-ending energy levels. All Aries want to succeed, get to the top, to ‘win’  and hence, they’re usually all  ambitious and  go-getters. They can be nagging and this increases stress and pressure which is all self-inflicted. Aries needs to learn to chill out and be nicer to themselves sometimes.
                                    </li>
                                </ul>
                                <p className=" text-dark">They love sports either playing or watching or betting. Their  interest in other people and helping   draws them  into charity, campaigning and mentoring work. They generally want to enjoy out and explore surroundings and places. They love being the center of attention  and hence  they love receiving applause and basking in the limelight.
                                    They take a great deal of pride in their homes and they beautify it with their own hands or showing their presence at every step of construction or beautifying.
                                </p>
                                <p className="text-dark"><strong>Traits:</strong> </p>
                                <p className="text-dark">Aries people demand to be noticed and attention seeking. They are physical, funny, warm, sexy and dynamic, and when they enter a room all eyes turn to them because of the force of their personality and humour. They tend to have dramatic brows and an intense gaze, fixing their object of attention with all their charisma and warmth. </p>
                                <p className="text-dark"><strong>Aries are attractive because :- </strong></p>
                                <ul>
                                    <li>
                                        They have big, genuine smiles which light up their face when they see people they love and like.


                                    </li>
                                    <li>They are adventurous, passionate and spontaneous, and being around them means adventures are likely to happen
                                    </li>
                                    <li>They stand out from the crowd and get noticed the moment they enter a room

                                    </li>
                                </ul>
                                <p className="text-dark"><strong>Career Choices :-</strong> </p>
                                <p className="text-dark">Mars, their ruler energises  them to try and get to the epitome  of whatever career path they choose to pursue. They aren’t content to follow or play at the second position . They want to lead.  Careers in sports, entertainment, physical therapies, sales, business, entrepreneurial ventures, tourism, hospitality, etc are their favourite career choices.
                                    Aries  gets  bored very fast hence they need  challenges, stimulus, and opportunity for progress.
                                </p>
                                <p className="text-dark"><strong>Aries Man:
                                </strong></p>
                                <ul>
                                    <li>
                                        Aries man wants to have fun.
                                    </li>
                                    <li>They are natural playful and passionate in nature.
                                    </li>
                                    <li>They get bored  easily but outings however boring are exciting for them.
                                    </li>
                                    <li>His plans will  be very exciting and full of adventure.</li>
                                    <li>He is always trying to to do something new and exciting.
                                    </li>
                                    <li>Adventurous games like skydiving, invention, exploring new areas, exploring new paths, etc are their favourites.
                                    </li>
                                    <li>They are highly temperamental.
                                    </li>
                                    <li>They take every opportunity or even a small quiz or game as a competition to the extent of a battle and they will compete fiercely to be at the top.
                                    </li>
                                    <li>They have high energy so they can be argumentative too.
                                    </li>
                                    <li>Hey sometimes can act immature and childish and want others in the surroundings to behave in the same manner as them.
                                    </li>
                                    <li>As a lover, they are very passionate and initiator and can become on the bossier side. They can always experiment with their love life and in the bedroom.
                                    </li>
                                </ul>
                                <p className="text-dark"><strong>Aries Women:
                                </strong></p>
                                <ul>
                                    <li>They are free spirited and fiercely independent.
                                    </li>
                                    <li>  They are full of energy and containing an element of fire in them.
                                    </li>
                                    <li>They have a spark in life and ignites whomsoever comes in their touch.
                                    </li>
                                    <li>They are highly creative, passionate and never die attitude person.
                                    </li>
                                    <li>They are dominating, wants to lead and always be on the top.
                                    </li>
                                    <li>They can be short tempered and can easily pick up fights.
                                    </li>
                                    <li>They are great in getting things going, initiating, getting the endeavors running using their own energy and being too enthusiastic all along the way.
                                    </li>
                                    <li>Aries' woman needs a lot of tendering and love and attention to keep the spark going and burning or she will sulk and lose her identity which will be detrimental for her personality.
                                    </li>
                                    <li>They are always energetic and youthful and hence look younger from their actual age.
                                    </li>
                                    <li>They are natural leaders.
                                    </li>
                                    <li>They are creative and very intelligent.They refuse to accept anything less than the best.</li>
                                    <li>They are fun to be around.</li>
                                    <li>They are very impatient and cannot wait for things to happen.
                                    </li>
                                    <li>They are moody and impulsive and hence can take wrong decisions and drive things away.
                                    </li>
                                    <li>They get aggressive easily and are emotionally weak.
                                    </li>
                                </ul>
                               
                            </div>
                          

                        </div> */}
                        <div className="col-md-4 py-md-2 px-md-3 mb-3">
                            <div className="service-card">
                                <div className="icon-wrapper mb-3">
                                    {/* <img src={Sagittarius} alt="Astrology Services"></img> */}
                                    <a href="/aries"> <img src={Ariesblog} className=" img-fluid aries-image" alt="Astrology Services"></img>
</a>
                                </div>
                                <h3><a href="/aries">Aries</a></h3>
                                <p className=" ">
                                    Aries is the first sign of the zodiac, considered as governing the period from about March 21 to about April 20. Its representation is a Ram.

                                </p>
                                {/* <div className="text-start w-100 mb-4">
                                    <span className="me-3"><strong>Lucky Color :</strong> Yellow
                                    </span>
                                    <span>
                                        <strong>Lucky Number :</strong> 3
                                    </span>
                                </div> */}
                                <div className="d-flex align-items-left justify-content-left">
                                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                                    <a href="/aries" className="button button-2">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 py-md-2 px-md-3 mb-3">
                            <div className="service-card">
                                <div className="icon-wrapper mb-3">
                                    {/* <img src={Sagittarius} alt="Astrology Services"></img> */}
                                    <a href="/taurus">  <img src={Taurasblog} className=" img-fluid aries-image" alt="Astrology Services"></img></a>

                                </div>
                                <h3><a href="/taurus">Taurus</a> </h3>
                                <p className=" ">
                                Taurus is the second sign of the zodiac, considered as governing the period from about April 21 to about May 20. Its representation is a Bull.
                                </p>
                                {/* <div className="text-start w-100 mb-4">
                                    <span className="me-3"><strong>Lucky Color :</strong> Yellow
                                    </span>
                                    <span>
                                        <strong>Lucky Number :</strong> 3
                                    </span>
                                </div> */}
                                <div className="d-flex align-items-left justify-content-left">
                                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                                    <a href="/taurus" className="button button-2">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 py-md-2 px-md-3 mb-3">
                            <div className="service-card">
                                <div className="icon-wrapper mb-3">
                                    {/* <img src={Sagittarius} alt="Astrology Services"></img> */}
                                    <a href="/gemini">  <img src={Geminiblog} className=" img-fluid aries-image" alt="Astrology Services"></img></a>

                                </div>
                                <h3><a href="/gemini">Gemini</a> </h3>
                                <p className=" ">
                                Gemini is the third sign of the zodiac, considered as governing the period from about May  21 to about June 20. Its representation is Twins. 

                                </p>
                                {/* <div className="text-start w-100 mb-4">
                                    <span className="me-3"><strong>Lucky Color :</strong> Yellow
                                    </span>
                                    <span>
                                        <strong>Lucky Number :</strong> 3
                                    </span>
                                </div> */}
                                <div className="d-flex align-items-left justify-content-left">
                                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                                    <a href="/gemini" className="button button-2">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 py-md-2 px-md-3 mb-3">
                            <div className="service-card">
                                <div className="icon-wrapper mb-3">
                                    {/* <img src={Sagittarius} alt="Astrology Services"></img> */}
                                    <a href="/cancer">  <img src={bloggemini} className=" img-fluid aries-image" alt="Astrology Services"></img></a>

                                </div>
                                <h3><a href="/cancer">Cancer</a> </h3>
                                <p className=" ">
                                Cancer signs fall  between 21st June and 22nd July .
The representation is of.

                                </p>
                                {/* <div className="text-start w-100 mb-4">
                                    <span className="me-3"><strong>Lucky Color :</strong> Yellow
                                    </span>
                                    <span>
                                        <strong>Lucky Number :</strong> 3
                                    </span>
                                </div> */}
                                <div className="d-flex align-items-left justify-content-left">
                                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                                    <a href="/cancer" className="button button-2">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 py-md-2 px-md-3 mb-3">
                            <div className="service-card">
                                <div className="icon-wrapper mb-3">
                                    {/* <img src={Sagittarius} alt="Astrology Services"></img> */}
                                    <a href="/leo">  <img src={blogleo} className=" img-fluid aries-image" alt="Astrology Services"></img></a>

                                </div>
                                <h3><a href="/leo">Leo</a> </h3>
                                <p className=" ">
                                Leo falls between 23rd July 23 and 22nd August. Its representation is a lion.

                                </p>
                                {/* <div className="text-start w-100 mb-4">
                                    <span className="me-3"><strong>Lucky Color :</strong> Yellow
                                    </span>
                                    <span>
                                        <strong>Lucky Number :</strong> 3
                                    </span>
                                </div> */}
                                <div className="d-flex align-items-left justify-content-left">
                                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                                    <a href="/leo" className="button button-2">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 py-md-2 px-md-3 mb-3">
                            <div className="service-card">
                                <div className="icon-wrapper mb-3">
                                    {/* <img src={Sagittarius} alt="Astrology Services"></img> */}
                                    <a href="/virgo">  <img src={blogvirgo} className=" img-fluid aries-image" alt="Astrology Services"></img></a>

                                </div>
                                <h3><a href="/virgo">Virgo</a> </h3>
                                <p className=" ">
                                Virgo sign falls  between 23rd August and 22nd September. Its representation is a 

                                </p>
                                {/* <div className="text-start w-100 mb-4">
                                    <span className="me-3"><strong>Lucky Color :</strong> Yellow
                                    </span>
                                    <span>
                                        <strong>Lucky Number :</strong> 3
                                    </span>
                                </div> */}
                                <div className="d-flex align-items-left justify-content-left">
                                    {/* <a href="/astrology-service" className="button button-2">Read More </a> */}
                                    <a href="/virgo" className="button button-2">Read More</a>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>


        </>

    );
}




