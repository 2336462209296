import React, { Component } from "react";
import BackToTop from "react-back-to-top-button";
import { IoIosArrowUp } from "react-icons/io";
import "./top.css"
function TopBtn() {
    return (
        <BackToTop
            showOnScrollDown
            showAt={50}
            speed={3000}
            easing="easeInOutQuint"
        >
            <button className="myBtn">
                {/* <span className="arrow"> */}
                    <IoIosArrowUp className="arrow"/>
                {/* </span> */}
            </button>
        </BackToTop>
    );
}
export default TopBtn;