import React from "react";
import "../servicepage.css";
import NavBar from "../../nav/nav";
// import s1 from "../../img/service/inner/astrology.png";
import corporateimage from "../../img/corporate-training-inner.png"
import { Helmet } from "react-helmet";
import { Accordion, Tab, Tabs } from "react-bootstrap";
import Corporateimagegallery from "./corporateimagegallery"
import introduction from "../../img/corporatephotos/introduction.png";
import Corporate from "../../img/corporatephotos/corporate-two.jpg";
import Therapeutic from "../../img/corporatephotos/Therapeutic.png";
import Meditation from "../../img/corporatephotos/Meditation.png";
import Workshops from "../../img/corporatephotos/Workshops.png";
import Wellness from "../../img/corporatephotos/Wellness.png";
import Benifits from "../../img/corporatephotos/benifits.png";


export default function corporatetraining() {
  return (
    <>
      <Helmet>

        <title>
          Astrology Corporate Training | Blessings Astrology
        </title>
        <meta
          name="description"
          content="Explore astrology-based corporate training by Blessings Astrology for enhanced workplace well-being, productivity, and harmony. Discover personalized career guidance, stress management, and conflict resolution strategies. "
        />
        <meta name="keywords" content="astrology services, best astrologer in gurgaon, astrologer in gurgaon, Jyotis shastra, astrological services" />
        <link rel="canonical" href="https://www.blessingsastrology.com/corporate-training-service" />        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Blessings Astrology" />
        <meta property="og:title" content="Astrology Corporate Training | Blessings Astrology" />
        <meta property="og:description" content="Explore astrology-based corporate training by Blessings Astrology for enhanced workplace well-being, productivity, and harmony. Discover personalized career guidance, stress management, and conflict resolution strategies." />
        <meta
          property="og:url"
          content=" https://www.blessingsastrology.com/corporate-training-service "
        />
        <meta
          property="og:image"
          content=" https://www.blessingsastrology.com/static/media/corporate-two.2e2e0f749d4356c3b922.jpg"
        />
        <meta
          name="twitter:title"
          content="          Astrology Corporate Training | Blessings Astrology
"
        />
        <meta
          name="twitter:description"
          content="Explore astrology-based corporate training by Blessings Astrology for enhanced workplace well-being, productivity, and harmony. Discover personalized career guidance, stress management, and conflict resolution strategies. 
"
        />
        <meta
          name="twitter:image"
          content="https://www.blessingsastrology.com/static/media/corporate-two.2e2e0f749d4356c3b922.jpg"
        />
      </Helmet>
      <section className="inner-section service-page">
        <NavBar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/services">Services</a></li>
                <li>Corporate Training</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5">

          <div className="row">
            <div className="section-head t-head mb-0 col-12 mb-4">
              <h4>Corporate <span>Training</span></h4>
            </div>
            <div className="col-lg-4">
              <img src={Corporate} alt="Introduction to Astrology in the Workplace" className="img-fluid service-card-img"></img>
            </div>
            <div className="col-lg-8">
              <p>
                Blessings Astrology offers unique and comprehensive corporate training sessions designed to enhance employee well-being, productivity, and overall workplace harmony. Led by renowned facilitator Ms. Surabhi Bhatnagar, these sessions integrate astrology, various therapeutic techniques, meditation, and workshops tailored to corporate needs.
              </p>
              <a href="/book-an-appointment" className="button button-2">Book An Appointment</a>

            </div>
            <div className="col-lg-12 service-inner-card">

              <div className="section-head t-head col-12 my-4">
                <h4>Session <span>Components</span></h4>
              </div>

              <div className="row mt-5">
                <div className="col-lg-6">
                  <img src={introduction} alt="Introduction to Astrology in the Workplace" className="img-fluid service-card-img"></img>
                </div>

                <div className="col-lg-6">
                  <h4>1. Introduction to Astrology in the Workplace</h4>
                  <ul>
                    <li>Understanding the Basics of Astrology</li>
                    <li>How Astrology Influences Personal and Professional Life</li>
                    <li>Leveraging Astrological Insights for Team Building and Management</li>
                  </ul>

                  <h4>2. Personalized Astrological Readings</h4>
                  <ul>
                    <li>Individual Birth Chart Analysis</li>
                    <li>Identifying Strengths and Areas of Improvement</li>
                    <li>Personalized Career Guidance</li>
                  </ul>
                </div>
              </div>

              <div className="row row-lg-reverse">
                <div className="col-lg-6 mt-5">

                  <h4>3. Therapeutic Techniques</h4>
                  <ul>
                    <li className="list-group-item"><h5>Astro-Therapy</h5></li>
                    <li>Combining Astrological Insights with Therapeutic Practices</li>
                    <li>Addressing Workplace Stress and Anxiety</li>

                    <li className="list-group-item"><h5>Energy Healing</h5></li>
                    <li>Introduction to Reiki and Energy Balancing</li>
                    <li>Techniques to Clear Negative Energy and Enhance Positivity</li>
                  </ul>

                </div>
                <div className="col-lg-6 mt-5">
                  <img src={Therapeutic} alt="Therapeutic Techniques" className="img-fluid"></img>


                </div>




              </div>
              <div className="row">
                <div className="col-lg-6 mt-5">
                  <img src={Meditation} alt="Meditation Sessions" className="img-fluid"></img>


                </div>
                <div className="col-lg-6 mt-5">

                  <h4>4. Meditation Sessions</h4>
                  <ul>
                    <li className="list-group-item"><h5>Guided Meditation</h5></li>
                    <li>Techniques for Stress Reduction and Mental Clarity</li>
                    <li>Daily Practices for Enhanced Focus and Productivity</li>
                    <li className="list-group-item"><h5>Mindfulness Meditation</h5></li>
                    <li>Cultivating Presence and Awareness in the Workplace</li>
                    <li>Exercises to Improve Emotional Regulation</li>
                  </ul>

                </div>



              </div>
              <div className="row flex-row-lg-reverse">

                <div className="col-lg-6 mt-5">

                  <h4>5. Workshops and Interactive Sessions</h4>
                  <ul>
                    <li className="list-group-item"><h5>Team Building Workshops</h5></li>
                    <li>Activities and Exercises to Foster Team Cohesion and Collaboration</li>
                    <li>Role of Astrology in Understanding Team Dynamics</li>
                    <li className="list-group-item"><h5>Leadership Development</h5></li>
                    <li>Empowering Leaders with Astrological Insights</li>
                    <li>Enhancing Leadership Skills through Self-Awareness and Intuition</li>
                    <li className="list-group-item"><h5>Conflict Resolution</h5></li>
                    <li>Techniques for Mediating and Resolving Workplace Conflicts</li>
                    <li>Using Astrological Compatibility to Address and Mitigate Disputes</li>
                  </ul>
                </div>
                <div className="col-lg-6 mt-5">
                  <img src={Workshops} alt="Workshops and Interactive Sessions" className="img-fluid"></img>

                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mt-5">
                  <img src={Wellness} alt=" Wellness Programs" className="img-fluid"></img>
                </div>
                <div className="col-lg-6 mt-5">

                  <h4>6. Wellness Programs</h4>
                  <ul>
                    <li className="list-group-item"><h5>Holistic Health and Wellness</h5></li>
                    <li>Integrating Physical, Mental, and Emotional Well-being</li>
                    <li>Personalized Wellness Plans Based on Astrological Profiles</li>
                    <li className="list-group-item"><h5>Stress Management Techniques</h5></li>
                    <li>Practical Tools for Managing Stress in a Corporate Environment</li>
                    <li>Breathing Exercises, Visualization, and Relaxation Techniques</li>
                  </ul>

                </div>
              </div>

              {/* <h3 className="mt-5">Benefits of the Program</h3>
              <ul>
                <li><h5>Enhanced Self-Awareness:</h5> Employees gain a deeper understanding of their strengths, weaknesses, and potential.</li>
                <li><h5>Improved Team Dynamics: </h5> Teams function more cohesively with better communication and mutual understanding.</li>
                <li><h5>Increased Productivity: </h5> Techniques and insights lead to better focus, efficiency, and overall performance.</li>
                <li><h5>Reduced Stress Levels: </h5> Meditation and therapeutic practices help in managing and reducing workplace stress.</li>
                <li><h5>Better Conflict Management: </h5> Astrological insights aid in resolving conflicts amicably and effectively.</li>
              </ul> */}

            </div>
            {/* <div className="col-12 my-4">
              <Corporateimagegallery />
            </div> */}
          </div>

        </div>
      </section>
      <section className="service-parallex mt-5 mb-4">

      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-head t-head mt-2 mb-5">
                <h4>Benefits of the Program</h4>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={Benifits} alt="Benefits of the Program" className="img-fluid"></img>
            </div>
            <div className="col-lg-6">
              <ul>
                <li><h5>Enhanced Self-Awareness:</h5> Employees gain a deeper understanding of their strengths, weaknesses, and potential.</li>
                <li><h5>Improved Team Dynamics: </h5> Teams function more cohesively with better communication and mutual understanding.</li>
                <li><h5>Increased Productivity: </h5> Techniques and insights lead to better focus, efficiency, and overall performance.</li>
                <li><h5>Reduced Stress Levels: </h5> Meditation and therapeutic practices help in managing and reducing workplace stress.</li>
                <li><h5>Better Conflict Management: </h5> Astrological insights aid in resolving conflicts amicably and effectively.</li>
              </ul>
            </div>
          </div>
          <div className="col-12 mb-4 mt-5">
            <Corporateimagegallery />
          </div>
        </div>
      </section >
    </>
  );
}