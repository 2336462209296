import g1 from "../../img/corporatephotos/corporate-one.jpg";
import g2 from "../../img/corporatephotos/corporate-two.jpg";
import g3 from "../../img/corporatephotos/corporate-seven.jpg";
import g4 from "../../img/corporatephotos/corporate-three.jpg";
import g5 from "../../img/corporatephotos/corporate-four.jpg";
import g6 from "../../img/corporatephotos/corporate-nine.jpg";
import g7 from "../../img/corporatephotos/corporate-five.jpg";
import g8 from "../../img/corporatephotos/corporate-six.jpg";
import g9 from "../../img/corporatephotos/corporate-eight.jpg";
export const photos = [
    // {
    //     src: g1,
    //     width: 2,
    //     height: 2,
    //     alt: "Media Gallery"
    // },
    {
        src: g5,
        width: 2,
        height: 2,
        alt: "Media Gallery"
    },
    {
        src: g2,
        width: 3,
        height: 1,
        alt: "conditional club of india (Dr. Surabhi Bhatnagar)"
    },
    {
        src: g8,
        width: 3,
        height: 1,
        alt: "Indian Astrology Awards"
    },
    {
        src: g4,
        width: 3,
        height: 1,
        alt: "Tero Queen Award"
    },
    {
        src: g6,
        width: 2,
        height: 1,
        alt: "64th Astrological convention Award (Dr. Surabhi Bhatnagar)"
    },
    {
        src: g1,
        width: 2,
        height: 1,
        alt: "Dr. Surabhi Bhatnagar"
    },
    {
        src: g9,
        width: 2,
        height: 1,
        alt: "Dr. Surabhi Bhatnagar"
    },
    {
        src: g3,
        width: 2,
        height: 1,
        alt: "International astrology fedration Inc.'s Certificate to Dr. Surbhi Bhatnagar"
    },
    {
        src: g7,
        width: 3,
        height: 1,
        alt: "Dr. Surabhi Bhatnagar"
    }
];
